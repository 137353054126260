import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UnsavedColumn, UnsavedRow } from "./types";
import { AttributePanel } from "../AttributePanel";
import { TableRowOffsetFormField } from "./TableRowOffsetFormField";
import { TableColumnOffsetFormField } from "./TableColumnOffsetFormField";
import { TableRowListFormField } from "./TableRowListFormField";
import { TableColumnListFormField } from "./TableColumnListFormField";
import {
  TableCreationPanelFragment,
  useCreateTableFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: TableCreationPanelFragment;
  onDelete: () => Promise<void>;
};

export function TableCreationPanel({ fragment, onDelete }: Props): JSX.Element {
  const { t } = useTranslation();
  const [createAttribute] = useCreateTableFromPanelMutation();
  const [parentRowOffset, setParentRowOffset] = useState<number>();
  const [parentColumnOffset, setParentColumnOffset] = useState<number>();
  const [rows, setRows] = useState<UnsavedRow[]>([]);
  const [columns, setColumns] = useState<UnsavedColumn[]>([]);

  const onSave = async () => {
    if (
      parentRowOffset === undefined ||
      parentColumnOffset === undefined ||
      rows.length === 0 ||
      columns.length === 0
    ) {
      return;
    }
    await createAttribute({
      variables: {
        regionId: fragment.id,
        parentRowOffset,
        parentColumnOffset,
        rows: rows.map((row) => row.height),
        columns: columns.map((column) => column.width),
      },
    });
    await onDelete();
  };

  return (
    <AttributePanel
      title={t("TableCreationPanel.title")}
      unsaved
      onSave={onSave}
      onDelete={onDelete}
    >
      <div className="flex flex-col gap-4">
        <TableRowOffsetFormField
          value={parentRowOffset ?? ""}
          onChange={(event) => setParentRowOffset(parseInt(event.target.value))}
        />
        <TableColumnOffsetFormField
          value={parentColumnOffset ?? ""}
          onChange={(event) =>
            setParentColumnOffset(parseInt(event.target.value))
          }
        />
        <TableRowListFormField rows={rows} setRows={setRows} />
        <TableColumnListFormField columns={columns} setColumns={setColumns} />
      </div>
    </AttributePanel>
  );
}
