import { ReactNode } from "react";
import { UserAvatar } from "../users/UserAvatar";
import { DocumentShareCollaboratorFragment } from "../client/generated";

type Props = {
  fragment: DocumentShareCollaboratorFragment;
  right?: ReactNode;
};

export function DocumentShareCollaborator({
  fragment,
  right,
}: Props): JSX.Element {
  return (
    <div className="flex gap-4 items-center">
      <UserAvatar fragment={fragment} />
      <span>{fragment.username}</span>
      <div className="ml-auto">{right}</div>
    </div>
  );
}
