import { useParams } from "react-router";
import { Fragment, useReducer } from "react";
import { usePageScreenSuspenseQuery } from "../client/generated";
import { editorReducer, TabIdentifier, RegionMode } from "../editor/reducers";
import { assertTypeName } from "../client/guards";
import { Sidebar } from "../shell/Sidebar";
import { Scaffold } from "../shell/Scaffold";
import { EditorMetadataNavbarTabButton } from "../editor/EditorMetadataNavbarTabButton";
import { EditorMetadataNavbarTabContent } from "../editor/EditorMetadataNavbarTabContent";
import { EditorRegionsNavbarTabButton } from "../editor/EditorRegionsNavbarTabButton";
import { EditorRegionsNavbarTabContent } from "../editor/EditorRegionsNavbarTabContent";
import { EditorPagesNavbarTabButton } from "../editor/EditorPagesNavbarTabButton";
import { EditorPagesNavbarTabContent } from "../editor/EditorPagesNavbarTabContent";
import { OverviewNavbarButton } from "../editor/OverviewNavbarButton";
import { SidebarDivider } from "../shell/SidebarDivider";
import { PageEditor } from "../editor/PageEditor";
import { PreviousPageNavbarButton } from "../editor/PreviousPageNavbarButton";
import { NextPageNavbarButton } from "../editor/NextPageNavbarButton";
import { Navbar } from "../shell/Navbar";
import { HybridRegionCreationTab } from "../types/hybrid/HybridRegionCreationTab";
import { RegionUpdateTab } from "../types/RegionUpdateTab";
import { HeadingRegionCreationTab } from "../types/heading/HeadingRegionCreationTab";
import { ArtifactRegionCreationTab } from "../types/artifact/ArtifactRegionCreationTab";
import { CaptionRegionCreationTab } from "../types/caption/CaptionRegionCreationTab";
import { FigureRegionCreationTab } from "../types/figure/FigureRegionCreationTab";
import { FormulaRegionCreationTab } from "../types/formula/FormulaRegionCreationTab";
import { ImageRegionCreationTab } from "../types/image/ImageRegionCreationTab";
import { ListRegionCreationTab } from "../types/list/ListRegionCreationTab";
import { TableRegionCreationTab } from "../types/table/TableRegionCreationTab";
import { TextRegionCreationTab } from "../types/text/TextRegionCreationTab";
import { TOCRegionCreationTab } from "../types/toc/TOCRegionCreationTab";
import { ListItemRegionCreationTab } from "../types/list-item/ListItemRegionCreationTab";
import { SublistRegionCreationTab } from "../types/sublist/SublistRegionCreationTab";
import { makePageThumbnailID } from "../regions/utils";
import { useClippy } from "../shell/useClippy";

export function PageScreen(): JSX.Element {
  const [state, dispatch] = useReducer(editorReducer, {
    mode: RegionMode.Rectangle,
    tabIdentifier: TabIdentifier.Regions,
  });

  const { pageId } = useParams<{ pageId: string }>();

  const { data } = usePageScreenSuspenseQuery({
    variables: { pageId: pageId! },
  });

  const { page } = data;
  assertTypeName(page?.__typename === "Page");

  const onTabsValueChange = (tabIdentifier: string) => {
    dispatch({
      type: "switchTab",
      tabIdentifier,
    });

    setTimeout(() => {
      document.getElementById(makePageThumbnailID(page.id))?.scrollIntoView({
        behavior: "instant",
        block: "center",
      });
    });
  };

  useClippy();

  return (
    <Scaffold
      tabsValue={state.tabIdentifier}
      onTabsValueChange={onTabsValueChange}
      navbar={
        <Navbar>
          <OverviewNavbarButton fragment={page.document} />
          <PreviousPageNavbarButton fragment={page} />
          <NextPageNavbarButton fragment={page} />
          <SidebarDivider />
          <EditorMetadataNavbarTabButton />
          <EditorPagesNavbarTabButton />
          <EditorRegionsNavbarTabButton />
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <EditorMetadataNavbarTabContent fragment={page.document} />
          <EditorPagesNavbarTabContent fragment={page} />
          <EditorRegionsNavbarTabContent
            state={state}
            dispatch={dispatch}
            fragment={page}
          />

          {page.regions.map((region) => (
            <Fragment key={region.id}>
              <ArtifactRegionCreationTab
                dispatch={dispatch}
                fragment={region}
              />
              <CaptionRegionCreationTab dispatch={dispatch} fragment={region} />
              <FigureRegionCreationTab dispatch={dispatch} fragment={region} />
              <FormulaRegionCreationTab dispatch={dispatch} fragment={region} />
              <HeadingRegionCreationTab dispatch={dispatch} fragment={region} />
              <HybridRegionCreationTab dispatch={dispatch} fragment={region} />
              <ImageRegionCreationTab dispatch={dispatch} fragment={region} />
              <ListRegionCreationTab dispatch={dispatch} fragment={region} />
              <ListItemRegionCreationTab
                dispatch={dispatch}
                fragment={region}
              />
              <SublistRegionCreationTab dispatch={dispatch} fragment={region} />
              <TableRegionCreationTab dispatch={dispatch} fragment={region} />
              <TextRegionCreationTab dispatch={dispatch} fragment={region} />
              <TOCRegionCreationTab dispatch={dispatch} fragment={region} />
              <RegionUpdateTab dispatch={dispatch} fragment={region} />
            </Fragment>
          ))}
        </Sidebar>
      }
    >
      <PageEditor fragment={page} state={state} dispatch={dispatch} />
    </Scaffold>
  );
}
