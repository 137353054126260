import { assertTypeName } from "../../client/guards";
import { FormulaRegionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: FormulaRegionPreviewFragment;
  expressionAttributeId: string;
};

export function FormulaRegionPreview({
  fragment,
  expressionAttributeId,
}: Props): JSX.Element {
  const expressionAttribute = fragment.attributes.find(
    (a) => a.id === expressionAttributeId,
  );
  assertTypeName(expressionAttribute?.__typename === "ExpressionAttribute");

  return <>{expressionAttribute.expressionLatex}</>;
}
