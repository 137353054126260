import { useId } from "react";
import { RegionImageFragment } from "../client/generated";
import { useProtectedImageSource } from "../client/images";

type Props = {
  fragment: RegionImageFragment;
};

export function RegionImage({ fragment }: Props): JSX.Element {
  const clipPathId = useId();
  const svgSrc = useProtectedImageSource(fragment.page.svgUrl);

  const viewBoxMinX = fragment.shape.aabb.left * fragment.page.width;
  const viewBoxMinY = fragment.shape.aabb.top * fragment.page.height;
  const viewBoxWidth = fragment.shape.aabb.width * fragment.page.width;
  const viewBoxHeight = fragment.shape.aabb.height * fragment.page.height;

  return (
    <svg
      viewBox={`${viewBoxMinX} ${viewBoxMinY} ${viewBoxWidth} ${viewBoxHeight}`}
      preserveAspectRatio="none"
      className="bg-white bg-opacity-10"
    >
      <defs>
        <clipPath id={clipPathId}>
          <polygon
            points={fragment.shape.vertices
              .map(
                (v) =>
                  `${v.left * fragment.page.width},${v.top * fragment.page.height}`,
              )
              .join(" ")}
          />
        </clipPath>
      </defs>

      <polygon
        className="fill-white"
        points={fragment.shape.vertices
          .map(
            (v) =>
              `${v.left * fragment.page.width},${v.top * fragment.page.height}`,
          )
          .join(" ")}
      />

      <image
        xlinkHref={svgSrc}
        width={fragment.page.width}
        height={fragment.page.height}
        clipPath={`url(#${clipPathId})`}
      />
    </svg>
  );
}
