import { Textarea } from "@acdc2/ui/components/textarea";
import { useTranslation } from "react-i18next";
import { AlternativeTextPredictionButton } from "./AlternativeTextPredictionButton";
import { AlternativeTextFormFieldFragment } from "../../client/generated";
import { ComponentProps } from "react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@acdc2/ui/components/form";

type Props = ComponentProps<"textarea"> & {
  fragment: AlternativeTextFormFieldFragment;
  onPrediction: (text: string) => void;
};

export function AlternativeTextFormField({
  fragment,
  onPrediction,
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <FormField>
      <FormItem>
        <FormLabel>{t("AlternativeTextFormField.label")}</FormLabel>
        <FormControl>
          <Textarea required rows={8} {...props} />
        </FormControl>
        <AlternativeTextPredictionButton
          fragment={fragment}
          onAccept={onPrediction}
        />
      </FormItem>
    </FormField>
  );
}
