import { ScrollArea } from "@acdc2/ui/components/scroll-area";
import { NavbarTabCloseButton } from "../../shell/NavbarTabCloseButton";
import { NavbarTabContent } from "../../shell/NavbarTabContent";
import { useTranslation } from "react-i18next";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { Dispatch, useState } from "react";
import { assertTypeName } from "../../client/guards";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { ListFormField } from "../../attributes/list/ListFormField";
import {
  ListRegionCreationTabFragment,
  ListType,
  useCreateListRegionMutation,
  useListRegionCreationTabSuspenseQuery,
} from "../../client/generated";

type Props = {
  fragment: ListRegionCreationTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export function ListRegionCreationTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.ListRegionCreation, fragment.id)}
      title={t("ListRegionCreationTab.title")}
      right={<NavbarTabCloseButton dispatch={dispatch} />}
    >
      <ScrollArea className="flex-1">
        <Body fragment={fragment} dispatch={dispatch} />
      </ScrollArea>
    </NavbarTabContent>
  );
}

function Body({ fragment, dispatch }: Props) {
  const listAttributeIds = fragment.attributes
    .filter((attr) => attr.__typename === "ListAttribute")
    .map((attr) => attr.id);

  const { data } = useListRegionCreationTabSuspenseQuery({
    variables: {
      regionId: fragment.id,
      listAttributeIds,
    },
  });

  assertTypeName(data.region?.__typename === "Region");

  const [createRegion] = useCreateListRegionMutation();

  const [listType, setListType] = useState(() => {
    const attr = data.listAttributes[0];
    if (!attr) return undefined;
    assertTypeName(attr.__typename === "ListAttribute");
    return attr.listType;
  });

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (listType === undefined) return;

    await createRegion({
      variables: {
        regionId: fragment.id,
        listType,
        excessAttributeIds: fragment.attributes.map((attr) => attr.id),
      },
      update: (cache) => {
        for (const attr of fragment.attributes) {
          const id = cache.identify(attr);
          cache.evict({ id });
          cache.gc();
        }
      },
    });
  };

  return (
    <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch}>
      <ListFormField
        value={listType}
        onValueChange={(newListType: ListType) => setListType(newListType)}
      />
    </RegionUpdateForm>
  );
}
