import { mdiCheck, mdiClose } from "@mdi/js";
import { Icon } from "@mdi/react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { Button } from "@acdc2/ui/components/button";
import { Input } from "@acdc2/ui/components/input";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableDimensionInput } from "./TableDimensionInput";
import { v4 as uuid } from "uuid";
import { UnsavedRow } from "./types";
import { FormControl, FormField, FormItem } from "@acdc2/ui/components/form";

type Props = {
  rows: UnsavedRow[];
  setRows: Dispatch<SetStateAction<UnsavedRow[]>>;
};

export function TableRowListFormField({ rows, setRows }: Props): JSX.Element {
  const { t } = useTranslation();
  const [inputVisibility, setInputVisibility] = useState(false);
  const [newRow, setNewRow] = useState<UnsavedRow | null>();

  const onSave = (index: number, newHeight: number) => {
    setRows((oldRows) => {
      const newRows = [...oldRows];
      newRows[index].height = newHeight;
      return newRows;
    });
  };

  const onDelete = (index: number) => {
    setRows((oldRows) => {
      const newRows = [...oldRows];
      newRows.splice(index, 1);
      return newRows;
    });
  };

  const onCancel = () => {
    setInputVisibility(false);
    setNewRow(null);
  };

  const onAdd = () => {
    if (!newRow) return;
    setRows((oldRows) => [...oldRows, newRow]);
    setInputVisibility(false);
    setNewRow(null);
  };

  return (
    <FormField>
      <legend>{t("TableRowListFormField.title")}</legend>
      <FormControl>
        <FormItem className="flex flex-col">
          <div className="flex flex-col gap-2">
            {rows.map((row, index) => (
              <TableDimensionInput
                defaultValue={row.height}
                key={row.uuid}
                onDelete={() => onDelete(index)}
                onSave={(newHeight: number) => onSave(index, newHeight)}
              />
            ))}
          </div>

          {inputVisibility && (
            <div className="flex flex-row gap-2">
              <Input
                type="number"
                inputMode="numeric"
                min={0}
                max={1}
                step={0.01}
                placeholder={t("TableRowListFormField.labels.placeholder")}
                value={newRow?.height ?? ""}
                onChange={(event) =>
                  setNewRow({
                    uuid: uuid(),
                    height: parseFloat(event.target.value),
                  })
                }
                required
              />
              <Button
                type="button"
                size="icon"
                variant="outline"
                className="p-2"
                onClick={() => onAdd()}
              >
                <Icon path={mdiCheck} size="1.25rem" />
              </Button>
              <Button
                type="button"
                size="icon"
                variant="outline"
                className="p-2"
                onClick={() => onCancel()}
              >
                <Icon path={mdiClose} size="1.25rem" />
              </Button>
            </div>
          )}

          <AccessButton type="button" onClick={() => setInputVisibility(true)}>
            {t("TableRowListFormField.labels.add")}
          </AccessButton>
        </FormItem>
      </FormControl>
    </FormField>
  );
}
