import { AlternativeTextUpdatePanel } from "../../attributes/alternative-text/AlternativeTextUpdatePanel";
import { CaptionUpdatePanel } from "../../attributes/caption/CaptionUpdatePanel";
import { ExcludeReadingOrderUpdatePanel } from "../../attributes/exclude-reading-order/ExcludeReadingOrderUpdatePanel";
import { ExpressionUpdatePanel } from "../../attributes/expression/ExpressionUpdatePanel";
import { FigureUpdatePanel } from "../../attributes/figure/FigureUpdatePanel";
import { HeadingLevelUpdatePanel } from "../../attributes/heading-level/HeadingLevelUpdatePanel";
import { ImageUpdatePanel } from "../../attributes/image/ImageUpdatePanel";
import { ListUpdatePanel } from "../../attributes/list/ListUpdatePanel";
import { ListItemUpdatePanel } from "../../attributes/list-item/ListItemUpdatePanel";
import { ListItemLabelUpdatePanel } from "../../attributes/list-item-label/ListItemLabelUpdatePanel";
import { PaginationArtifactUpdatePanel } from "../../attributes/pagination-artifact/PaginationArtifactUpdatePanel";
import { TableUpdatePanel } from "../../attributes/table/TableUpdatePanel";
import { TableCellUpdatePanel } from "../../attributes/table-cell/TableCellUpdatePanel";
import { TableHeaderCellUpdatePanel } from "../../attributes/table-header-cell/TableHeaderCellUpdatePanel";
import { TableOfContentsUpdatePanel } from "../../attributes/table-of-contents/TableOfContentsUpdatePanel";
import { TableOfContentsHeadingUpdatePanel } from "../../attributes/table-of-contents-heading/TableOfContentsHeadingUpdatePanel";
import { TableOfContentsItemUpdatePanel } from "../../attributes/table-of-contents-item/TableOfContentsItemUpdatePanel";
import { TableOfContentsPageNumberUpdatePanel } from "../../attributes/table-of-contents-page-number/TableOfContentsPageNumberUpdatePanel";
import { TextUpdatePanel } from "../../attributes/text/TextUpdatePanel";
import { AttributeUpdateSectionFragment } from "../../client/generated";

type Props = {
  fragment: AttributeUpdateSectionFragment;
};

export function AttributeUpdateSection({ fragment }: Props) {
  return (
    <div>
      {fragment.attributes.map((attribute) =>
        attribute.__typename === "AlternativeTextAttribute" ? (
          <AlternativeTextUpdatePanel key={attribute.id} fragment={attribute} />
        ) : attribute.__typename === "CaptionAttribute" ? (
          <CaptionUpdatePanel key={attribute.id} fragment={attribute} />
        ) : attribute.__typename === "ExcludeReadingOrderAttribute" ? (
          <ExcludeReadingOrderUpdatePanel
            key={attribute.id}
            fragment={attribute}
          />
        ) : attribute.__typename === "ExpressionAttribute" ? (
          <ExpressionUpdatePanel key={attribute.id} fragment={attribute} />
        ) : attribute.__typename === "FigureAttribute" ? (
          <FigureUpdatePanel key={attribute.id} fragment={attribute} />
        ) : attribute.__typename === "HeaderLevelAttribute" ? (
          <HeadingLevelUpdatePanel key={attribute.id} fragment={attribute} />
        ) : attribute.__typename === "ImageAttribute" ? (
          <ImageUpdatePanel key={attribute.id} fragment={attribute} />
        ) : attribute.__typename === "ListAttribute" ? (
          <ListUpdatePanel key={attribute.id} fragment={attribute} />
        ) : attribute.__typename === "ListItemAttribute" ? (
          <ListItemUpdatePanel key={attribute.id} fragment={attribute} />
        ) : attribute.__typename === "ListItemLabelAttribute" ? (
          <ListItemLabelUpdatePanel key={attribute.id} fragment={attribute} />
        ) : attribute.__typename === "PaginationArtifactAttribute" ? (
          <PaginationArtifactUpdatePanel
            key={attribute.id}
            fragment={attribute}
          />
        ) : attribute.__typename === "TableAttribute" ? (
          <TableUpdatePanel key={attribute.id} fragment={attribute} />
        ) : attribute.__typename === "TableCellAttribute" ? (
          <TableCellUpdatePanel key={attribute.id} fragment={attribute} />
        ) : attribute.__typename === "TableHeaderCellAttribute" ? (
          <TableHeaderCellUpdatePanel key={attribute.id} fragment={attribute} />
        ) : attribute.__typename === "TableOfContentsAttribute" ? (
          <TableOfContentsUpdatePanel key={attribute.id} fragment={attribute} />
        ) : attribute.__typename === "TableOfContentsHeadingAttribute" ? (
          <TableOfContentsHeadingUpdatePanel
            key={attribute.id}
            fragment={attribute}
          />
        ) : attribute.__typename === "TableOfContentsItemAttribute" ? (
          <TableOfContentsItemUpdatePanel
            key={attribute.id}
            fragment={attribute}
          />
        ) : attribute.__typename === "TableOfContentsPageNumberAttribute" ? (
          <TableOfContentsPageNumberUpdatePanel
            key={attribute.id}
            fragment={attribute}
          />
        ) : attribute.__typename === "TextAttribute" ? (
          <TextUpdatePanel key={attribute.id} fragment={attribute} />
        ) : null,
      )}
    </div>
  );
}
