import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ListFormField } from "./ListFormField";
import { AttributePanel } from "../AttributePanel";
import {
  ListCreationPanelFragment,
  ListType,
  useCreateListFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: ListCreationPanelFragment;
  onDelete: () => Promise<void>;
};

export function ListCreationPanel({ fragment, onDelete }: Props): JSX.Element {
  const { t } = useTranslation();
  const [createAttribute] = useCreateListFromPanelMutation();
  const [listType, setListType] = useState<ListType>();

  const onSave = async () => {
    if (!listType) return;
    await createAttribute({
      variables: {
        regionId: fragment.id,
        listType,
      },
    });
    await onDelete();
  };

  return (
    <AttributePanel
      title={t("ListCreationPanel.title")}
      unsaved
      onSave={onSave}
      onDelete={onDelete}
    >
      <ListFormField
        value={listType}
        onValueChange={(newListType: ListType) => setListType(newListType)}
      />
    </AttributePanel>
  );
}
