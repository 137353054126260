import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ListItemFormField } from "./ListItemFormField";
import { AttributePanel } from "../AttributePanel";
import {
  ListItemCreationPanelFragment,
  useCreateListItemFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: ListItemCreationPanelFragment;
  onDelete: () => Promise<void>;
};

export function ListItemCreationPanel({
  fragment,
  onDelete,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createAttribute] = useCreateListItemFromPanelMutation();
  const [ordinal, setOrdinal] = useState<number>();

  const onSave = async () => {
    if (ordinal === undefined) return;
    await createAttribute({
      variables: {
        regionId: fragment.id,
        ordinal: ordinal,
      },
    });
    await onDelete();
  };

  return (
    <AttributePanel
      title={t("ListItemCreationPanel.title")}
      unsaved
      onSave={onSave}
      onDelete={onDelete}
    >
      <ListItemFormField
        value={ordinal}
        onChange={(event) => setOrdinal(parseInt(event.target.value))}
      />
    </AttributePanel>
  );
}
