import { RegionSelectHandlerFragment } from "../client/generated";
import { EditorAction } from "../editor/reducers";
import { cn } from "@acdc2/ui/lib/utils";

type Props = {
  fragment: RegionSelectHandlerFragment;
  children: React.ReactNode;
  isSelected: boolean;
  dispatch: React.Dispatch<EditorAction>;
};

export function RegionSelectHandler({
  fragment,
  children,
  isSelected,
  dispatch,
}: Props): JSX.Element {
  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch({ type: "selectRegion", regionId: fragment.id });
  };

  return (
    <g onClick={onClick} className={cn({ "cursor-pointer": !isSelected })}>
      {children}
    </g>
  );
}
