import { Route, Routes } from "react-router";
import { Toaster } from "@acdc2/ui/components/sonner";
import { DocumentsScreen } from "./screens/DocumentsScreen";
import { PageScreen } from "./screens/PageScreen";
import { DocumentScreen } from "./screens/DocumentScreen";
import { LoginScreen } from "./screens/LoginScreen";
import { SettingsScreen } from "./screens/SettingsScreen";
import { HelpScreen } from "./screens/HelpScreen";
import { Shell } from "./shell/Shell";
import { routingPaths } from "./navigation/constants";
import { ProtectedRoute } from "./screens/ProtectedRoute";

export function App() {
  return (
    <Shell>
      <Routes>
        <Route index element={<LoginScreen />} />
        <Route element={<ProtectedRoute />}>
          <Route path={routingPaths.documents} element={<DocumentsScreen />} />
          <Route
            path={routingPaths.document(":documentId")}
            element={<DocumentScreen />}
          />
          <Route
            path={routingPaths.documentPage(":documentId", ":pageId")}
            element={<PageScreen />}
          />
          <Route path={routingPaths.settings} element={<SettingsScreen />} />
        </Route>
        <Route path={routingPaths.help} element={<HelpScreen />} />
      </Routes>
      <Toaster />
    </Shell>
  );
}
