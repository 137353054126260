import { useTranslation } from "react-i18next";
import { ComponentProps } from "react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@acdc2/ui/components/form";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@acdc2/ui/components/select";

type Props = ComponentProps<typeof Select>;

export function HeadingLevelFormField(props: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <FormField>
      <FormItem>
        <FormLabel>{t("HeadingLevelFormField.label")}</FormLabel>
        <Select {...props}>
          <FormControl>
            <SelectTrigger>
              <SelectValue
                placeholder={t("HeadingLevelFormField.placeholder")}
              />
            </SelectTrigger>
          </FormControl>
          <SelectContent>
            <SelectItem value="1">
              {t("HeadingLevelFormField.options.h1")}
            </SelectItem>
            <SelectItem value="2">
              {t("HeadingLevelFormField.options.h2")}
            </SelectItem>
            <SelectItem value="3">
              {t("HeadingLevelFormField.options.h3")}
            </SelectItem>
            <SelectItem value="4">
              {t("HeadingLevelFormField.options.h4")}
            </SelectItem>
            <SelectItem value="5">
              {t("HeadingLevelFormField.options.h5")}
            </SelectItem>
            <SelectItem value="6">
              {t("HeadingLevelFormField.options.h6")}
            </SelectItem>
          </SelectContent>
        </Select>
      </FormItem>
    </FormField>
  );
}
