import { DocumentExtensionTableCellFragment } from "../client/generated";
import { DocumentTableCell } from "./DocumentTableCell";

type Props = {
  fragment: DocumentExtensionTableCellFragment;
};

export function DocumentExtensionTableCell({ fragment }: Props): JSX.Element {
  return (
    <DocumentTableCell>{fragment.extension.toUpperCase()}</DocumentTableCell>
  );
}
