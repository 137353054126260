import { assertTypeName } from "../../client/guards";
import { TableRegionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: TableRegionPreviewFragment;
  tableAttributeId: string;
};

export function TableRegionPreview({
  fragment,
  tableAttributeId,
}: Props): JSX.Element {
  const tableAttribute = fragment.attributes.find(
    (a) => a.id === tableAttributeId,
  );
  assertTypeName(tableAttribute?.__typename === "TableAttribute");

  return (
    <>
      {tableAttribute.rows.length}⨯{tableAttribute.columns.length}
    </>
  );
}
