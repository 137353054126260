import { assertTypeName } from "../../client/guards";
import { useTranslation } from "react-i18next";
import { ArtifactRegionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: ArtifactRegionPreviewFragment;
  paginationArtifactAttributeId: string;
};

export function ArtifactRegionPreview({
  fragment,
  paginationArtifactAttributeId,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const paginationArtifactAttribute = fragment.attributes.find(
    (a) => a.id === paginationArtifactAttributeId,
  );
  assertTypeName(
    paginationArtifactAttribute?.__typename === "PaginationArtifactAttribute",
  );

  return (
    <>
      {t(`PaginationRegionPreview.preview.${paginationArtifactAttribute.type}`)}
    </>
  );
}
