import { Dispatch } from "react";
import { EditorAction, makeIdentifier, TabPrefix } from "../editor/reducers";
import { useTranslation } from "react-i18next";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuShortcut,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
} from "@acdc2/ui/components/context-menu";
import {
  RegionContextMenu2Fragment,
  useDeleteRegionFromContextMutation,
} from "../client/generated";

type Props = {
  fragment: RegionContextMenu2Fragment;
  dispatch: Dispatch<EditorAction>;
  children?: React.ReactNode;
};

export function RegionContextMenu2({
  fragment,
  dispatch,
  children,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [deleteRegion] = useDeleteRegionFromContextMutation();

  const onDelete = async () => {
    await deleteRegion({
      variables: { regionId: fragment.id },
      optimisticResponse: {
        deleteRegion: {
          userErrors: [],
          region: {
            __typename: "Region",
            id: fragment.id,
          },
        },
      },
      update(cache) {
        const id = cache.identify(fragment);
        cache.evict({ id });
        cache.gc();
      },
    });
  };

  const onEdit = () => {
    dispatch({
      type: "switchTab",
      tabIdentifier: makeIdentifier(TabPrefix.RegionUpdate, fragment.id),
    });
  };

  const switchTo = (tabPrefix: TabPrefix) => {
    dispatch({
      type: "switchTab",
      tabIdentifier: makeIdentifier(tabPrefix, fragment.id),
    });
  };

  return (
    <ContextMenu>
      <ContextMenuTrigger>{children}</ContextMenuTrigger>
      <ContextMenuContent className="w-48">
        <ContextMenuItem onClick={onEdit}>
          {t("RegionContextMenu2.labels.edit")}
        </ContextMenuItem>
        <ContextMenuSub>
          <ContextMenuSubTrigger>
            {t("RegionContextMenu2.labels.convert")}
          </ContextMenuSubTrigger>
          <ContextMenuSubContent>
            <ContextMenuItem
              onClick={() => switchTo(TabPrefix.HeadingRegionCreation)}
            >
              {t("RegionContextMenu2.labels.heading")}
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => switchTo(TabPrefix.TextRegionCreation)}
            >
              {t("RegionContextMenu2.labels.text")}
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => switchTo(TabPrefix.FormulaRegionCreation)}
            >
              {t("RegionContextMenu2.labels.formula")}
            </ContextMenuItem>

            <ContextMenuItem
              onClick={() => switchTo(TabPrefix.TableRegionCreation)}
            >
              {t("RegionContextMenu2.labels.table")}
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => switchTo(TabPrefix.ImageRegionCreation)}
            >
              {t("RegionContextMenu2.labels.image")}
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => switchTo(TabPrefix.FigureRegionCreation)}
            >
              {t("RegionContextMenu2.labels.figure")}
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => switchTo(TabPrefix.CaptionRegionCreation)}
            >
              {t("RegionContextMenu2.labels.caption")}
            </ContextMenuItem>

            <ContextMenuItem
              onClick={() => switchTo(TabPrefix.ListRegionCreation)}
            >
              {t("RegionContextMenu2.labels.list")}
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => switchTo(TabPrefix.ListItemRegionCreation)}
            >
              {t("RegionContextMenu2.labels.listItem")}
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => switchTo(TabPrefix.SublistRegionCreation)}
            >
              {t("RegionContextMenu2.labels.sublist")}
            </ContextMenuItem>

            <ContextMenuItem
              onClick={() => switchTo(TabPrefix.ArtifactRegionCreation)}
            >
              {t("RegionContextMenu2.labels.artifact")}
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => switchTo(TabPrefix.TOCRegionCreation)}
            >
              {t("RegionContextMenu2.labels.toc")}
            </ContextMenuItem>
            <ContextMenuSeparator />
            <ContextMenuItem
              onClick={() => switchTo(TabPrefix.HybridRegionCreation)}
            >
              {t("RegionContextMenu2.labels.hybrid")}
            </ContextMenuItem>
          </ContextMenuSubContent>
        </ContextMenuSub>
        <ContextMenuItem onClick={onDelete}>
          {t("RegionContextMenu2.labels.delete")}
          <ContextMenuShortcut>
            {t("RegionContextMenu2.shortcuts.delete")}
          </ContextMenuShortcut>
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
}
