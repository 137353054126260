import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import { HeadingLevelFormField } from "./HeadingLevelFormField";
import {
  HeadingLevelCreationPanelFragment,
  useCreateHeadingLevelFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: HeadingLevelCreationPanelFragment;
  onDelete: () => Promise<void>;
};

export function HeadingLevelCreationPanel({
  fragment,
  onDelete,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createAttribute] = useCreateHeadingLevelFromPanelMutation();
  const [level, setLevel] = useState<string>();

  const onSave = async () => {
    if (!level) return;
    await createAttribute({
      variables: {
        regionId: fragment.id,
        level: parseInt(level),
      },
    });
    await onDelete();
  };

  return (
    <AttributePanel
      title={t("HeadingLevelCreationPanel.title")}
      unsaved
      onSave={onSave}
      onDelete={onDelete}
    >
      <HeadingLevelFormField value={level} onValueChange={setLevel} />
    </AttributePanel>
  );
}
