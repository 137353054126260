import { useTranslation } from "react-i18next";
import { EditorPagesNavbarTabContentFragment } from "../client/generated";
import { NavbarTabContent } from "../shell/NavbarTabContent";
import { TabIdentifier } from "./reducers";
import { SidebarDivider } from "../shell/SidebarDivider";
import { PageThumbnail } from "../pages/PageThumbnail";
import { PagePagination } from "../pages/PagePagination";
import { ScrollArea } from "@acdc2/ui/components/scroll-area";

type Props = {
  fragment: EditorPagesNavbarTabContentFragment;
};

export function EditorPagesNavbarTabContent({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={TabIdentifier.Pages}
      title={t("EditorPagesNavbarTabContent.title")}
    >
      <ScrollArea className="@container flex-1">
        <div className="p-4 grid gap-4 grid-cols-1 @md:grid-cols-2 @2xl:grid-cols-3 @4xl:grid-cols-4 @6xl:grid-cols-5">
          {fragment.document.pages.pages.map((page) => (
            <PageThumbnail
              key={page.id}
              fragment={page}
              highlight={page.id === fragment.id}
            />
          ))}
        </div>
      </ScrollArea>
      <div>
        <SidebarDivider />
        <PagePagination key={fragment.id} fragment={fragment} />
      </div>
    </NavbarTabContent>
  );
}
