import * as Tabs from "@radix-ui/react-tabs";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@acdc2/ui/components/resizable";

type Props = {
  navbar: React.ReactNode;
  sidebar?: React.ReactNode;
  children?: React.ReactNode;
  tabsValue?: string;
  onTabsValueChange?: (value: string) => void;
};

export function Scaffold({
  navbar,
  sidebar,
  children,
  tabsValue,
  onTabsValueChange,
}: Props): JSX.Element {
  return (
    <Tabs.Root
      value={tabsValue}
      onValueChange={onTabsValueChange}
      orientation="vertical"
      asChild
    >
      <div className="h-screen flex flex-row">
        <ResizablePanelGroup
          direction="horizontal"
          autoSaveId="acdc-main-panel-group"
        >
          <>{navbar}</>

          {sidebar ? (
            <>
              <ResizablePanel minSize={16} defaultSize={16}>
                {sidebar}
              </ResizablePanel>
              <ResizableHandle withHandle />
            </>
          ) : null}

          <ResizablePanel minSize={32}>
            <main className="h-full flex flex-col">{children}</main>
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </Tabs.Root>
  );
}
