import { Client } from "@gradio/client";
import { Aabb } from "./generated";
import {
  blobToBase64,
  cropBlobImage,
  fetchAndCacheProtectedImage,
  fillBlobImage,
  padBlobImage,
} from "./images";

export async function predictImageContent(blob: Blob, prompt: string) {
  const data = await blobToBase64(blob);
  const app = await Client.connect(import.meta.env.VITE_ACDC2_DEEPSEEK_API_URL);

  // Upload image and prompt
  await app.predict(4, [prompt, [{ name: "my_image.png", data }]]);

  const result = await app.predict(5, [
    null,
    0.9, // top p
    0.1, // temperature
    1.1, // repetition penalty
    2048, // max generation tokens
    4096, // max history tokens
    "deepseek-ai/deepseek-vl2-small", // model
  ]);

  return (result.data as [[[string, string]]])[0][0][1];
}

export async function predictAlternative(
  imagePath: string,
  token: string,
  aabb: Aabb,
) {
  const blob = await fetchAndCacheProtectedImage(imagePath, token);

  const croppedBlob = await cropBlobImage(
    blob,
    aabb.left,
    aabb.top,
    aabb.width,
    aabb.height,
  );

  const paddedBlob = await padBlobImage(croppedBlob, 20);

  const croppedAndFilledBlob = await fillBlobImage(paddedBlob, "white");

  return await predictImageContent(
    croppedAndFilledBlob,
    "Write an accessibility alternative text for this <image>",
  );
}

export async function predictText(
  imagePath: string,
  token: string,
  aabb: Aabb,
) {
  const blob = await fetchAndCacheProtectedImage(imagePath, token);

  const croppedBlob = await cropBlobImage(
    blob,
    aabb.left,
    aabb.top,
    aabb.width,
    aabb.height,
  );

  const paddedBlob = await padBlobImage(croppedBlob, 20);

  const filledBlob = await fillBlobImage(paddedBlob, "white");

  return await predictImageContent(
    filledBlob,
    "Perform OCR on this <image> and return nothing but the recognized text!",
  );
}

export async function predictLatex(
  imagePath: string,
  token: string,
  aabb: Aabb,
) {
  const blob = await fetchAndCacheProtectedImage(imagePath, token);

  const croppedBlob = await cropBlobImage(
    blob,
    aabb.left,
    aabb.top,
    aabb.width,
    aabb.height,
  );

  const paddedBlob = await padBlobImage(croppedBlob, 20);

  const filledBlob = await fillBlobImage(paddedBlob, "white");

  return await predictImageContent(
    filledBlob,
    "Express the formular in this <image> in LaTeX",
  );
}
