import { assertTypeName } from "../../client/guards";
import { AttributeUpdateSection } from "./AttributeUpdateSection";
import { AttributeCreationSection } from "./AttributeCreationSection";
import { useHybridRegionEditorSuspenseQuery } from "../../client/generated";

type Props = {
  regionId: string;
};

export function HybridRegionEditor({ regionId }: Props): JSX.Element {
  const { data } = useHybridRegionEditorSuspenseQuery({
    variables: {
      regionId,
    },
  });

  const { region } = data;
  assertTypeName(region?.__typename === "Region");

  return (
    <div>
      <AttributeUpdateSection fragment={region} />
      <AttributeCreationSection fragment={region} />
    </div>
  );
}
