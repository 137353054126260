import { assertTypeName } from "../../client/guards";
import { TocRegionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: TocRegionPreviewFragment;
  tocAttributeId: string;
};

export function TOCRegionPreview({
  fragment,
  tocAttributeId,
}: Props): JSX.Element {
  const tocAttribute = fragment.attributes.find((a) => a.id === tocAttributeId);
  assertTypeName(tocAttribute?.__typename === "TableOfContentsAttribute");

  return <></>;
}
