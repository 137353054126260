import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@acdc2/ui/components/accordion";
import { Button } from "@acdc2/ui/components/button";
import { Input } from "@acdc2/ui/components/input";
import { useTranslation } from "react-i18next";
import {
  MetadataTitleAccordionItemFragment,
  useAddTitleMutation,
  useRemoveTitleMutation,
  useUpdateTitleMutation,
} from "../client/generated";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useState } from "react";
import { Icon } from "@mdi/react";
import { mdiCheck, mdiClose } from "@mdi/js";
import { MetadataTitleInput } from "./MetadataTitleInput";
import { MetadataTitleLanguageCombobox } from "./MetadataTitleLanguageCombobox";
import { TitleLanguage } from "./constants";

type Props = { fragment: MetadataTitleAccordionItemFragment };

export function MetadataTitleAccordionItem({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const [inputVisibility, setInputVisibility] = useState(false);
  const [newLanguage, setNewLanguage] = useState<TitleLanguage | null>();
  const [newTitle, setNewTitle] = useState<string | null>();

  const [addTitle] = useAddTitleMutation({ refetchQueries: "active" });
  const [removeTitle] = useRemoveTitleMutation({
    update(cache) {
      const id = cache.identify(fragment);
      cache.evict({ id });
      cache.gc();
    },
  });
  const [updateTitle] = useUpdateTitleMutation();

  const onAdd = async () => {
    if (!newLanguage || !newTitle) {
      return;
    }

    await addTitle({
      variables: {
        documentId: fragment.id,
        language: newLanguage,
        title: newTitle,
      },
    });

    setInputVisibility(false);
    setNewLanguage(null);
    setNewTitle(null);
  };

  const onCancel = () => {
    setInputVisibility(false);
    setNewLanguage(null);
    setNewTitle(null);
  };

  const onDelete = async (titleID: string) => {
    await removeTitle({ variables: { titleID } });
  };

  const onSave = async (
    titleID: string,
    newLanguage: string,
    newTitle: string,
  ) => {
    await updateTitle({
      variables: { titleID, title: newTitle, language: newLanguage },
    });
  };

  return (
    <AccordionItem value="titles">
      <AccordionTrigger className="px-4">
        {t("MetadataTitleAccordionItem.label")}
      </AccordionTrigger>
      <AccordionContent className="px-4 pt-1 flex flex-col gap-2">
        {fragment.metadata.titles.map((title) => (
          <MetadataTitleInput
            key={title.id}
            defaultValue={{
              language: (title.language as TitleLanguage) ?? "",
              title: title.title,
            }}
            onSave={(newLanguage, newTitle) =>
              onSave(title.id, newLanguage, newTitle)
            }
            onDelete={() => onDelete(title.id)}
          />
        ))}

        {inputVisibility && (
          <div className="flex flex-row gap-2">
            <MetadataTitleLanguageCombobox
              defaultValue={newLanguage ?? ""}
              onValueChange={(value) => setNewLanguage(value as TitleLanguage)}
            />

            <Input
              type="text"
              inputMode="text"
              placeholder={t("MetadataTitleAccordionItem.placeholders.title")}
              value={newTitle ?? ""}
              onChange={(event) => setNewTitle(event.target.value)}
              required
            />

            <div className="flex flex-row gap-2">
              <Button
                type="button"
                size="icon"
                variant="outline"
                onClick={() => onAdd()}
              >
                <Icon path={mdiCheck} size="1rem" />
              </Button>
              <Button
                type="button"
                size="icon"
                variant="outline"
                onClick={() => onCancel()}
              >
                <Icon path={mdiClose} size="1.25rem" />
              </Button>
            </div>
          </div>
        )}

        <AccessButton type="button" onClick={() => setInputVisibility(true)}>
          {t("MetadataTitleAccordionItem.labels.add")}
        </AccessButton>
      </AccordionContent>
    </AccordionItem>
  );
}
