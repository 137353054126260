import { useTranslation } from "react-i18next";
import { Button } from "@acdc2/ui/components/button";
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@acdc2/ui/components/card";

type Props = {
  title: string;
  description: string;
  onConvert: () => void;
};

export function RegionTypeCard({
  title,
  description,
  onConvert,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Card className="shadow-sm rounded-md">
      <CardHeader className="p-4">
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardFooter className="p-4 pt-0">
        <Button className="w-full" onClick={onConvert}>
          {t("RegionTypeCard.button.label")}
        </Button>
      </CardFooter>
    </Card>
  );
}
