import { mdiFileDocumentOutline } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { NavbarTabButton } from "../shell/NavbarTabButton";
import { TabIdentifier } from "./reducers";

export function EditorMetadataNavbarTabButton(): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabButton
      tooltip={t("EditorMetadataNavbarTabButton.tooltip")}
      value={TabIdentifier.Metadata}
      iconPath={mdiFileDocumentOutline}
    />
  );
}
