import { useTranslation } from "react-i18next";
import { Icon } from "@mdi/react";
import { mdiInformationOutline } from "@mdi/js";
import {
  Alert,
  AlertTitle,
  AlertDescription,
} from "@acdc2/ui/components/alert";

export function PageEmptyAlert(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Alert>
      <Icon path={mdiInformationOutline} size="1rem" />
      <AlertTitle>{t("PageEmptyAlert.title")}</AlertTitle>
      <AlertDescription>{t("PageEmptyAlert.description")}</AlertDescription>
    </Alert>
  );
}
