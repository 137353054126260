import { Input } from "@acdc2/ui/components/input";
import { mdiCheck, mdiClose, mdiDelete, mdiPencil } from "@mdi/js";
import { Icon } from "@mdi/react";
import { Button } from "@acdc2/ui/components/button";
import { useState } from "react";

type Props = {
  defaultValue: number;
  onSave: (width: number) => void;
  onDelete: () => void;
};

export function TableDimensionInput({
  defaultValue,
  onSave,
  onDelete,
}: Props): JSX.Element {
  const [isEditing, setIsEditing] = useState(false);
  const [editInput, setEditInput] = useState(defaultValue);

  const handleSave = (newWidth: number) => {
    onSave(newWidth);
    setIsEditing(false);
  };

  return (
    <div className="flex flex-row gap-2">
      <Input
        defaultValue={editInput}
        type="number"
        inputMode="numeric"
        min={0}
        max={1}
        step={0.01}
        onChange={(event) => setEditInput(parseFloat(event.target.value))}
        disabled={!isEditing}
        required
      />
      <div className="flex flex-row gap-2">
        {isEditing ? (
          <>
            <Button
              type="button"
              size="icon"
              variant="outline"
              onClick={() => handleSave(editInput)}
            >
              <Icon path={mdiCheck} size="1.25rem" className="m-2" />
            </Button>
            <Button
              type="button"
              size="icon"
              variant="outline"
              onClick={() => setIsEditing(false)}
            >
              <Icon path={mdiClose} size="1.25rem" className="m-2" />
            </Button>
          </>
        ) : (
          <>
            <Button
              type="button"
              size="icon"
              variant="outline"
              onClick={() => setIsEditing(true)}
              className="p-2"
            >
              <Icon path={mdiPencil} size="1.25rem" />
            </Button>
            <Button
              type="button"
              size="icon"
              variant="outline"
              onClick={() => onDelete()}
              className="p-2"
            >
              <Icon path={mdiDelete} size="1.25rem" />
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
