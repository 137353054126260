import { useTranslation } from "react-i18next";
import { Dispatch, useState } from "react";
import { EditorAction, EditorState, TabIdentifier } from "./reducers";
import { SidebarDivider } from "../shell/SidebarDivider";
import { NavbarTabContent } from "../shell/NavbarTabContent";
import { RegionsToolbox } from "../regions/RegionsToolbox";
import { RegionPreview } from "../regions/RegionPreview";
import { ScrollArea } from "@acdc2/ui/components/scroll-area";
import { PageEmptyAlert } from "../pages/PageEmptyAlert";
import { PageUnsegmentedAlert } from "../pages/PageUnsegmentedAlert";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  EditorRegionsNavbarTabContentFragment,
  RegionPreviewFragment,
  useUpdateReadingOrderMutation,
} from "../client/generated";

type Props = {
  state: EditorState;
  dispatch: Dispatch<EditorAction>;
  fragment: EditorRegionsNavbarTabContentFragment;
};

export function EditorRegionsNavbarTabContent({
  state,
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [updateReadingOrder] = useUpdateReadingOrderMutation();

  const [activeRegion, setActiveRegion] = useState<RegionPreviewFragment>();

  const items = [...fragment.regions].sort(
    (a, b) => a.readingOrderIndex - b.readingOrderIndex,
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const persistReadingOrder = async (
    newItems: (typeof fragment)["regions"],
  ) => {
    try {
      await Promise.allSettled([
        ...newItems.map((region, index) =>
          updateReadingOrder({
            variables: {
              regionId: region.id,
              readingOrderIndex: index,
            },
            optimisticResponse: {
              updateRegionReadingOrderIndex: {
                userErrors: [],
                region: {
                  ...region,
                  readingOrderIndex: index,
                },
              },
            },
          }),
        ),
      ]);
    } catch (e) {
      console.error("Failed to persist reading orders", e);
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    setActiveRegion(event.active.data.current as RegionPreviewFragment);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const activeId = event.active.id;
    const overId = event.over?.id;

    if (!overId) return;
    if (activeId === overId) return;

    const oldIndex = items.findIndex((region) => region.id === activeId);
    const newIndex = items.findIndex((region) => region.id === overId);
    const newItems = arrayMove(items, oldIndex, newIndex);
    persistReadingOrder(newItems);

    setActiveRegion(undefined);
  };

  return (
    <NavbarTabContent
      value={TabIdentifier.Regions}
      title={t("EditorRegionsNavbarTabContent.title")}
    >
      <div>
        <div className="p-4">
          <RegionsToolbox state={state} dispatch={dispatch} />
        </div>
        <SidebarDivider />
      </div>
      <ScrollArea className="flex-1">
        <div className="flex flex-col gap-4 p-4">
          {!fragment.available && <PageUnsegmentedAlert />}
          {items.length === 0 && <PageEmptyAlert />}

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {items.map((region) => (
                <RegionPreview
                  key={region.id}
                  fragment={region}
                  state={state}
                  dispatch={dispatch}
                />
              ))}
            </SortableContext>

            <DragOverlay>
              {activeRegion ? (
                <RegionPreview
                  fragment={activeRegion}
                  state={state}
                  dispatch={dispatch}
                />
              ) : null}
            </DragOverlay>
          </DndContext>
        </div>
      </ScrollArea>
    </NavbarTabContent>
  );
}
