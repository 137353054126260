import { AccessButton } from "@acdc2/ui/components/access-button";
import { mdiAutoFix } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Textarea } from "@acdc2/ui/components/textarea";
import { Button } from "@acdc2/ui/components/button";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "@acdc2/ui/components/popover";

type Props = {
  label: string;
  onPredict: () => Promise<string | undefined>;
  onAccept: (prediction: string) => void;
};

export function PredictionButton({
  label,
  onPredict,
  onAccept,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [predicting, setPredicting] = useState(false);
  const [prediction, setPrediction] = useState<string>();

  const predict = async () => {
    setPredicting(true);

    try {
      const newPrediction = await onPredict();
      setPrediction(newPrediction);
    } catch (error) {
      console.error("Prediction error:", error);
    } finally {
      setPredicting(false);
    }
  };

  const accept = () => {
    if (prediction === undefined) return;
    onAccept(prediction);
  };

  const onOpenChange = (open: boolean) => {
    if (open && prediction === undefined) {
      predict();
    }
  };

  return (
    <Popover onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button className="w-full gap-2">
          <Icon path={mdiAutoFix} size="1.25rem" />
          <span>{label}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-96 flex flex-col gap-2">
        <Textarea
          className="min-h-44"
          placeholder={t("PredictionButton.placeholder")}
          value={prediction}
          onChange={(event) => setPrediction(event.target.value)}
        />
        <div className="flex flex-row gap-2 justify-end">
          <AccessButton
            onClick={predict}
            loading={predicting}
            disabled={predicting}
          >
            {t("PredictionButton.labels.retry")}
          </AccessButton>
          <PopoverClose asChild>
            <Button onClick={accept} disabled={predicting}>
              {t("PredictionButton.labels.accept")}
            </Button>
          </PopoverClose>
        </div>
      </PopoverContent>
    </Popover>
  );
}
