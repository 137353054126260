import { useTranslation } from "react-i18next";
import { HybridRegionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: HybridRegionPreviewFragment;
};

export function HybridRegionPreview({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      {fragment.attributes
        .map((attr) => t(`HybridRegionPreview.preview.${attr.__typename}`))
        .join(", ")}
    </>
  );
}
