import { assertTypeName } from "../../client/guards";
import { FigureRegionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: FigureRegionPreviewFragment;
  figureAttributeId: string;
};

export function FigureRegionPreview({
  fragment,
  figureAttributeId,
}: Props): JSX.Element {
  const figureAttribute = fragment.attributes.find(
    (a) => a.id === figureAttributeId,
  );
  assertTypeName(figureAttribute?.__typename === "FigureAttribute");

  return <></>;
}
