import { Dispatch } from "react";
import { EditorAction } from "../../editor/reducers";
import { assertTypeName } from "../../client/guards";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { useFigureRegionEditorSuspenseQuery } from "../../client/generated";

type Props = {
  figureAttributeId: string;
  dispatch: Dispatch<EditorAction>;
};

export function FigureRegionEditor({
  figureAttributeId,
  dispatch,
}: Props): JSX.Element {
  const { data } = useFigureRegionEditorSuspenseQuery({
    variables: {
      figureAttributeId,
    },
  });

  const { figureAttr } = data;
  assertTypeName(figureAttr?.__typename === "FigureAttribute");

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
  };

  return <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch} />;
}
