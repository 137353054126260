import { useTransformComponent } from "react-zoom-pan-pinch";

export function usePageScale(): number {
  return useTransformComponent<number>(
    (contextState) => contextState.state.scale,
  );
}

export function useOverlayScale(): number {
  const pageScale = usePageScale();
  return Math.max(1, pageScale);
}
