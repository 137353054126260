import { useTranslation } from "react-i18next";
import { Accordion } from "@acdc2/ui/components/accordion";
import { EditorMetadataNavbarTabContentFragment } from "../client/generated";
import { TabIdentifier } from "./reducers";
import { NavbarTabContent } from "../shell/NavbarTabContent";
import { MetadataTitleAccordionItem } from "../metadata/MetadataTitleAccordionItem";
import { MetadataAuthorAccordionItem } from "../metadata/MetadataAuthorAccordionItem";
import { MetadataKeywordAccordionItem } from "../metadata/MetadataKeywordAccordionItem";
import { MetadataDateAccordionItem } from "../metadata/MetadataDateAccordionItem";
import { ScrollArea } from "@acdc2/ui/components/scroll-area";

type Props = {
  fragment: EditorMetadataNavbarTabContentFragment;
};

export function EditorMetadataNavbarTabContent({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={TabIdentifier.Metadata}
      title={t("EditorMetadataNavbarTabContent.title")}
    >
      <ScrollArea className="flex-1">
        <Accordion
          type="multiple"
          defaultValue={["titles", "authors", "keywords", "date"]}
          className="w-full"
        >
          <MetadataTitleAccordionItem fragment={fragment} />
          <MetadataAuthorAccordionItem fragment={fragment} />
          <MetadataKeywordAccordionItem fragment={fragment} />
          <MetadataDateAccordionItem fragment={fragment} />
        </Accordion>
      </ScrollArea>
    </NavbarTabContent>
  );
}
