import { ScrollArea } from "@acdc2/ui/components/scroll-area";
import { NavbarTabCloseButton } from "../../shell/NavbarTabCloseButton";
import { NavbarTabContent } from "../../shell/NavbarTabContent";
import { useTranslation } from "react-i18next";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { Dispatch, useState } from "react";
import { assertTypeName } from "../../client/guards";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { AlternativeTextFormField } from "../../attributes/alternative-text/AlternativeTextFormField";
import {
  ImageRegionCreationTabFragment,
  useCreateImageRegionMutation,
  useImageRegionCreationTabSuspenseQuery,
} from "../../client/generated";

type Props = {
  fragment: ImageRegionCreationTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export function ImageRegionCreationTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.ImageRegionCreation, fragment.id)}
      title={t("ImageRegionCreationTab.title")}
      right={<NavbarTabCloseButton dispatch={dispatch} />}
    >
      <ScrollArea className="flex-1">
        <Body fragment={fragment} dispatch={dispatch} />
      </ScrollArea>
    </NavbarTabContent>
  );
}

function Body({ fragment, dispatch }: Props) {
  const imageAttributeIds = fragment.attributes
    .filter((attr) => attr.__typename === "ImageAttribute")
    .map((attr) => attr.id);

  const alternativeTextAttributeIds = fragment.attributes
    .filter((attr) => attr.__typename === "AlternativeTextAttribute")
    .map((attr) => attr.id);

  const { data } = useImageRegionCreationTabSuspenseQuery({
    variables: {
      regionId: fragment.id,
      imageAttributeIds,
      alternativeTextAttributeIds,
    },
  });

  assertTypeName(data.region?.__typename === "Region");

  const [createRegion] = useCreateImageRegionMutation();

  const [alternativeText, setAlternativeText] = useState(() => {
    const attr = data.alternativeTextAttributes[0];
    if (!attr) return undefined;
    assertTypeName(attr.__typename === "AlternativeTextAttribute");
    return attr.alternativeText;
  });

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (alternativeText === undefined) return;

    await createRegion({
      variables: {
        regionId: fragment.id,
        alternativeText,
        excessAttributeIds: fragment.attributes.map((attr) => attr.id),
      },
      update: (cache) => {
        for (const attr of fragment.attributes) {
          const id = cache.identify(attr);
          cache.evict({ id });
          cache.gc();
        }
      },
    });
  };

  return (
    <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch}>
      <AlternativeTextFormField
        fragment={data.region}
        value={alternativeText}
        onChange={(event) => setAlternativeText(event.target.value)}
        onPrediction={setAlternativeText}
      />
    </RegionUpdateForm>
  );
}
