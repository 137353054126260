import { AttributePanel } from "../AttributePanel";
import { useTranslation } from "react-i18next";
import {
  FigureCreationPanelFragment,
  useCreateFigureFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: FigureCreationPanelFragment;
  onDelete: () => Promise<void>;
};

export function FigureCreationPanel({
  fragment,
  onDelete,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createAttribute] = useCreateFigureFromPanelMutation();

  const onSave = async () => {
    await createAttribute({
      variables: {
        regionId: fragment.id,
      },
    });
    await onDelete();
  };

  return (
    <AttributePanel
      title={t("FigureCreationPanel.title")}
      unsaved
      onSave={onSave}
      onDelete={onDelete}
    />
  );
}
