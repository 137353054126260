import { Dispatch, useState } from "react";
import { EditorAction } from "../../editor/reducers";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { assertTypeName } from "../../client/guards";
import { ListFormField } from "../../attributes/list/ListFormField";
import {
  ListType,
  useListRegionEditorSuspenseQuery,
  useUpdateListRegionMutation,
} from "../../client/generated";

type Props = {
  listAttributeId: string;
  dispatch: Dispatch<EditorAction>;
};

export function ListRegionEditor({
  listAttributeId,
  dispatch,
}: Props): JSX.Element {
  const { data } = useListRegionEditorSuspenseQuery({
    variables: {
      listAttributeId,
    },
  });

  const { listAttr } = data;
  assertTypeName(listAttr?.__typename === "ListAttribute");

  const [updateRegion] = useUpdateListRegionMutation();
  const [listType, setListType] = useState(listAttr.listType);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateRegion({
      variables: {
        listAttributeId,
        listType,
      },
    });
  };

  return (
    <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch}>
      <ListFormField
        value={listType}
        onValueChange={(newListType: ListType) => setListType(newListType)}
      />
    </RegionUpdateForm>
  );
}
