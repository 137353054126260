import { useTranslation } from "react-i18next";
import { NavbarTabContent } from "../../shell/NavbarTabContent";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { NavbarTabCloseButton } from "../../shell/NavbarTabCloseButton";
import { Dispatch } from "react";
import { ScrollArea } from "@acdc2/ui/components/scroll-area";
import { HybridRegionEditor } from "./HybridRegionEditor";
import { HybridRegionCreationTabFragment } from "../../client/generated";

type Props = {
  fragment: HybridRegionCreationTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export function HybridRegionCreationTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.HybridRegionCreation, fragment.id)}
      title={t("HybridRegionCreationTab.title")}
      right={<NavbarTabCloseButton dispatch={dispatch} />}
    >
      <ScrollArea className="flex-1">
        <HybridRegionEditor regionId={fragment.id} />
      </ScrollArea>
    </NavbarTabContent>
  );
}
