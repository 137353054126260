import { useState } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { Button } from "@acdc2/ui/components/button";
import { ButtonIconLabel } from "@acdc2/ui/components/button-icon-label";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@acdc2/ui/components/dialog";
import { DropdownMenuItem } from "@acdc2/ui/components/dropdown-menu";
import { mdiExport } from "@mdi/js";
import { Trans, useTranslation } from "react-i18next";
import {
  DocumentExportDropdownMenuItemFragment,
  ExporterType,
  useCreateArtifactMutation,
} from "../client/generated";
import { DocumentExportCombobox } from "./DocumentExportCombobox";
import { DocumentExportArtifact } from "./DocumentExportArtifact";

type Props = {
  fragment: DocumentExportDropdownMenuItemFragment;
};

export function DocumentExportDropdownMenuItem({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [exportType, setExportType] = useState<ExporterType>();
  const [isLoading, setIsLoading] = useState(false);

  const [createArtifact] = useCreateArtifactMutation();

  const exportDocument = async () => {
    if (!exportType) return;

    setIsLoading(true);

    try {
      await createArtifact({
        variables: {
          exportType,
          documentId: fragment.id,
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ButtonIconLabel
            path={mdiExport}
            description={t("DocumentExportDropdownMenuItem.label")}
          />
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("DocumentExportDropdownMenuItem.dialog.title")}
          </DialogTitle>
          <DialogDescription>
            <Trans
              i18nKey="DocumentExportDropdownMenuItem.dialog.description"
              components={{ "word-break": <span className="break-all" /> }}
              values={{ name: fragment.name }}
            />
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-row gap-2">
          <DocumentExportCombobox value={exportType} onSelect={setExportType} />
          <AccessButton
            variant="outline"
            disabled={!exportType}
            onClick={exportDocument}
            loading={isLoading}
          >
            {t("DocumentExportDropdownMenuItem.dialog.submit")}
          </AccessButton>
        </div>

        <div className="flex flex-col gap-3">
          <DialogTitle asChild>
            <h3>
              {t("DocumentExportDropdownMenuItem.dialog.artifacts.title")}
            </h3>
          </DialogTitle>
          {fragment.artifacts.artifacts.length > 0 ? (
            <ul className="flex flex-col gap-4">
              {fragment.artifacts.artifacts.map((artifact) => (
                <li key={artifact.id}>
                  <DocumentExportArtifact fragment={artifact} />
                </li>
              ))}
            </ul>
          ) : (
            <span className="text-sm text-muted-foreground">
              {t("DocumentExportDropdownMenuItem.dialog.artifacts.placeholder")}
            </span>
          )}
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button type="button">
              {t("DocumentExportDropdownMenuItem.dialog.done")}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
