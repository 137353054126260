import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import {
  TableHeaderCellCreationPanelFragment,
  useCreateTableHeaderCellFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: TableHeaderCellCreationPanelFragment;
  onDelete: () => Promise<void>;
};

export function TableHeaderCellCreationPanel({
  fragment,
  onDelete,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createAttribute] = useCreateTableHeaderCellFromPanelMutation();

  const onSave = async () => {
    await createAttribute({
      variables: {
        regionId: fragment.id,
      },
    });
    await onDelete();
  };

  return (
    <AttributePanel
      title={t("TableHeaderCellCreationPanel.title")}
      unsaved
      onSave={onSave}
      onDelete={onDelete}
    />
  );
}
