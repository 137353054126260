import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import { ListFormField } from "./ListFormField";
import {
  ListType,
  ListUpdatePanelFragment,
  useDeleteListFromPanelMutation,
  useUpdateListFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: ListUpdatePanelFragment;
};

export function ListUpdatePanel({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const [updateAttribute] = useUpdateListFromPanelMutation();
  const [deleteAttribute] = useDeleteListFromPanelMutation();
  const [listType, setListType] = useState(fragment.listType);

  const onSave = async () => {
    await updateAttribute({
      variables: {
        attributeId: fragment.id,
        listType,
      },
    });
  };

  const onDelete = async () => {
    await deleteAttribute({
      variables: { attributeId: fragment.id },
      update(cache) {
        const id = cache.identify(fragment);
        cache.evict({ id });
        cache.gc();
      },
    });
  };

  return (
    <AttributePanel
      title={t("ListUpdatePanel.title")}
      unsaved={listType !== fragment.listType}
      onSave={onSave}
      onDelete={onDelete}
    >
      <ListFormField
        value={listType}
        onValueChange={(newListType: ListType) => setListType(newListType)}
      />
    </AttributePanel>
  );
}
