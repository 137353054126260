import { ButtonIconLabel } from "@acdc2/ui/components/button-icon-label";
import { DropdownMenuItem } from "@acdc2/ui/components/dropdown-menu";
import { mdiTrashCan } from "@mdi/js";
import { Trans, useTranslation } from "react-i18next";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@acdc2/ui/components/dialog";
import { Button } from "@acdc2/ui/components/button";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useState } from "react";
import {
  DocumentDeleteDropdownMenuItemFragment,
  useDeleteDocumentMutation,
} from "../client/generated";

type Props = {
  fragment: DocumentDeleteDropdownMenuItemFragment;
};

export function DocumentDeleteDropdownMenuItem({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const [deleteDocument, { loading }] = useDeleteDocumentMutation({
    variables: { id: fragment.id },
    update(cache) {
      const id = cache.identify(fragment);
      cache.evict({ id });
      cache.gc();
    },
  });

  const onClick = async () => {
    try {
      await deleteDocument();
      setIsOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ButtonIconLabel
            path={mdiTrashCan}
            description={t("DocumentDeleteDropdownMenuItem.label")}
          />
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("DocumentDeleteDropdownMenuItem.alert.title")}
          </DialogTitle>
          <DialogDescription>
            <Trans
              i18nKey="DocumentDeleteDropdownMenuItem.alert.description"
              components={{ "word-break": <span className="break-all" /> }}
              values={{ name: fragment.name }}
            />
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              {t("DocumentDeleteDropdownMenuItem.alert.buttons.cancel")}
            </Button>
          </DialogClose>

          <AccessButton
            variant="destructive"
            onClick={onClick}
            loading={loading}
            disabled={loading}
            children={t("DocumentDeleteDropdownMenuItem.alert.buttons.confirm")}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
