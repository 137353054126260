import { useParams } from "react-router";
import { useDocumentScreenSuspenseQuery } from "../client/generated";
import { assertTypeName } from "../client/guards";
import { Sidebar } from "../shell/Sidebar";
import { Scaffold } from "../shell/Scaffold";
import { EditorMetadataNavbarTabButton } from "../editor/EditorMetadataNavbarTabButton";
import { EditorMetadataNavbarTabContent } from "../editor/EditorMetadataNavbarTabContent";
import { PageThumbnail } from "../pages/PageThumbnail";
import { OverviewNavbarButton } from "../editor/OverviewNavbarButton";
import { EditorPagesNavbarTabButton } from "../editor/EditorPagesNavbarTabButton";
import { EditorRegionsNavbarTabButton } from "../editor/EditorRegionsNavbarTabButton";
import { SidebarDivider } from "../shell/SidebarDivider";
import { PreviousPageNavbarButton } from "../editor/PreviousPageNavbarButton";
import { NextPageNavbarButton } from "../editor/NextPageNavbarButton";
import { TabIdentifier } from "../editor/reducers";
import { useState } from "react";
import { Navbar } from "../shell/Navbar";
import { ScrollArea } from "@acdc2/ui/components/scroll-area";

export function DocumentScreen(): JSX.Element {
  const [tabValue, setTabValue] = useState(TabIdentifier.Metadata);
  const { documentId } = useParams<{ documentId: string }>();
  const { data } = useDocumentScreenSuspenseQuery({
    variables: { documentId: documentId! },
  });

  assertTypeName(data.node?.__typename === "Document");

  return (
    <Scaffold
      tabsValue={tabValue}
      onTabsValueChange={(newTabValue) => {
        setTabValue(newTabValue as TabIdentifier);
      }}
      navbar={
        <Navbar>
          <OverviewNavbarButton fragment={data.node} disabled />
          <PreviousPageNavbarButton />
          <NextPageNavbarButton />
          <SidebarDivider />
          <EditorMetadataNavbarTabButton />
          <EditorPagesNavbarTabButton disabled />
          <EditorRegionsNavbarTabButton disabled />
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <EditorMetadataNavbarTabContent fragment={data.node} />
        </Sidebar>
      }
    >
      <ScrollArea className="h-full bg-gray-100 dark:bg-gray-900">
        <div className="@container p-4 flex flex-col gap-4">
          <h1 className="text-lg">{data.node.name}</h1>
          <div className="grid gap-4 grid-cols-1 @md:grid-cols-2 @2xl:grid-cols-3 @4xl:grid-cols-4 @6xl:grid-cols-5">
            {data.node.pages.pages.map((page) => (
              <PageThumbnail key={page.id} fragment={page} />
            ))}
          </div>
        </div>
      </ScrollArea>
    </Scaffold>
  );
}
