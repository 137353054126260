import { createPortal } from "react-dom";
import {
  AccessContextMenuContent,
  AccessContextMenuSeparator,
} from "@acdc2/ui/components/access-context-menu";
import { RegionDeleteContextMenuItem } from "./RegionDeleteContextMenuItem";
import { RegionCutContextMenuItem } from "./RegionCutContextMenuItem";
import { RegionCopyContextMenuItem } from "./RegionCopyContextMenuItem";
import { RegionPasteContextMenuItem } from "./RegionPasteContextMenuItem";
import { RegionDuplicateContextMenuItem } from "./RegionDuplicateContextMenuItem";

type Props = {
  position: { x: number; y: number };
  onDelete: () => void;
  onClose: () => void;
};

export function RegionContextMenu({
  position,
  onDelete,
  onClose,
}: Props): JSX.Element {
  const onContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onClose();
  };

  return createPortal(
    <div
      className="fixed top-0 left-0 right-0 bottom-0 z-10"
      onClick={onClose}
      onContextMenu={onContextMenu}
    >
      <div style={{ left: position.x, top: position.y }} className="absolute">
        <AccessContextMenuContent className="w-64">
          <RegionCutContextMenuItem disabled />
          <RegionCopyContextMenuItem disabled />
          <RegionPasteContextMenuItem disabled />
          <AccessContextMenuSeparator />
          <RegionDuplicateContextMenuItem disabled />
          <RegionDeleteContextMenuItem onDelete={onDelete} />
        </AccessContextMenuContent>
      </div>
    </div>,
    document.body,
  );
}
