import Confetti from "react-canvas-confetti/dist/presets/realistic";
import { Icon } from "@mdi/react";
import { Button, ButtonProps } from "./button";
import { mdiLoading } from "@mdi/js";
import { useRef } from "react";
import { TConductorInstance } from "react-canvas-confetti/dist/types";

type Props = ButtonProps & {
  loading?: boolean;
  confetti?: boolean;
};

export function AccessButton({
  onClick,
  loading,
  confetti,
  children,
  ...props
}: Props): JSX.Element {
  const conductorRef = useRef<TConductorInstance>();

  const onClickWithConfetti = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (confetti) {
      conductorRef.current?.shoot();
    }
    onClick?.(event);
  };

  return (
    <Button {...props} onClick={onClickWithConfetti}>
      {loading ? (
        <Icon
          path={mdiLoading}
          size="1.25rem"
          className="mr-2 h-4 w-4 animate-spin"
        />
      ) : null}
      {children}
      <Confetti
        className="z-50 fixed top-0 w-full h-screen pointer-events-none"
        decorateOptions={(options) => ({
          ...options,
          zIndex: 999,
          colors: ["#ff577f", "#ff884b"],
          origin: { x: 0.5, y: 0 },
          angle: 270,
          startVelocity: 25,
        })}
        onInit={({ conductor }) => {
          conductorRef.current = conductor;
        }}
      />
    </Button>
  );
}
