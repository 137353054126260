import { ComponentProps, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RegionSelectFragment } from "../client/generated";
import { RegionThumbnail } from "./RegionThumbnail";
import { AttributeKind } from "../types/utils";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@acdc2/ui/components/select";

type Props = ComponentProps<typeof Select> & {
  fragment: RegionSelectFragment;
  qualifyingAttributes: AttributeKind[];
};

export function RegionSelect({
  fragment,
  qualifyingAttributes,
  value,
  onOpenChange,
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation();
  const currentPageGroupRef = useRef<HTMLDivElement>(null);

  const selectedPage = fragment.document.pages.pages.find((page) =>
    page.regions.some((region) => region.id === value),
  );

  const handleOpenChange = (isOpen: boolean) => {
    onOpenChange?.(isOpen);

    if (isOpen) {
      setTimeout(() => {
        currentPageGroupRef.current?.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      });
    }
  };

  return (
    <Select value={value} onOpenChange={handleOpenChange} {...props}>
      <SelectTrigger>
        <SelectValue placeholder={t("RegionSelect.placeholder")}>
          {t("RegionSelect.selection", { page: selectedPage?.index })}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {fragment.document.pages.pages.map((page) => {
          const selectableRegions = page.regions.filter((region) =>
            region.attributes.some(
              (attr) =>
                attr.__typename &&
                qualifyingAttributes.includes(attr.__typename),
            ),
          );

          if (selectableRegions.length === 0) {
            return null;
          }

          return (
            <SelectGroup
              key={page.id}
              ref={
                fragment.page.id === page.id ? currentPageGroupRef : undefined
              }
            >
              <SelectLabel>
                {fragment.page.id === page.id
                  ? t("RegionSelect.groupLabel.current", { page: page.index })
                  : t("RegionSelect.groupLabel.default", { page: page.index })}
              </SelectLabel>
              {selectableRegions.map((region) => (
                <SelectItem
                  key={region.id}
                  value={region.id}
                  className="flex-col justify-center items-stretch"
                >
                  <RegionThumbnail fragment={region} />
                </SelectItem>
              ))}
            </SelectGroup>
          );
        })}
      </SelectContent>
    </Select>
  );
}
