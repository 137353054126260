import { SVGProps } from "react";
import { Vertex } from "../client/generated";
import { cn } from "@acdc2/ui/lib/utils";
import { RegionType } from "../types/utils";
import { useOverlayScale } from "../editor/hooks";

type Props = SVGProps<SVGPolygonElement> & {
  vertices: Vertex[];
  regionType: RegionType;
};

export function RegionPolygon({
  vertices,
  regionType,
  className,
  ...rest
}: Props) {
  const overlayScale = useOverlayScale();

  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="none">
      <polygon
        points={vertices
          .map((vertex) => `${vertex.left * 100},${vertex.top * 100}`)
          .join(" ")}
        strokeWidth={2 / overlayScale}
        vectorEffect="non-scaling-stroke"
        tabIndex={0}
        className={cn(
          "outline-none fill-transparent mix-blend-multiply",
          {
            "animate-untyped-attribute focus:fill-attr-untyped-mid/50 hover:fill-attr-untyped-mid/50":
              regionType === "Untyped",
            "animate-hybrid-attribute focus:fill-attr-hybrid-mid/50 hover:fill-attr-hybrid-mid/50":
              regionType === "Hybrid",
            "animate-heading-attribute focus:fill-attr-heading-mid/50 hover:fill-attr-heading-mid/50":
              regionType === "Heading",
            "animate-caption-attribute focus:fill-attr-caption-mid/50 hover:fill-attr-caption-mid/50":
              regionType === "Caption",
            "animate-formula-attribute focus:fill-attr-formula-mid/50 hover:fill-attr-formula-mid/50":
              regionType === "Formula",
            "animate-figure-attribute focus:fill-attr-figure-mid/50 hover:fill-attr-figure-mid/50":
              regionType === "Figure",
            "animate-image-attribute focus:fill-attr-image-mid/50 hover:fill-attr-image-mid/50":
              regionType === "Image",
            "animate-list-attribute focus:fill-attr-list-mid/50 hover:fill-attr-list-mid/50":
              regionType === "List" ||
              regionType === "ListItem" ||
              regionType === "Sublist",
            "animate-artifact-attribute focus:fill-attr-artifact-mid/50 hover:fill-attr-artifact-mid/50":
              regionType === "Artifact",
            "animate-table-attribute focus:fill-attr-table-mid/50 hover:fill-attr-table-mid/50":
              regionType === "Table",
            "animate-toc-attribute focus:fill-attr-toc-mid/50 hover:fill-attr-toc-mid/50":
              regionType === "TOC",
            "animate-text-attribute focus:fill-attr-text-mid/50 hover:fill-attr-text-mid/50":
              regionType === "Text",
          },
          className,
        )}
        {...rest}
      />
    </svg>
  );
}
