import introspection from "./generated/introspection";
import { InMemoryCache } from "@apollo/client";

export const cache = new InMemoryCache({
  ...introspection,
  typePolicies: {
    RegionConnection: { keyFields: false },
    IssueConnection: { keyFields: false },
    PageConnection: { keyFields: false },
    ArtifactConnection: { keyFields: false },
    UserConnection: { keyFields: false },
    MembershipConnection: { keyFields: false },

    Membership: {
      keyFields: ["document", ["id"], "user", ["id"]],
    },
    Metadata: {
      keyFields: ["document", ["id"]],
    },
  },
});
