import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";

import { cn } from "@acdc2/ui/lib/utils";
import { Label } from "@acdc2/ui/components/label";

export function Form(props: React.HTMLAttributes<HTMLFormElement>) {
  return <form {...props} />;
}

export function FormField({
  className,
  ...props
}: React.HTMLAttributes<HTMLFieldSetElement>) {
  return <fieldset className={cn("group", className)} {...props} />;
}

type FormItemContextValue = {
  formItemId?: string;
  formDescriptionId?: string;
  formMessageId?: string;
};

const FormItemContext = React.createContext<FormItemContextValue>({});

export function FormItem({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  const id = React.useId();
  const formItemId = `${id}-form-field`;
  const formDescriptionId = `${id}-form-description`;
  const formMessageId = `${id}-form-message`;

  return (
    <FormItemContext.Provider
      value={{ formItemId, formDescriptionId, formMessageId }}
    >
      <div className={cn("space-y-2", className)} {...props} />
    </FormItemContext.Provider>
  );
}

export function FormLabel({
  className,
  ...props
}: React.ComponentProps<typeof LabelPrimitive.Label>) {
  const { formItemId } = React.useContext(FormItemContext);

  return (
    <Label
      className={cn("group-invalid:text-destructive", className)}
      htmlFor={formItemId}
      {...props}
    />
  );
}

export function FormControl(props: React.ComponentProps<typeof Slot>) {
  const { formItemId, formDescriptionId } = React.useContext(FormItemContext);

  return (
    <Slot id={formItemId} aria-describedby={formDescriptionId} {...props} />
  );
}

export function FormDescription({
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) {
  const { formDescriptionId } = React.useContext(FormItemContext);

  return (
    <p
      id={formDescriptionId}
      className={cn("text-[0.8rem] text-muted-foreground", className)}
      {...props}
    />
  );
}

export function FormMessage({
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) {
  const { formMessageId } = React.useContext(FormItemContext);

  return (
    <p
      id={formMessageId}
      className={cn(
        "text-[0.8rem] font-medium text-destructive hidden group-invalid:block",
        className,
      )}
      {...props}
    />
  );
}
