import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ColumnFiltersState,
  SortingState,
  Updater,
  VisibilityState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@acdc2/ui/components/table";
import {
  DocumentRowFragment,
  DocumentTableFragment,
} from "../client/generated";
import { DocumentUploadedByTableHeader } from "./DocumentUploadedByTableHeader";
import { DocumentNameTableHeader } from "./DocumentNameTableHeader";
import { DocumentModifiedAtTableHeader } from "./DocumentModifiedAtTableHeader";
import { DocumentExtensionTableHeader } from "./DocumentExtensionTableHeader";
import { DocumentModifiedByTableHeader } from "./DocumentModifiedByTableHeader";
import { DocumentUploadedAtTableHeader } from "./DocumentUploadedAtTableHeader";
import { DocumentTableActionsDropdownMenu } from "./DocumentTableActionsDropdownMenu";
import { DocumentUploadedByTableCell } from "./DocumentUploadedByTableCell";
import { DocumentModifiedByTableCell } from "./DocumentModifiedByTableCell";
import { DocumentNameTableCell } from "./DocumentNameTableCell";
import { DocumentExtensionTableCell } from "./DocumentExtensionTableCell";
import { DocumentUploadedAtTableCell } from "./DocumentUploadedAtTableCell";
import { DocumentModifiedAtTableCell } from "./DocumentModifiedAtTableCell";
import { DocumentTableToolbar } from "./DocumentTableToolbar";

type Props = {
  fragment: DocumentTableFragment;
};

export function DocumentTable({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([
    { id: "modifiedAt", desc: true },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    () => {
      const serializedVisibility = localStorage.getItem(VISIBILITY_STORAGE_KEY);
      return serializedVisibility ? JSON.parse(serializedVisibility) : {};
    },
  );

  const onColumnVisibilityChange = (
    updaterOrValue: Updater<VisibilityState>,
  ) => {
    setColumnVisibility((oldColumnVisibility) => {
      const newColumnVisibility =
        typeof updaterOrValue === "function"
          ? updaterOrValue(oldColumnVisibility)
          : updaterOrValue;

      localStorage.setItem(
        VISIBILITY_STORAGE_KEY,
        JSON.stringify(newColumnVisibility),
      );

      return newColumnVisibility;
    });
  };

  const table = useReactTable({
    data: fragment.memberships,
    columns,
    enableSortingRemoval: true,
    state: { sorting, columnFilters, columnVisibility },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <div>
      <DocumentTableToolbar fragment={fragment} table={table} />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={table.getVisibleFlatColumns().length}
                  className="h-24 text-center"
                >
                  {t("DocumentTable.placeholder")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

const columnHelper = createColumnHelper<DocumentRowFragment>();

const columns = [
  columnHelper.accessor("document.name", {
    id: "name",
    header: ({ column }) => <DocumentNameTableHeader column={column} />,
    cell: (cellContext) => (
      <DocumentNameTableCell fragment={cellContext.row.original.document} />
    ),
  }),

  columnHelper.accessor("document.uploadedBy.username", {
    id: "uploadedBy",
    header: ({ column }) => <DocumentUploadedByTableHeader column={column} />,
    cell: (cellContext) => (
      <DocumentUploadedByTableCell
        fragment={cellContext.row.original.document}
      />
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  }),

  columnHelper.accessor("document.uploadedAt", {
    id: "uploadedAt",
    header: ({ column }) => <DocumentUploadedAtTableHeader column={column} />,
    cell: (cellContext) => (
      <DocumentUploadedAtTableCell
        fragment={cellContext.row.original.document}
      />
    ),
  }),

  columnHelper.accessor("document.lastModifiedBy.username", {
    id: "modifiedBy",
    header: ({ column }) => <DocumentModifiedByTableHeader column={column} />,
    cell: (cellContext) => (
      <DocumentModifiedByTableCell
        fragment={cellContext.row.original.document}
      />
    ),
  }),

  columnHelper.accessor("document.lastModifiedAt", {
    id: "modifiedAt",
    header: ({ column }) => <DocumentModifiedAtTableHeader column={column} />,
    cell: (cellContext) => (
      <DocumentModifiedAtTableCell
        fragment={cellContext.row.original.document}
      />
    ),
  }),

  columnHelper.accessor("document.extension", {
    id: "extension",
    header: ({ column }) => <DocumentExtensionTableHeader column={column} />,
    cell: (cellContext) => (
      <DocumentExtensionTableCell
        fragment={cellContext.row.original.document}
      />
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  }),

  columnHelper.display({
    id: "actions",
    cell: ({ row }) => (
      <DocumentTableActionsDropdownMenu fragment={row.original.document} />
    ),
  }),
];

const VISIBILITY_STORAGE_KEY = "documentTableColumnVisibility";
