import { ScrollArea } from "@acdc2/ui/components/scroll-area";
import { NavbarTabCloseButton } from "../../shell/NavbarTabCloseButton";
import { NavbarTabContent } from "../../shell/NavbarTabContent";
import { useTranslation } from "react-i18next";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { Dispatch, useState } from "react";
import { assertTypeName } from "../../client/guards";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { TextFormField } from "../../attributes/text/TextFormField";
import {
  TextRegionCreationTabFragment,
  useCreateTextRegionMutation,
  useTextRegionCreationTabSuspenseQuery,
} from "../../client/generated";

type Props = {
  fragment: TextRegionCreationTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export function TextRegionCreationTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.TextRegionCreation, fragment.id)}
      title={t("TextRegionCreationTab.title")}
      right={<NavbarTabCloseButton dispatch={dispatch} />}
    >
      <ScrollArea className="flex-1">
        <Body fragment={fragment} dispatch={dispatch} />
      </ScrollArea>
    </NavbarTabContent>
  );
}

function Body({ fragment, dispatch }: Props) {
  const textAttributeIds = fragment.attributes
    .filter((attr) => attr.__typename === "TextAttribute")
    .map((attr) => attr.id);

  const { data } = useTextRegionCreationTabSuspenseQuery({
    variables: {
      regionId: fragment.id,
      textAttributeIds,
    },
  });

  assertTypeName(data.region?.__typename === "Region");

  const [createRegion] = useCreateTextRegionMutation();

  const [text, setText] = useState(() => {
    const attr = data.textAttributes[0];
    if (!attr) return undefined;
    assertTypeName(attr.__typename === "TextAttribute");
    return attr.text;
  });

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (text === undefined) return;

    await createRegion({
      variables: {
        regionId: fragment.id,
        text,
        excessAttributeIds: fragment.attributes.map((attr) => attr.id),
      },
      update: (cache) => {
        for (const attr of fragment.attributes) {
          const id = cache.identify(attr);
          cache.evict({ id });
          cache.gc();
        }
      },
    });
  };

  return (
    <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch}>
      <TextFormField
        fragment={data.region}
        value={text}
        onChange={(event) => setText(event.target.value)}
        onPrediction={setText}
      />
    </RegionUpdateForm>
  );
}
