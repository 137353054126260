import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@acdc2/ui/components/accordion";
import { useTranslation } from "react-i18next";
import {
  MetadataAuthorAccordionItemFragment,
  useAddAuthorMutation,
  useRemoveAuthorMutation,
  useRenameAuthorMutation,
} from "../client/generated";
import { useState } from "react";
import { MetadataAuthorInput } from "./MetadataAuthorInput";
import { Input } from "@acdc2/ui/components/input";
import { Button } from "@acdc2/ui/components/button";
import { mdiCheck, mdiClose } from "@mdi/js";
import { Icon } from "@mdi/react";
import { AccessButton } from "@acdc2/ui/components/access-button";

type Props = {
  fragment: MetadataAuthorAccordionItemFragment;
};

export function MetadataAuthorAccordionItem({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();

  const [newAuthor, setNewAuthor] = useState<string | null>();
  const [inputVisibility, setInputVisibility] = useState<boolean>(false);

  const [addAuthor] = useAddAuthorMutation({
    refetchQueries: "active",
  });
  const [renameAuthor] = useRenameAuthorMutation();
  const [removeAuthor] = useRemoveAuthorMutation({
    update(cache) {
      const id = cache.identify(fragment);
      cache.evict({ id });
      cache.gc();
    },
  });

  const onSave = async (id: string, newAuthor: string) => {
    await renameAuthor({
      variables: {
        authorId: id,
        name: newAuthor,
      },
    });
  };

  const onDelete = async (id: string) => {
    await removeAuthor({
      variables: { authorId: id },
    });
  };

  const onCancel = () => {
    setInputVisibility(false);
    setNewAuthor(null);
  };

  const onAdd = async () => {
    if (!newAuthor) return;
    await addAuthor({
      variables: { documentId: fragment.id, name: newAuthor },
    });
    setInputVisibility(false);
    setNewAuthor(null);
  };

  return (
    <AccordionItem value="authors">
      <AccordionTrigger className="px-4">
        {t("MetadataAuthorAccordionItem.label")}
      </AccordionTrigger>
      <AccordionContent className="px-4 pt-1 flex flex-col gap-2">
        {fragment.metadata.authors.map((author) => (
          <MetadataAuthorInput
            defaultValue={author.name}
            key={author.id}
            onDelete={() => onDelete(author.id)}
            onSave={(newAuthor: string) => onSave(author.id, newAuthor)}
          />
        ))}

        {inputVisibility && (
          <div className="flex flex-row gap-2">
            <Input
              type="text"
              inputMode="text"
              placeholder={t("MetadataAuthorAccordionItem.placeholder")}
              value={newAuthor ?? ""}
              onChange={(event) => setNewAuthor(event.target.value)}
              required
            />
            <div className="flex flex-row gap-2">
              <Button
                type="button"
                size="icon"
                variant="outline"
                onClick={() => onAdd()}
              >
                <Icon path={mdiCheck} size="1.25rem" />
              </Button>
              <Button
                type="button"
                size="icon"
                variant="outline"
                onClick={() => onCancel()}
              >
                <Icon path={mdiClose} size="1.25rem" />
              </Button>
            </div>
          </div>
        )}
        <AccessButton type="button" onClick={() => setInputVisibility(true)}>
          {t("MetadataAuthorAccordionItem.labels.add")}
        </AccessButton>
      </AccordionContent>
    </AccordionItem>
  );
}
