import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import { ExpressionReferenceFormField } from "./ExpressionReferenceFormField";
import { ExpressionPreviewFormField } from "./ExpressionPreviewFormField";
import { ExpressionFormField } from "./ExpressionFormField";
import {
  ExpressionUpdatePanelFragment,
  useDeleteExpressionFromPanelMutation,
  useUpdateExpressionFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: ExpressionUpdatePanelFragment;
};

export function ExpressionUpdatePanel({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const [updateAttribute] = useUpdateExpressionFromPanelMutation();
  const [deleteAttribute] = useDeleteExpressionFromPanelMutation();
  const [latex, setLatex] = useState(fragment.expressionLatex);

  const onSave = async () => {
    await updateAttribute({
      variables: {
        attributeId: fragment.id,
        expressionLatex: latex,
      },
    });
  };

  const onDelete = async () => {
    await deleteAttribute({
      variables: { attributeId: fragment.id },
      update(cache) {
        const id = cache.identify(fragment);
        cache.evict({ id });
        cache.gc();
      },
    });
  };

  return (
    <AttributePanel
      title={t("ExpressionUpdatePanel.title")}
      unsaved={latex !== fragment.expressionLatex}
      onSave={onSave}
      onDelete={onDelete}
    >
      <div className="flex flex-col gap-4">
        <ExpressionReferenceFormField fragment={fragment.region} />
        <ExpressionPreviewFormField latex={latex} />
        <ExpressionFormField
          fragment={fragment.region}
          onPrediction={setLatex}
          value={latex}
          onChange={(event) => setLatex(event.target.value)}
        />
      </div>
    </AttributePanel>
  );
}
