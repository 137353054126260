import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import { PaginationArtifactFormField } from "./PaginationArtifactFormField";
import {
  PaginationArtifactType,
  PaginationArtifactUpdatePanelFragment,
  useDeletePaginationArtifactFromPanelMutation,
  useUpdatePaginationArtifactFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: PaginationArtifactUpdatePanelFragment;
};

export function PaginationArtifactUpdatePanel({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [updateAttribute] = useUpdatePaginationArtifactFromPanelMutation();
  const [deleteAttribute] = useDeletePaginationArtifactFromPanelMutation();
  const [type, setType] = useState(fragment.type);

  const onSave = async () => {
    await updateAttribute({
      variables: {
        attributeId: fragment.id,
        type,
      },
    });
  };

  const onDelete = async () => {
    await deleteAttribute({
      variables: { attributeId: fragment.id },
      update(cache) {
        const id = cache.identify(fragment);
        cache.evict({ id });
        cache.gc();
      },
    });
  };

  return (
    <AttributePanel
      title={t("PaginationArtifactUpdatePanel.title")}
      unsaved={type !== fragment.type}
      onSave={onSave}
      onDelete={onDelete}
    >
      <PaginationArtifactFormField
        value={type}
        onValueChange={(newType: PaginationArtifactType) => setType(newType)}
      />
    </AttributePanel>
  );
}
