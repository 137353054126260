import { Textarea } from "@acdc2/ui/components/textarea";
import { TextPredictionButton } from "./TextPredictionButton";
import { ComponentProps } from "react";
import { TextFormFieldFragment } from "../../client/generated";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@acdc2/ui/components/form";

type Props = ComponentProps<"textarea"> & {
  fragment: TextFormFieldFragment;
  onPrediction: (text: string) => void;
};

export function TextFormField({
  fragment,
  onPrediction,
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <FormField>
      <FormItem>
        <FormLabel>{t("TextFormField.label")}</FormLabel>
        <FormControl>
          <Textarea required rows={8} {...props} />
        </FormControl>
        <TextPredictionButton fragment={fragment} onAccept={onPrediction} />
      </FormItem>
    </FormField>
  );
}
