import { useTranslation } from "react-i18next";
import { mdiChevronLeft } from "@mdi/js";
import { NavbarButton } from "../shell/NavbarButton";
import { NavbarButtonIcon } from "../shell/NavbarButtonIcon";
import { PreviousPageNavbarButtonFragment } from "../client/generated";
import { routingPaths } from "../navigation/constants";
import { useNavigateTransition } from "../navigation/useNavigateTransition";

type Props = {
  fragment?: PreviousPageNavbarButtonFragment;
};

export function PreviousPageNavbarButton({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const [isPending, navigate] = useNavigateTransition();

  const onClick = () => {
    if (fragment && fragment.previousPage) {
      navigate(
        routingPaths.documentPage(
          fragment.document.id,
          fragment.previousPage.id,
        ),
      );
    }
  };

  return (
    <NavbarButton
      tooltip={t("PreviousPageNavbarButton.tooltip")}
      loading={isPending}
    >
      <NavbarButtonIcon
        path={mdiChevronLeft}
        disabled={!fragment?.previousPage}
        onClick={onClick}
      />
    </NavbarButton>
  );
}
