import { useTranslation } from "react-i18next";
import { assertTypeName } from "../../client/guards";
import { HeadingRegionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: HeadingRegionPreviewFragment;
  headerLevelAttributeId: string;
  textAttributeId: string;
};

export function HeadingRegionPreview({
  fragment,
  headerLevelAttributeId,
  textAttributeId,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const headerLevelAttribute = fragment.attributes.find(
    (a) => a.id === headerLevelAttributeId,
  );
  assertTypeName(headerLevelAttribute?.__typename === "HeaderLevelAttribute");

  const textAttribute = fragment.attributes.find(
    (a) => a.id === textAttributeId,
  );
  assertTypeName(textAttribute?.__typename === "TextAttribute");

  return (
    <>
      {t("HeadingRegionPreview.preview", {
        level: headerLevelAttribute.level,
        text: textAttribute.text,
      })}
    </>
  );
}
