import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@acdc2/ui/components/form";
import { Input } from "@acdc2/ui/components/input";
import { useTranslation } from "react-i18next";

type Props = React.ComponentProps<typeof Input>;

export function TableCellRowSpanFormField(props: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <FormField>
      <FormItem>
        <FormLabel>{t("TableCellRowSpanFormField.label")}</FormLabel>
        <FormControl>
          <Input
            placeholder={t("TableCellRowSpanFormField.placeholder")}
            type="number"
            inputMode="numeric"
            min={1}
            required
            {...props}
          />
        </FormControl>
      </FormItem>
    </FormField>
  );
}
