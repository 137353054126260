import { useTranslation } from "react-i18next";
import { Scaffold } from "../shell/Scaffold";
import { Navbar } from "../shell/Navbar";

export function HelpScreen(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Scaffold navbar={<Navbar />}>
      <h1>{t("HelpScreen.title")}</h1>
    </Scaffold>
  );
}
