import { default as katex } from "katex";
import { useEffect, useRef } from "react";

type Props = {
  expression: string;
};

export function KaTeX({ expression }: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    katex.render(expression, ref.current, {
      throwOnError: false,
      displayMode: true,
      output: "mathml",
    });
  }, [ref, expression]);

  return <div ref={ref} />;
}
