import { useState } from "react";
import { v4 as uuid } from "uuid";
import { AttributeKind } from "../utils";
import { HeadingLevelCreationPanel } from "../../attributes/heading-level/HeadingLevelCreationPanel";
import { AttributeCreationSectionFragment } from "../../client/generated";
import { AttributeCreationButton } from "./AttributeCreationButton";
import { ImageCreationPanel } from "../../attributes/image/ImageCreationPanel";
import { TextCreationPanel } from "../../attributes/text/TextCreationPanel";
import { ListCreationPanel } from "../../attributes/list/ListCreationPanel";
import { ListItemCreationPanel } from "../../attributes/list-item/ListItemCreationPanel";
import { ListItemLabelCreationPanel } from "../../attributes/list-item-label/ListItemLabelCreationPanel";
import { ExpressionCreationPanel } from "../../attributes/expression/ExpressionCreationPanel";
import { FigureCreationPanel } from "../../attributes/figure/FigureCreationPanel";
import { CaptionCreationPanel } from "../../attributes/caption/CaptionCreationPanel";
import { TableCreationPanel } from "../../attributes/table/TableCreationPanel";
import { AlternativeTextCreationPanel } from "../../attributes/alternative-text/AlternativeTextCreationPanel";
import { TableCellCreationPanel } from "../../attributes/table-cell/TableCellCreationPanel";
import { ExcludeReadingOrderCreationPanel } from "../../attributes/exclude-reading-order/ExcludeReadingOrderCreationPanel";
import { PaginationArtifactCreationPanel } from "../../attributes/pagination-artifact/PaginationArtifactCreationPanel";
import { TableOfContentsCreationPanel } from "../../attributes/table-of-contents/TableOfContentsCreationPanel";
import { TableOfContentsHeadingCreationPanel } from "../../attributes/table-of-contents-heading/TableOfContentsHeadingCreationPanel";
import { TableOfContentsItemCreationPanel } from "../../attributes/table-of-contents-item/TableOfContentsItemCreationPanel";
import { TableOfContentsPageNumberCreationPanel } from "../../attributes/table-of-contents-page-number/TableOfContentsPageNumberCreationPanel";
import { TableHeaderCellCreationPanel } from "../../attributes/table-header-cell/TableHeaderCellCreationPanel";

type Props = {
  fragment: AttributeCreationSectionFragment;
};

export function AttributeCreationSection({ fragment }: Props) {
  const [attrs, setAttrs] = useState<Record<string, AttributeKind>>({});

  const deleteAttr = async (key: string) => {
    setAttrs((oldAttrs) => {
      const { [key]: _, ...newAttrs } = oldAttrs;
      return newAttrs;
    });
  };

  return (
    <div>
      {Object.entries(attrs).map(([key, kind]) =>
        kind === "AlternativeTextAttribute" ? (
          <AlternativeTextCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "CaptionAttribute" ? (
          <CaptionCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "FigureAttribute" ? (
          <FigureCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "ExcludeReadingOrderAttribute" ? (
          <ExcludeReadingOrderCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "ExpressionAttribute" ? (
          <ExpressionCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "HeaderLevelAttribute" ? (
          <HeadingLevelCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "ImageAttribute" ? (
          <ImageCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "ListAttribute" ? (
          <ListCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "ListItemAttribute" ? (
          <ListItemCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "ListItemLabelAttribute" ? (
          <ListItemLabelCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "PaginationArtifactAttribute" ? (
          <PaginationArtifactCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "TableAttribute" ? (
          <TableCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "TableCellAttribute" ? (
          <TableCellCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "TableHeaderCellAttribute" ? (
          <TableHeaderCellCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "TableOfContentsAttribute" ? (
          <TableOfContentsCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "TableOfContentsHeadingAttribute" ? (
          <TableOfContentsHeadingCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "TableOfContentsItemAttribute" ? (
          <TableOfContentsItemCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "TableOfContentsPageNumberAttribute" ? (
          <TableOfContentsPageNumberCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : kind === "TextAttribute" ? (
          <TextCreationPanel
            key={key}
            fragment={fragment}
            onDelete={() => deleteAttr(key)}
          />
        ) : null,
      )}
      <div className="p-4 flex flex-col">
        <AttributeCreationButton
          onSelect={(kind) =>
            setAttrs((oldUnsavedAttributes) => ({
              ...oldUnsavedAttributes,
              [uuid()]: kind,
            }))
          }
        />
      </div>
    </div>
  );
}
