import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import {
  TableOfContentsHeadingUpdatePanelFragment,
  useDeleteTableOfContentsFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: TableOfContentsHeadingUpdatePanelFragment;
};

export function TableOfContentsHeadingUpdatePanel({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [deleteAttribute] = useDeleteTableOfContentsFromPanelMutation();

  const onSave = async () => {};

  const onDelete = async () => {
    await deleteAttribute({
      variables: { attributeId: fragment.id },
      update(cache) {
        const id = cache.identify(fragment);
        cache.evict({ id });
        cache.gc();
      },
    });
  };

  return (
    <AttributePanel
      title={t("TableOfContentsHeadingUpdatePanel.title")}
      onSave={onSave}
      onDelete={onDelete}
    />
  );
}
