import { cn } from "@acdc2/ui/lib/utils";
import { mdiDrag } from "@mdi/js";
import { Icon } from "@mdi/react";
import { ComponentProps } from "react";

type Props = ComponentProps<"div">;

export function RegionDraggable({ className, ...props }: Props): JSX.Element {
  return (
    <div
      className={cn(
        "hover:bg-black hover:bg-opacity-5 p-1 rounded cursor-grab",
        className,
      )}
      {...props}
    >
      <Icon path={mdiDrag} size="1.25rem" />
    </div>
  );
}
