import { DropdownMenuItem } from "@acdc2/ui/components/dropdown-menu";
import { useTranslation } from "react-i18next";
import { Icon } from "@mdi/react";
import { mdiLock } from "@mdi/js";

export function ViewerPrivacyDropdownMenuItem(): JSX.Element {
  const { t } = useTranslation();
  return (
    <DropdownMenuItem>
      <div className="flex gap-4">
        <Icon path={mdiLock} size="1.25rem" />
        <a
          href={t("ViewerPrivacyDropdownMenuItem.href")}
          target="_blank"
          className="flex items-center"
        >
          {t("ViewerPrivacyDropdownMenuItem.label")}
        </a>
      </div>
    </DropdownMenuItem>
  );
}
