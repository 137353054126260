import { DocumentModifiedByTableCellFragment } from "../client/generated";
import { UserAvatar } from "../users/UserAvatar";
import { DocumentTableCell } from "./DocumentTableCell";

type Props = {
  fragment: DocumentModifiedByTableCellFragment;
};

export function DocumentModifiedByTableCell({ fragment }: Props): JSX.Element {
  return (
    <DocumentTableCell className="flex items-center gap-2">
      <UserAvatar className="h-6 w-6" fragment={fragment.lastModifiedBy} />
      <span>{fragment.lastModifiedBy.username}</span>
    </DocumentTableCell>
  );
}
