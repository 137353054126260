import { AccessLoading } from "@acdc2/ui/components/access-loading";
import { Button } from "@acdc2/ui/components/button";
import { useAuth } from "react-oidc-context";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@acdc2/ui/components/dropdown-menu";
import { mdiChevronDown } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DocumentExportArtifactOptionsFragment,
  useDeleteArtifactMutation,
} from "../client/generated";

type Props = {
  fragment: DocumentExportArtifactOptionsFragment;
};

export function DocumentExportArtifactOptions({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const auth = useAuth();

  const [deleteArtifact] = useDeleteArtifactMutation({
    update(cache) {
      cache.evict({ id: cache.identify(fragment) });
      cache.gc();
    },
  });

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteArtifact({
        variables: {
          artifactId: fragment.id,
        },
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDownload = async () => {
    const url = `${import.meta.env.VITE_ACDC2_BASE_API_URL}${fragment.downloadUrl}`;
    const headers = { Authorization: `Bearer ${auth.user?.access_token}` };

    const response = await fetch(url, { headers });
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
  };

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger>
        <Button variant="outline">
          <span>{t("DocumentExportArtifactOptions.label")}</span>
          <Icon size="1.25rem" path={mdiChevronDown} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          className="justify-between items-center"
          onSelect={handleDelete}
        >
          <span>{t("DocumentExportArtifactOptions.items.delete")}</span>
          {isDeleting && <AccessLoading className="h-4" />}
        </DropdownMenuItem>
        <DropdownMenuItem
          disabled={!fragment.downloadUrl}
          onSelect={handleDownload}
        >
          <span>{t("DocumentExportArtifactOptions.items.download")}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
