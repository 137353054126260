import { useTranslation } from "react-i18next";
import { RegionLabelFragment } from "../client/generated";
import { useOverlayScale } from "../editor/hooks";
import { CSS_IN_TO_PX } from "../editor/utils";
import { getRegionType } from "../types/utils";
import { cn } from "@acdc2/ui/lib/utils";
import { useId } from "react";

type Props = {
  fragment: RegionLabelFragment;
};

export function RegionLabel({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const overlayScale = useOverlayScale();
  const filterId = useId();

  const pageWidth = fragment.page.width * CSS_IN_TO_PX;
  const pageHeight = fragment.page.height * CSS_IN_TO_PX;
  const regionKind = getRegionType(fragment);

  const mostTopLeftPoint = [...fragment.shape.vertices].sort((a, b) => {
    return a.top - b.top || a.left - b.left;
  })[0];

  return (
    <>
      <defs>
        <filter x="0" y="0" width="1" height="1" id={filterId}>
          <feFlood
            className={cn({
              "flood-color-attr-untyped-mid": regionKind === "Untyped",
              "flood-color-attr-hybrid-mid": regionKind === "Hybrid",
              "flood-color-attr-heading-mid": regionKind === "Heading",
              "flood-color-attr-caption-mid": regionKind === "Caption",
              "flood-color-attr-formula-mid": regionKind === "Formula",
              "flood-color-attr-figure-mid": regionKind === "Figure",
              "flood-color-attr-image-mid": regionKind === "Image",
              "flood-color-attr-list-mid":
                regionKind === "List" ||
                regionKind === "ListItem" ||
                regionKind === "Sublist",
              "flood-color-attr-artifact-mid": regionKind === "Artifact",
              "flood-color-attr-table-mid": regionKind === "Table",
              "flood-color-attr-toc-mid": regionKind === "TOC",
              "flood-color-attr-text-mid": regionKind === "Text",
            })}
          />
          <feComposite in="SourceGraphic" operator="xor" />
        </filter>
      </defs>
      <text
        dx={mostTopLeftPoint.left * pageWidth}
        dy={mostTopLeftPoint.top * pageHeight}
        fontSize={14 / overlayScale}
        filter={`url(#${filterId})`}
      >
        {t(`RegionLabel.labels.${regionKind}`)}{" "}
        <>[{fragment.readingOrderIndex + 1}]</>
      </text>
    </>
  );
}
