import { Dispatch } from "react";
import { RegionPreviewFragment } from "../client/generated";
import { EditorAction, EditorState } from "../editor/reducers";
import { RegionThumbnail } from "./RegionThumbnail";
import { cn } from "@acdc2/ui/lib/utils";
import { makeRegionPreviewID } from "./utils";
import { RegionContextMenu2 } from "./RegionContextMenu2";
import { RegionTile } from "./RegionTile";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { RegionDraggable } from "./RegionDraggable";

type Props = {
  dispatch: Dispatch<EditorAction>;
  state: EditorState;
  fragment: RegionPreviewFragment;
};

export function RegionPreview({
  fragment,
  dispatch,
  state,
}: Props): JSX.Element {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: fragment.id, data: fragment });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const isSelected = state.selectedRegionId === fragment.id;

  const onClick = () => {
    dispatch({
      type: "selectRegion",
      regionId: fragment.id,
    });
  };

  return (
    <div ref={setNodeRef} id={makeRegionPreviewID(fragment.id)} style={style}>
      <div
        className={cn(
          "border rounded overflow-hidden bg-gray-50 dark:bg-gray-900",
          {
            "outline outline-brand": isSelected,
            "opacity-50": isDragging,
          },
        )}
      >
        <RegionContextMenu2 fragment={fragment} dispatch={dispatch}>
          <div className="cursor-pointer" onClick={onClick}>
            <RegionThumbnail fragment={fragment} />
          </div>
          <RegionTile fragment={fragment} dispatch={dispatch}>
            <RegionDraggable {...attributes} {...listeners} />
          </RegionTile>
        </RegionContextMenu2>
      </div>
    </div>
  );
}
