import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import { TextFormField } from "./TextFormField";
import {
  TextUpdatePanelFragment,
  useDeleteTextFromPanelMutation,
  useUpdateTextFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: TextUpdatePanelFragment;
};

export function TextUpdatePanel({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const [updateAttribute] = useUpdateTextFromPanelMutation();
  const [deleteAttribute] = useDeleteTextFromPanelMutation();
  const [text, setText] = useState(fragment.text);

  const onSave = async () => {
    await updateAttribute({
      variables: {
        attributeId: fragment.id,
        text,
      },
    });
  };

  const onDelete = async () => {
    await deleteAttribute({
      variables: { attributeId: fragment.id },
      update(cache) {
        const id = cache.identify(fragment);
        cache.evict({ id });
        cache.gc();
      },
    });
  };

  return (
    <AttributePanel
      title={t("TextUpdatePanel.title")}
      unsaved={text !== fragment.text}
      onSave={onSave}
      onDelete={onDelete}
    >
      <TextFormField
        fragment={fragment.region}
        value={text}
        onChange={(event) => setText(event.target.value)}
        onPrediction={setText}
      />
    </AttributePanel>
  );
}
