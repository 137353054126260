import { useState } from "react";
import { v4 as uuid } from "uuid";
import { AttributePanel } from "../AttributePanel";
import { useTranslation } from "react-i18next";
import { TableRowOffsetFormField } from "./TableRowOffsetFormField";
import { TableColumnOffsetFormField } from "./TableColumnOffsetFormField";
import { TableRowListFormField } from "./TableRowListFormField";
import { TableColumnListFormField } from "./TableColumnListFormField";
import {
  TableUpdatePanelFragment,
  useDeleteTableFromPanelMutation,
  useUpdateTableFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: TableUpdatePanelFragment;
};

export function TableUpdatePanel({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const [updateAttribute] = useUpdateTableFromPanelMutation();
  const [deleteAttribute] = useDeleteTableFromPanelMutation();
  const [rowOffset, setRowOffset] = useState(fragment.parentRowOffset);
  const [columnOffset, setColumnOffset] = useState(fragment.parentColumnOffset);

  const [rows, setRows] = useState(
    fragment.rows.map((height) => ({ uuid: uuid(), height })),
  );

  const [columns, setColumns] = useState(
    fragment.columns.map((width) => ({ uuid: uuid(), width })),
  );

  const unsaved =
    rowOffset !== fragment.parentRowOffset ||
    columnOffset !== fragment.parentColumnOffset ||
    rows.length !== fragment.rows.length ||
    columns.length !== fragment.columns.length ||
    rows.some((row, index) => row.height !== fragment.rows[index]) ||
    columns.some((column, index) => column.width !== fragment.columns[index]);

  const onSave = async () => {
    await updateAttribute({
      variables: {
        attributeId: fragment.id,
        rowOffset,
        columnOffset,
        rows: rows.map((row) => row.height),
        columns: columns.map((column) => column.width),
      },
    });
  };

  const onDelete = async () => {
    await deleteAttribute({
      variables: { attributeId: fragment.id },
      update(cache) {
        const id = cache.identify(fragment);
        cache.evict({ id });
        cache.gc();
      },
    });
  };

  return (
    <AttributePanel
      title={t("TableUpdatePanel.title")}
      unsaved={unsaved}
      onSave={onSave}
      onDelete={onDelete}
    >
      <div className="flex flex-col gap-4">
        <TableRowOffsetFormField
          value={rowOffset}
          onChange={(event) => setRowOffset(parseInt(event.target.value))}
        />
        <TableColumnOffsetFormField
          value={columnOffset}
          onChange={(event) => setColumnOffset(parseInt(event.target.value))}
        />
        <TableRowListFormField rows={rows} setRows={setRows} />
        <TableColumnListFormField columns={columns} setColumns={setColumns} />
      </div>
    </AttributePanel>
  );
}
