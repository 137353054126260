import { useTranslation } from "react-i18next";
import { useState } from "react";
import { AttributePanel } from "../AttributePanel";
import { CaptionFormField } from "./CaptionFormField";
import {
  CaptionUpdatePanelFragment,
  useDeleteCaptionFromPanelMutation,
  useUpdateCaptionFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: CaptionUpdatePanelFragment;
};

export function CaptionUpdatePanel({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const [updateAttribute] = useUpdateCaptionFromPanelMutation();
  const [deleteAttribute] = useDeleteCaptionFromPanelMutation();
  const [linkedRegionId, setLinkedRegionId] = useState(fragment.linkedRegionId);

  const onSave = async () => {
    await updateAttribute({
      variables: {
        attributeId: fragment.id,
        linkedRegionId,
      },
    });
  };

  const onDelete = async () => {
    await deleteAttribute({
      variables: { attributeId: fragment.id },
      update(cache) {
        const id = cache.identify(fragment);
        cache.evict({ id });
        cache.gc();
      },
    });
  };

  return (
    <AttributePanel
      title={t("CaptionUpdatePanel.title")}
      unsaved={linkedRegionId !== fragment.linkedRegionId}
      onSave={onSave}
      onDelete={onDelete}
    >
      <CaptionFormField
        fragment={fragment.region}
        value={linkedRegionId ?? undefined}
        onValueChange={setLinkedRegionId}
      />
    </AttributePanel>
  );
}
