import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import { AlternativeTextFormField } from "./AlternativeTextFormField";
import {
  AlternativeTextUpdatePanelFragment,
  useDeleteAlternativeTextFromPanelMutation,
  useUpdateAlternativeTextFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: AlternativeTextUpdatePanelFragment;
};

export function AlternativeTextUpdatePanel({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const [updateAttribute] = useUpdateAlternativeTextFromPanelMutation();
  const [deleteAttribute] = useDeleteAlternativeTextFromPanelMutation();
  const [alternativeText, setAlternativeText] = useState(
    fragment.alternativeText,
  );

  const onSave = async () => {
    await updateAttribute({
      variables: {
        attributeId: fragment.id,
        alternativeText,
      },
    });
  };

  const onDelete = async () => {
    await deleteAttribute({
      variables: { attributeId: fragment.id },
      update(cache) {
        const id = cache.identify(fragment);
        cache.evict({ id });
        cache.gc();
      },
    });
  };

  return (
    <AttributePanel
      title={t("AlternativeTextUpdatePanel.title")}
      unsaved={alternativeText !== fragment.alternativeText}
      onSave={onSave}
      onDelete={onDelete}
    >
      <AlternativeTextFormField
        fragment={fragment.region}
        value={alternativeText}
        onChange={(event) => setAlternativeText(event.target.value)}
        onPrediction={setAlternativeText}
      />
    </AttributePanel>
  );
}
