import { Badge } from "@acdc2/ui/components/badge";
import { AccessLoading } from "@acdc2/ui/components/access-loading";
import { PageThumbnailFragment } from "../client/generated";
import { routingPaths } from "../navigation/constants";
import { useProtectedImageSource } from "../client/images";
import { useNavigateTransition } from "../navigation/useNavigateTransition";
import { cn } from "@acdc2/ui/lib/utils";
import { makePageThumbnailID } from "../regions/utils";

type Props = {
  fragment: PageThumbnailFragment;
  highlight?: boolean;
};

export function PageThumbnail({ fragment, highlight }: Props): JSX.Element {
  const [isPending, navigate] = useNavigateTransition();
  const src = useProtectedImageSource(fragment.pngUrl);

  return (
    <div
      id={makePageThumbnailID(fragment.id)}
      style={{ aspectRatio: `${fragment.width}/${fragment.height}` }}
      className={cn(
        "relative border rounded overflow-hidden w-full cursor-pointer",
        { "outline outline-brand": highlight },
      )}
      onClick={() =>
        navigate(routingPaths.documentPage(fragment.document.id, fragment.id))
      }
    >
      {src ? (
        <img className="bg-white w-full h-full" src={src} />
      ) : (
        <div className="w-full h-full bg-slate-200 dark:bg-slate-800 animate-pulse"></div>
      )}

      <div className="p-4 absolute bottom-0 right-0">
        <Badge>{fragment.index}</Badge>
      </div>

      {isPending && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <AccessLoading />
        </div>
      )}
    </div>
  );
}
