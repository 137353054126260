import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import { HeadingLevelFormField } from "./HeadingLevelFormField";
import {
  HeadingLevelUpdatePanelFragment,
  useDeleteHeadingLevelFromPanelMutation,
  useUpdateHeadingLevelFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: HeadingLevelUpdatePanelFragment;
};

export function HeadingLevelUpdatePanel({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const [updateAttribute] = useUpdateHeadingLevelFromPanelMutation();
  const [deleteAttribute] = useDeleteHeadingLevelFromPanelMutation();
  const [level, setLevel] = useState(fragment.level);

  const onSave = async () => {
    await updateAttribute({
      variables: {
        attributeId: fragment.id,
        level,
      },
    });
  };

  const onDelete = async () => {
    await deleteAttribute({
      variables: { attributeId: fragment.id },
      update(cache) {
        const id = cache.identify(fragment);
        cache.evict({ id });
        cache.gc();
      },
    });
  };

  return (
    <AttributePanel
      title={t("HeadingLevelUpdatePanel.title")}
      unsaved={level !== fragment.level}
      onSave={onSave}
      onDelete={onDelete}
    >
      <HeadingLevelFormField
        value={level.toString()}
        onValueChange={(newLevel) => setLevel(parseInt(newLevel))}
      />
    </AttributePanel>
  );
}
