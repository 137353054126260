import { useTranslation } from "react-i18next";
import { ComponentProps } from "react";
import { RegionSelect } from "../../regions/RegionSelect";
import { CaptionFormFieldFragment } from "../../client/generated";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@acdc2/ui/components/form";

type Props = Omit<
  ComponentProps<typeof RegionSelect>,
  "qualifyingAttributes"
> & {
  fragment: CaptionFormFieldFragment;
};

export function CaptionFormField({ fragment, ...props }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <FormField>
      <FormItem>
        <FormLabel>{t("CaptionFormField.label")}</FormLabel>
        <FormControl>
          <RegionSelect
            {...props}
            fragment={fragment}
            qualifyingAttributes={["ImageAttribute", "FigureAttribute"]}
          />
        </FormControl>
      </FormItem>
    </FormField>
  );
}
