import { assertTypeName } from "../../client/guards";
import { TextRegionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: TextRegionPreviewFragment;
  textAttributeId: string;
};

export function TextRegionPreview({
  fragment,
  textAttributeId,
}: Props): JSX.Element {
  const textAttribute = fragment.attributes.find(
    (a) => a.id === textAttributeId,
  );
  assertTypeName(textAttribute?.__typename === "TextAttribute");

  return <>{textAttribute.text}</>;
}
