import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import { TextFormField } from "./TextFormField";
import {
  TextCreationPanelFragment,
  useCreateTextFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: TextCreationPanelFragment;
  onDelete: () => Promise<void>;
};

export function TextCreationPanel({ fragment, onDelete }: Props): JSX.Element {
  const { t } = useTranslation();
  const [createAttribute] = useCreateTextFromPanelMutation();
  const [text, setText] = useState<string>();

  const onSave = async () => {
    if (!text) return;
    await createAttribute({
      variables: {
        regionId: fragment.id,
        text,
      },
    });
    await onDelete();
  };

  return (
    <AttributePanel
      title={t("TextCreationPanel.title")}
      unsaved
      onSave={onSave}
      onDelete={onDelete}
    >
      <TextFormField
        fragment={fragment}
        value={text}
        onChange={(event) => setText(event.target.value)}
        onPrediction={setText}
      />
    </AttributePanel>
  );
}
