import { Button } from "@acdc2/ui/components/button";
import {
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from "@acdc2/ui/components/command";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@acdc2/ui/components/popover";
import { mdiCheck } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { languages, TitleLanguage } from "./constants";

type Props = {
  defaultValue: TitleLanguage | "";
  onValueChange: (value: string) => void;
  disabled?: boolean;
};

export function MetadataTitleLanguageCombobox({
  defaultValue,
  onValueChange,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<TitleLanguage | "">(
    defaultValue,
  );

  const handleSelect = (currentValue: TitleLanguage) => {
    const newValue = currentValue === selectedValue ? "" : currentValue;
    setSelectedValue(newValue);
    onValueChange(newValue);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="flex justify-between"
          disabled={disabled}
        >
          {selectedValue ? (
            <div>
              {t(`MetadataTitleLanguageCombobox.labels.${selectedValue}`)}
            </div>
          ) : (
            <div>{t("MetadataTitleLanguageCombobox.label")}</div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 mx-2">
        <Command>
          <CommandInput
            placeholder={t("MetadataTitleLanguageCombobox.input")}
          />
          <CommandList>
            <CommandEmpty>
              {t("MetadataTitleLanguageCombobox.results.placeholder")}
            </CommandEmpty>
            <CommandGroup>
              {languages.map((language) => (
                <CommandItem
                  key={language}
                  value={language}
                  onSelect={() => {
                    handleSelect(language);
                  }}
                >
                  {selectedValue === language ? (
                    <Icon path={mdiCheck} size="1.25rem" className="mr-2" />
                  ) : null}
                  {t(`MetadataTitleLanguageCombobox.labels.${language}`)}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
