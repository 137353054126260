import { Button } from "@acdc2/ui/components/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@acdc2/ui/components/dropdown-menu";
import { mdiTuneVariant } from "@mdi/js";
import { Icon } from "@mdi/react";
import { Table } from "@tanstack/react-table";
import { DocumentRowFragment } from "../client/generated";
import { useTranslation } from "react-i18next";

type Props = {
  table: Table<DocumentRowFragment>;
};

export function DocumentTableViewOptions({ table }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="gap-2">
          <Icon path={mdiTuneVariant} size="1rem" />
          {t("DocumentTableViewOptions.label")}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>
          {t("DocumentTableViewOptions.prompt")}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {table
          .getAllColumns()
          .filter((column) => !!column.accessorFn && column.getCanHide())
          .map((column) => (
            <DropdownMenuCheckboxItem
              key={column.id}
              checked={column.getIsVisible()}
              onCheckedChange={(checked) => column.toggleVisibility(checked)}
            >
              {column.id === "name"
                ? t("DocumentNameTableHeader.label")
                : column.id === "uploadedBy"
                  ? t("DocumentUploadedByTableHeader.label")
                  : column.id === "uploadedAt"
                    ? t("DocumentUploadedAtTableHeader.label")
                    : column.id === "modifiedBy"
                      ? t("DocumentModifiedByTableHeader.label")
                      : column.id === "modifiedAt"
                        ? t("DocumentModifiedAtTableHeader.label")
                        : column.id === "extension"
                          ? t("DocumentExtensionTableHeader.label")
                          : null}
            </DropdownMenuCheckboxItem>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
