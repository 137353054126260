import { useEffect, useState } from "react";

type Props = {
  children: React.ReactNode;
  isSelected: boolean;
  onDrag: (movementX: number, movementY: number) => void;
  onDone: () => void;
};

export function RegionDragHandler({
  children,
  isSelected,
  onDrag,
  onDone,
}: Props): JSX.Element {
  const [isDragged, setIsDragged] = useState(false);

  const onMouseDown = (event: React.MouseEvent) => {
    if (!isSelected) return;
    event.stopPropagation();
    setIsDragged(true);
  };

  useEffect(() => {
    if (!isDragged) return;

    const onMouseMove = (event: MouseEvent) => {
      if (event.buttons !== 1) {
        setIsDragged(false);
        return;
      }

      onDrag(event.movementX, event.movementY);
    };

    const onMouseUp = () => {
      setIsDragged(false);
      onDone();
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [isDragged, onDrag, onDone]);

  return (
    <g
      onMouseDown={onMouseDown}
      className={isDragged ? "cursor-grabbing" : "cursor-grab"}
    >
      {children}
    </g>
  );
}
