import { Input } from "@acdc2/ui/components/input";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@acdc2/ui/components/form";

type Props = React.ComponentProps<typeof Input>;

export function TableCellColumnSpanFormField(props: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <FormField>
      <FormItem>
        <FormLabel>{t("TableCellColumnSpanFormField.label")}</FormLabel>
        <FormControl>
          <Input
            placeholder={t("TableCellColumnSpanFormField.placeholder")}
            type="number"
            inputMode="numeric"
            min={1}
            required
            {...props}
          />
        </FormControl>
      </FormItem>
    </FormField>
  );
}
