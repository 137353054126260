import { Dispatch, useState } from "react";
import { EditorAction } from "../../editor/reducers";
import { TextFormField } from "../../attributes/text/TextFormField";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { assertTypeName } from "../../client/guards";
import { CaptionFormField } from "../../attributes/caption/CaptionFormField";
import {
  useCaptionRegionEditorSuspenseQuery,
  useUpdateCaptionRegionMutation,
} from "../../client/generated";

type Props = {
  captionAttributeId: string;
  textAttributeId: string;
  dispatch: Dispatch<EditorAction>;
};

export function CaptionRegionEditor({
  captionAttributeId,
  textAttributeId,
  dispatch,
}: Props): JSX.Element {
  const { data } = useCaptionRegionEditorSuspenseQuery({
    variables: {
      captionAttributeId,
      textAttributeId,
    },
  });

  const { captionAttr, textAttr } = data;
  assertTypeName(captionAttr?.__typename === "CaptionAttribute");
  assertTypeName(textAttr?.__typename === "TextAttribute");

  const [updateRegion] = useUpdateCaptionRegionMutation();
  const [linkedRegionId, setLinkedRegionId] = useState(
    captionAttr.linkedRegionId,
  );
  const [text, setText] = useState(textAttr.text);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateRegion({
      variables: {
        captionAttributeId,
        textAttributeId,
        linkedRegionId,
        text,
      },
    });
  };

  return (
    <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch}>
      <CaptionFormField
        fragment={captionAttr.region}
        value={linkedRegionId ?? undefined}
        onValueChange={setLinkedRegionId}
      />
      <TextFormField
        fragment={textAttr.region}
        value={text}
        onChange={(event) => setText(event.target.value)}
        onPrediction={setText}
      />
    </RegionUpdateForm>
  );
}
