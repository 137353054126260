import { ScrollArea, ScrollBar } from "@acdc2/ui/components/scroll-area";
import { KaTeX } from "./KaTeX";

type Props = {
  latexExpression: string;
};

export function KaTeXPreview({ latexExpression }: Props): JSX.Element {
  return (
    <ScrollArea className="rounded-md border bg-secondary">
      <div className="p-4 text-lg">
        <KaTeX expression={latexExpression} />
      </div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}
