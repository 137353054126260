import { useTranslation } from "react-i18next";
import { NavbarTabContent } from "../shell/NavbarTabContent";
import { Dispatch } from "react";
import { ScrollArea } from "@acdc2/ui/components/scroll-area";
import { NavbarTabCloseButton } from "../shell/NavbarTabCloseButton";
import { getRegionType, matchRegionType } from "./utils";
import { RegionUpdateTabFragment } from "../client/generated";
import { EditorAction, makeIdentifier, TabPrefix } from "../editor/reducers";
import { ImageRegionEditor } from "./image/ImageRegionEditor";
import { HybridRegionEditor } from "./hybrid/HybridRegionEditor";
import { HeadingRegionEditor } from "./heading/HeadingRegionEditor";
import { TextRegionEditor } from "./text/TextRegionEditor";
import { CaptionRegionEditor } from "./caption/CaptionRegionEditor";
import { FormulaRegionEditor } from "./formula/FormulaRegionEditor";
import { FigureRegionEditor } from "./figure/FigureRegionEditor";
import { ListRegionEditor } from "./list/ListRegionEditor";
import { ArtifactRegionEditor } from "./artifact/ArtifactRegionEditor";
import { TableRegionEditor } from "./table/TableRegionEditor";
import { TOCRegionEditor } from "./toc/TOCRegionEditor";
import { ListItemRegionEditor } from "./list-item/ListItemRegionEditor";
import { SublistRegionEditor } from "./sublist/SublistRegionEditor";
import { UntypedRegionEditor } from "./untyped/UntypedRegionEditor";

type Props = {
  fragment: RegionUpdateTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export function RegionUpdateTab({ fragment, dispatch }: Props): JSX.Element {
  const { t } = useTranslation();
  const regionType = getRegionType(fragment);

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.RegionUpdate, fragment.id)}
      title={t(`RegionUpdateTab.title.${regionType}`)}
      right={<NavbarTabCloseButton dispatch={dispatch} />}
    >
      <ScrollArea className="flex-1">
        <Body fragment={fragment} dispatch={dispatch} />
      </ScrollArea>
    </NavbarTabContent>
  );
}

function Body({ fragment, dispatch }: Props) {
  const [regionType, params] = matchRegionType(fragment);

  switch (regionType) {
    case "Artifact":
      return (
        <ArtifactRegionEditor
          paginationArtifactAttributeId={params["paginationId"]}
          dispatch={dispatch}
        />
      );
    case "Caption":
      return (
        <CaptionRegionEditor
          captionAttributeId={params["captionId"]}
          textAttributeId={params["textId"]}
          dispatch={dispatch}
        />
      );
    case "Figure":
      return (
        <FigureRegionEditor
          figureAttributeId={params["figureId"]}
          dispatch={dispatch}
        />
      );
    case "Formula":
      return (
        <FormulaRegionEditor
          expressionAttributeId={params["expressionId"]}
          dispatch={dispatch}
        />
      );
    case "Heading":
      return (
        <HeadingRegionEditor
          headerLevelAttributeId={params["headerLevelId"]}
          textAttributeId={params["textId"]}
          dispatch={dispatch}
        />
      );
    case "Image":
      return (
        <ImageRegionEditor
          alternativeTextAttributeId={params["alternativeTextId"]}
          imageAttributeId={params["imageId"]}
          dispatch={dispatch}
        />
      );
    case "List":
      return (
        <ListRegionEditor
          listAttributeId={params["listId"]}
          dispatch={dispatch}
        />
      );
    case "ListItem":
      return (
        <ListItemRegionEditor
          listItemAttributeId={params["listItemId"]}
          textAttributeId={params["textId"]}
          dispatch={dispatch}
        />
      );
    case "Sublist":
      return (
        <SublistRegionEditor
          listAttributeId={params["listId"]}
          listItemAttributeId={params["listItemId"]}
          dispatch={dispatch}
        />
      );
    case "Table":
      return (
        <TableRegionEditor
          tableAttributeId={params["tableId"]}
          dispatch={dispatch}
        />
      );
    case "TOC":
      return (
        <TOCRegionEditor tocAttributeId={params["tocId"]} dispatch={dispatch} />
      );
    case "Text":
      return (
        <TextRegionEditor
          textAttributeId={params["textId"]}
          dispatch={dispatch}
        />
      );
    case "Untyped":
      return <UntypedRegionEditor regionId={fragment.id} dispatch={dispatch} />;
    default:
      return <HybridRegionEditor regionId={fragment.id} />;
  }
}
