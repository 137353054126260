import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import { useState } from "react";
import { PaginationArtifactFormField } from "./PaginationArtifactFormField";
import {
  PaginationArtifactCreationPanelFragment,
  PaginationArtifactType,
  useCreatePaginationArtifactFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: PaginationArtifactCreationPanelFragment;
  onDelete: () => Promise<void>;
};

export function PaginationArtifactCreationPanel({
  fragment,
  onDelete,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createAttribute] = useCreatePaginationArtifactFromPanelMutation();
  const [type, setType] = useState<PaginationArtifactType>();

  const onSave = async () => {
    if (!type) return;
    await createAttribute({
      variables: {
        regionId: fragment.id,
        type,
      },
    });
    await onDelete();
  };

  return (
    <AttributePanel
      title={t("PaginationArtifactCreationPanel.title")}
      unsaved
      onSave={onSave}
      onDelete={onDelete}
    >
      <PaginationArtifactFormField
        value={type}
        onValueChange={(newArtifactType: PaginationArtifactType) =>
          setType(newArtifactType)
        }
      />
    </AttributePanel>
  );
}
