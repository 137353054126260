import * as Tabs from "@radix-ui/react-tabs";
import { ComponentProps, Suspense } from "react";
import { SidebarLoading } from "./SidebarLoading";

type Props = ComponentProps<typeof Tabs.Content> & {
  title: string;
  right?: React.ReactNode;
  children?: React.ReactNode;
};

export function NavbarTabContent({
  title,
  right,
  children,
  ...tabsContentProps
}: Props): JSX.Element {
  return (
    <Tabs.Content {...tabsContentProps} asChild>
      <div className="h-full flex flex-col">
        <div className="h-14 flex-shrink-0 border-b px-4 py-2 flex flex-row gap-4 items-center justify-between">
          <div className="text-lg">{title}</div>
          <div>{right}</div>
        </div>
        <Suspense fallback={<SidebarLoading />}>{children}</Suspense>
      </div>
    </Tabs.Content>
  );
}
