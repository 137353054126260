import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import { TableOfContentsItemFormField } from "./TableOfContentsItemFormField";
import { useState } from "react";
import {
  TableOfContentsItemCreationPanelFragment,
  useCreateTableOfContentsItemFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: TableOfContentsItemCreationPanelFragment;
  onDelete: () => Promise<void>;
};

export function TableOfContentsItemCreationPanel({
  fragment,
  onDelete,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createAttribute] = useCreateTableOfContentsItemFromPanelMutation();
  const [linkedRegionId, setLinkedRegionId] = useState<string>();

  const onSave = async () => {
    await createAttribute({
      variables: {
        regionId: fragment.id,
        linkedRegionId,
      },
    });
    await onDelete();
  };

  return (
    <AttributePanel
      title={t("TableOfContentsItemCreationPanel.title")}
      unsaved
      onSave={onSave}
      onDelete={onDelete}
    >
      <TableOfContentsItemFormField
        fragment={fragment}
        value={linkedRegionId}
        onValueChange={setLinkedRegionId}
      />
    </AttributePanel>
  );
}
