import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ExpressionFormField } from "./ExpressionFormField";
import { AttributePanel } from "../AttributePanel";
import { ExpressionReferenceFormField } from "./ExpressionReferenceFormField";
import { ExpressionPreviewFormField } from "./ExpressionPreviewFormField";
import {
  ExpressionCreationPanelFragment,
  useCreateExpressionFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: ExpressionCreationPanelFragment;
  onDelete: () => Promise<void>;
};

export function ExpressionCreationPanel({
  fragment,
  onDelete,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createAttribute] = useCreateExpressionFromPanelMutation();
  const [latex, setLatex] = useState<string>();

  const onSave = async () => {
    if (!latex) return;
    await createAttribute({
      variables: {
        regionId: fragment.id,
        expressionLatex: latex,
      },
    });
    await onDelete();
  };

  return (
    <AttributePanel
      title={t("ExpressionCreationPanel.title")}
      unsaved
      onSave={onSave}
      onDelete={onDelete}
    >
      <div className="flex flex-col gap-4">
        <ExpressionReferenceFormField fragment={fragment} />
        <ExpressionPreviewFormField latex={latex ?? ""} />
        <ExpressionFormField
          fragment={fragment}
          value={latex}
          onChange={(event) => setLatex(event.target.value)}
          onPrediction={setLatex}
        />
      </div>
    </AttributePanel>
  );
}
