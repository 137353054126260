import { Icon } from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { Button } from "@acdc2/ui/components/button";
import { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { EditorAction, TabIdentifier } from "../editor/reducers";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@acdc2/ui/components/tooltip";

type Props = {
  dispatch: Dispatch<EditorAction>;
  parentTab?: TabIdentifier;
};

export function NavbarTabCloseButton({
  dispatch,
  parentTab = TabIdentifier.Regions,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const onClick = () => {
    dispatch({
      type: "switchTab",
      tabIdentifier: parentTab,
    });
  };

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button variant="outline" size="icon" onClick={onClick}>
            <Icon path={mdiClose} size="1.25rem" />
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">
          {t("NavbarTabCloseButton.tooltip")}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
