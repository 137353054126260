import { ScrollArea } from "@acdc2/ui/components/scroll-area";
import { NavbarTabCloseButton } from "../../shell/NavbarTabCloseButton";
import { NavbarTabContent } from "../../shell/NavbarTabContent";
import { useTranslation } from "react-i18next";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { Dispatch, useState } from "react";
import { assertTypeName } from "../../client/guards";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { HeadingLevelFormField } from "../../attributes/heading-level/HeadingLevelFormField";
import { TextFormField } from "../../attributes/text/TextFormField";
import {
  HeadingRegionCreationTabFragment,
  useCreateHeadingRegionMutation,
  useHeadingRegionCreationTabSuspenseQuery,
} from "../../client/generated";

type Props = {
  fragment: HeadingRegionCreationTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export function HeadingRegionCreationTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.HeadingRegionCreation, fragment.id)}
      title={t("HeadingRegionCreationTab.title")}
      right={<NavbarTabCloseButton dispatch={dispatch} />}
    >
      <ScrollArea className="flex-1">
        <Body fragment={fragment} dispatch={dispatch} />
      </ScrollArea>
    </NavbarTabContent>
  );
}

function Body({ fragment, dispatch }: Props) {
  const headerLevelAttributeIds = fragment.attributes
    .filter((attr) => attr.__typename === "HeaderLevelAttribute")
    .map((attr) => attr.id);

  const textAttributeIds = fragment.attributes
    .filter((attr) => attr.__typename === "TextAttribute")
    .map((attr) => attr.id);

  const { data } = useHeadingRegionCreationTabSuspenseQuery({
    variables: {
      regionId: fragment.id,
      headerLevelAttributeIds,
      textAttributeIds,
    },
  });

  assertTypeName(data.region?.__typename === "Region");

  const [createRegion] = useCreateHeadingRegionMutation();

  const [level, setLevel] = useState(() => {
    const attr = data.headerLevelAttributes[0];
    if (!attr) return undefined;
    assertTypeName(attr.__typename === "HeaderLevelAttribute");
    return attr.level;
  });

  const [text, setText] = useState(() => {
    const attr = data.textAttributes[0];
    if (!attr) return undefined;
    assertTypeName(attr.__typename === "TextAttribute");
    return attr.text;
  });

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (level === undefined || text === undefined) return;

    await createRegion({
      variables: {
        regionId: fragment.id,
        level,
        text,
        excessAttributeIds: fragment.attributes.map((attr) => attr.id),
      },
      update: (cache) => {
        for (const attr of fragment.attributes) {
          const id = cache.identify(attr);
          cache.evict({ id });
        }
      },
    });
  };

  return (
    <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch}>
      <HeadingLevelFormField
        value={level === undefined ? level : level.toString()}
        onValueChange={(newLevel) => setLevel(parseInt(newLevel))}
        required
      />
      <TextFormField
        fragment={data.region}
        value={text}
        onChange={(event) => setText(event.target.value)}
        onPrediction={setText}
      />
    </RegionUpdateForm>
  );
}
