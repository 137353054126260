import { PaginationArtifactTypeSelect } from "./PaginationArtifactTypeSelect";
import { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@acdc2/ui/components/form";

type Props = ComponentProps<typeof PaginationArtifactTypeSelect>;

export function PaginationArtifactFormField(props: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <FormField>
      <FormItem>
        <FormLabel>{t("PaginationArtifactFormField.label")}</FormLabel>
        <FormControl>
          <PaginationArtifactTypeSelect {...props} />
        </FormControl>
      </FormItem>
    </FormField>
  );
}
