import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@acdc2/ui/components/accordion";
import { Button } from "@acdc2/ui/components/button";
import { useTranslation } from "react-i18next";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@acdc2/ui/components/popover";
import { Calendar } from "@acdc2/ui/components/calendar";
import dayjs from "dayjs";
import {
  MetadataDateAccordionItemFragment,
  useSetDocumentDateMutation,
} from "../client/generated";
import { useState } from "react";
import { mdiDelete } from "@mdi/js";
import { Icon } from "@mdi/react";
import { LANGUAGE2LOCALE } from "../i18n/utils";

type Props = {
  fragment: MetadataDateAccordionItemFragment;
};

export function MetadataDateAccordionItem({ fragment }: Props): JSX.Element {
  const { t, i18n } = useTranslation();
  const [date, setDate] = useState(
    fragment.metadata.date ? new Date(fragment.metadata.date) : undefined,
  );

  const [setDocumentDate] = useSetDocumentDateMutation();

  const onDayClick = async (date: Date | undefined) => {
    setDate(date);

    await setDocumentDate({
      variables: {
        documentId: fragment.id,
        date: date?.toISOString() ?? null,
      },
    });
  };

  return (
    <AccordionItem value="date">
      <AccordionTrigger className="px-4">
        {t("MetadataDateAccordionItem.label")}
      </AccordionTrigger>
      <AccordionContent className="px-4 pt-1">
        <div className="flex flex-row gap-2 justify-between items-center">
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="outline" className="flex-grow">
                {date ? (
                  <span>
                    {dayjs(date).locale(i18n.language).format("DD. MMMM YYYY")}
                  </span>
                ) : (
                  <span>{t("MetadataDateAccordionItem.placeholder")}</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0 mx-2">
              <Calendar
                mode="single"
                selected={date}
                onDayClick={onDayClick}
                initialFocus
                locale={
                  LANGUAGE2LOCALE[i18n.language as keyof typeof LANGUAGE2LOCALE]
                }
              />
            </PopoverContent>
          </Popover>
          <Button
            variant="outline"
            size="icon"
            onClick={() => setDate(undefined)}
          >
            <Icon path={mdiDelete} size="1.25rem" />
          </Button>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
}
