import { Dispatch, useState } from "react";
import { EditorAction } from "../../editor/reducers";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { assertTypeName } from "../../client/guards";
import { ExpressionReferenceFormField } from "../../attributes/expression/ExpressionReferenceFormField";
import { ExpressionPreviewFormField } from "../../attributes/expression/ExpressionPreviewFormField";
import { ExpressionFormField } from "../../attributes/expression/ExpressionFormField";
import {
  useFormulaRegionEditorSuspenseQuery,
  useUpdateFormulaRegionMutation,
} from "../../client/generated";

type Props = {
  expressionAttributeId: string;
  dispatch: Dispatch<EditorAction>;
};

export function FormulaRegionEditor({
  expressionAttributeId,
  dispatch,
}: Props): JSX.Element {
  const { data } = useFormulaRegionEditorSuspenseQuery({
    variables: {
      expressionAttributeId,
    },
  });

  const { expressionAttr } = data;
  assertTypeName(expressionAttr?.__typename === "ExpressionAttribute");

  const [updateRegion] = useUpdateFormulaRegionMutation();
  const [expressionLatex, setExpressionLatex] = useState(
    expressionAttr.expressionLatex,
  );

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateRegion({
      variables: {
        expressionAttributeId,
        expressionLatex,
      },
    });
  };

  return (
    <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch}>
      <ExpressionReferenceFormField fragment={expressionAttr.region} />
      <ExpressionPreviewFormField latex={expressionLatex} />
      <ExpressionFormField
        fragment={expressionAttr.region}
        value={expressionLatex}
        onChange={(event) => setExpressionLatex(event.target.value)}
        onPrediction={setExpressionLatex}
      />
    </RegionUpdateForm>
  );
}
