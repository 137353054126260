import { useTranslation } from "react-i18next";
import { assertTypeName } from "../../client/guards";
import { ListItemRegionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: ListItemRegionPreviewFragment;
  listItemAttributeId: string;
  textAttributeId: string;
};

export function ListItemRegionPreview({
  fragment,
  listItemAttributeId,
  textAttributeId,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const listItemAttribute = fragment.attributes.find(
    (a) => a.id === listItemAttributeId,
  );
  assertTypeName(listItemAttribute?.__typename === "ListItemAttribute");

  const textAttribute = fragment.attributes.find(
    (a) => a.id === textAttributeId,
  );
  assertTypeName(textAttribute?.__typename === "TextAttribute");

  return (
    <>
      {t("ListItemRegionPreview.preview", {
        position: listItemAttribute.ordinal,
        text: textAttribute.text,
      })}
    </>
  );
}
