import { ScrollArea } from "@acdc2/ui/components/scroll-area";
import { NavbarTabCloseButton } from "../../shell/NavbarTabCloseButton";
import { NavbarTabContent } from "../../shell/NavbarTabContent";
import { useTranslation } from "react-i18next";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { Dispatch, useState } from "react";
import { assertTypeName } from "../../client/guards";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { ListItemFormField } from "../../attributes/list-item/ListItemFormField";
import {
  ListItemRegionCreationTabFragment,
  useCreateListItemRegionMutation,
  useListItemRegionCreationTabSuspenseQuery,
} from "../../client/generated";
import { TextFormField } from "../../attributes/text/TextFormField";

type Props = {
  fragment: ListItemRegionCreationTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export function ListItemRegionCreationTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.ListItemRegionCreation, fragment.id)}
      title={t("ListItemRegionCreationTab.title")}
      right={<NavbarTabCloseButton dispatch={dispatch} />}
    >
      <ScrollArea className="flex-1">
        <Body fragment={fragment} dispatch={dispatch} />
      </ScrollArea>
    </NavbarTabContent>
  );
}

function Body({ fragment, dispatch }: Props) {
  const listItemAttributeIds = fragment.attributes
    .filter((attr) => attr.__typename === "ListItemAttribute")
    .map((attr) => attr.id);

  const textAttributeIds = fragment.attributes
    .filter((attr) => attr.__typename === "TextAttribute")
    .map((attr) => attr.id);

  const { data } = useListItemRegionCreationTabSuspenseQuery({
    variables: {
      regionId: fragment.id,
      listItemAttributeIds,
      textAttributeIds,
    },
  });

  assertTypeName(data.region?.__typename === "Region");

  const [createRegion] = useCreateListItemRegionMutation();

  const [ordinal, setOrdinal] = useState(() => {
    const attr = data.listItemAttributes[0];
    if (!attr) return undefined;
    assertTypeName(attr.__typename === "ListItemAttribute");
    return attr.ordinal;
  });

  const [text, setText] = useState(() => {
    const attr = data.textAttributes[0];
    if (!attr) return undefined;
    assertTypeName(attr.__typename === "TextAttribute");
    return attr.text;
  });

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (ordinal === undefined || text === undefined) return;

    await createRegion({
      variables: {
        regionId: fragment.id,
        ordinal,
        text,
        excessAttributeIds: fragment.attributes.map((attr) => attr.id),
      },
      update: (cache) => {
        for (const attr of fragment.attributes) {
          const id = cache.identify(attr);
          cache.evict({ id });
          cache.gc();
        }
      },
    });
  };

  return (
    <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch}>
      <ListItemFormField
        value={ordinal}
        onChange={(event) => setOrdinal(parseInt(event.target.value))}
      />
      <TextFormField
        fragment={data.region}
        value={text}
        onChange={(event) => setText(event.target.value)}
        onPrediction={setText}
      />
    </RegionUpdateForm>
  );
}
