import clippy, { Agent } from "clippyts";
import { useEffect, useRef } from "react";

const animations: ((agent: Agent) => void)[] = [
  (agent) => {
    agent.play("GetAttention");
    agent.speak("Wo alt Text??", false);
  },
  (agent) => {
    agent.speak("Ich glaube du hast da eine Tabelle vergessen", false);
    agent.play("GestureRight");
  },
  (agent) => {
    agent.speak("Das sag ich Thorsten", false);
    agent.play("SendMail");
  },
  (agent) => {
    agent.speak("Lesereihenfolge be like...", false);
    agent.play("Print");
  },
  (agent) => {
    agent.speak("Klick mal X oben rechts", false);
    agent.play("GestureUp");
  },
  (agent) => {
    agent.speak("Ich lösch mal paar Seiten", false);
    agent.play("Processing");
  },
  (agent) => {
    agent.speak("Das sag ich PAC", false);
    agent.play("GoodBye");
  },
  (agent) => {
    agent.speak("Das ist aber nicht accessible", false);
    agent.play("CheckingSomething");
  },
  (agent) => {
    agent.speak("Hiiighwayy to helll", false);
    agent.play("Hearing_1");
  },
  (agent) => {
    agent.speak("Das merk ich mir", false);
    agent.play("Writing");
  },
  (agent) => {
    agent.speak("Tag tree be like", false);
    agent.play("GetArtsy");
  },
  (agent) => {
    agent.speak("Da sind noch mehr Seiten", false);
    agent.play("LookDown");
  },
  (agent) => {
    agent.speak("Wo LaTeX import?", false);
    agent.play("Searching");
  },
  (agent) => {
    agent.speak("Huiiii", false);
    agent.play("EmptyTrash");
  },
  (agent) => {
    agent.play("Greeting");
    agent.speak("PAC sagt nein", false);
  },
  (agent) => {
    agent.speak("success = true", false);
    agent.play("SendMail");
  },
  (agent) => {
    agent.animate();
  },
];

export function useClippy() {
  const effectFired = useRef(false);

  useEffect(() => {
    if (effectFired.current) return;
    effectFired.current = true;

    let agent: Agent;
    let interval: NodeJS.Timeout;

    clippy.load({
      name: "Clippy",
      successCb: (clippyAgent) => {
        agent = clippyAgent;
        agent.show(false);

        interval = setInterval(() => {
          const randomIndex = Math.floor(Math.random() * animations.length);
          const randomAnimation = animations[randomIndex];
          randomAnimation(agent);
        }, 20 * 1000);
      },
    });

    return () => {
      if (agent) {
        agent.stop();
        agent.hide(false, () => {});
      }
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);
}
