import { AccessLoading } from "@acdc2/ui/components/access-loading";
import { useTranslation } from "react-i18next";

export function SidebarLoading(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col justify-center items-center gap-4">
      <AccessLoading />
      <span className="font-bold uppercase">{t("SidebarLoading.label")}</span>
    </div>
  );
}
