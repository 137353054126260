import { useHumanizedDiff } from "../i18n/useHumanizedDiff";
import { DocumentTableCell } from "./DocumentTableCell";

type Props = {
  date: string;
};

export function DocumentDateTableCell({ date }: Props): JSX.Element {
  const humanizedDiff = useHumanizedDiff(date);

  return (
    <DocumentTableCell className="first-letter:capitalize">
      {humanizedDiff}
    </DocumentTableCell>
  );
}
