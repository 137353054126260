import { useTranslation } from "react-i18next";
import { Progress } from "@acdc2/ui/components/progress";
import { Icon } from "@mdi/react";
import { mdiAutoFix } from "@mdi/js";
import {
  Alert,
  AlertTitle,
  AlertDescription,
} from "@acdc2/ui/components/alert";

export function PageUnsegmentedAlert(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <Alert>
        <Icon path={mdiAutoFix} size="1rem" />
        <AlertTitle>{t("PageUnsegmentedAlert.title")}</AlertTitle>
        <AlertDescription className="pb-4">
          {t("PageUnsegmentedAlert.description")}
        </AlertDescription>
      </Alert>
      <div className="absolute bottom-4 w-full px-4">
        <Progress className="h-1" indeterminate />
      </div>
    </div>
  );
}
