import { RegionTypeFragment } from "../client/generated";
import { PossibleTypesResultData } from "../client/generated/introspection";

export type AttributeKind =
  PossibleTypesResultData["possibleTypes"]["Attribute"][number];

export type RegionType = MatchRegionTypeResult[0];

export function getRegionType(region: RegionTypeFragment): RegionType {
  const [regionType] = matchRegionType(region);
  return regionType;
}

type MatchRegionTypeResult =
  | ["Artifact", { paginationId: string }]
  | ["Caption", { captionId: string; textId: string }]
  | ["Formula", { expressionId: string }]
  | ["Figure", { figureId: string }]
  | ["Heading", { headerLevelId: string; textId: string }]
  | ["Image", { alternativeTextId: string; imageId: string }]
  | ["List", { listId: string }]
  | ["ListItem", { listItemId: string; textId: string }]
  | ["Sublist", { listId: string; listItemId: string }]
  | ["Table", { tableId: string }]
  | ["TOC", { tocId: string }]
  | ["Text", { textId: string }]
  | ["Untyped", Record<string, never>]
  | ["Hybrid", Record<string, never>];

export function matchRegionType(
  region: RegionTypeFragment,
): MatchRegionTypeResult {
  const numAttributes = region.attributes.length;

  if (numAttributes === 0) {
    return ["Untyped", {}];
  }

  const kind2id = region.attributes.reduce(
    (acc, attr) => {
      acc[attr.__typename] = attr.id;
      return acc;
    },
    {} as Record<AttributeKind, string | undefined>,
  );

  const {
    AlternativeTextAttribute: alternativeTextId,
    CaptionAttribute: captionId,
    ExpressionAttribute: expressionId,
    FigureAttribute: figureId,
    HeaderLevelAttribute: headerLevelId,
    ImageAttribute: imageId,
    ListAttribute: listId,
    ListItemAttribute: listItemId,
    PaginationArtifactAttribute: paginationId,
    TableAttribute: tableId,
    TableOfContentsAttribute: tocId,
    TextAttribute: textId,
  } = kind2id;

  if (paginationId && numAttributes === 1) {
    return ["Artifact", { paginationId }];
  }

  if (captionId && textId && numAttributes === 2) {
    return ["Caption", { captionId, textId }];
  }

  if (expressionId && numAttributes === 1) {
    return ["Formula", { expressionId }];
  }

  if (figureId && numAttributes === 1) {
    return ["Figure", { figureId }];
  }

  if (headerLevelId && textId && numAttributes === 2) {
    return ["Heading", { headerLevelId, textId }];
  }

  if (alternativeTextId && imageId && numAttributes === 2) {
    return ["Image", { alternativeTextId, imageId }];
  }

  if (listId && numAttributes === 1) {
    return ["List", { listId }];
  }

  if (listItemId && textId && numAttributes === 2) {
    return ["ListItem", { listItemId, textId }];
  }

  if (listId && listItemId && numAttributes === 2) {
    return ["Sublist", { listId, listItemId }];
  }

  if (tableId && numAttributes === 1) {
    return ["Table", { tableId }];
  }

  if (tocId && numAttributes === 1) {
    return ["TOC", { tocId }];
  }

  if (textId && numAttributes === 1) {
    return ["Text", { textId }];
  }

  return ["Hybrid", {}];
}
