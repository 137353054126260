import { useTranslation } from "react-i18next";
import { assertTypeName } from "../../client/guards";
import { ListRegionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: ListRegionPreviewFragment;
  listAttributeId: string;
};

export function ListRegionPreview({
  fragment,
  listAttributeId,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const listAttribute = fragment.attributes.find(
    (a) => a.id === listAttributeId,
  );
  assertTypeName(listAttribute?.__typename === "ListAttribute");

  return <>{t(`ListRegionPreview.preview.${listAttribute.listType}`)}</>;
}
