import { Dispatch } from "react";
import { RegionTypeCard } from "../RegionTypeCard";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { useTranslation } from "react-i18next";

type Props = {
  regionId: string;
  dispatch: Dispatch<EditorAction>;
};

export function ListItemRegionTypeCard({
  regionId,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const onConvert = () => {
    dispatch({
      type: "switchTab",
      tabIdentifier: makeIdentifier(TabPrefix.ListItemRegionCreation, regionId),
    });
  };

  return (
    <RegionTypeCard
      title={t("ListItemRegionTypeCard.title")}
      description={t("ListItemRegionTypeCard.description")}
      onConvert={onConvert}
    />
  );
}
