import { DocumentModifiedAtTableCellFragment } from "../client/generated";
import { DocumentDateTableCell } from "./DocumentDateTableCell";

type Props = {
  fragment: DocumentModifiedAtTableCellFragment;
};

export function DocumentModifiedAtTableCell({ fragment }: Props): JSX.Element {
  return <DocumentDateTableCell date={fragment.lastModifiedAt} />;
}
