import { Skeleton } from "@acdc2/ui/components/skeleton";
import { PageSkeletonFragment } from "../client/generated";

type Props = {
  fragment: PageSkeletonFragment;
};

export function PageSkeleton({ fragment }: Props): JSX.Element {
  return (
    <div
      style={{ aspectRatio: `${fragment.width}/${fragment.height}` }}
      className="h-full bg-white shadow relative"
    >
      {fragment.regions.map((region) => (
        <Skeleton
          key={region.id}
          className="absolute"
          style={{
            top: `${region.shape.aabb.top * 100}%`,
            left: `${region.shape.aabb.left * 100}%`,
            width: `${region.shape.aabb.width * 100}%`,
            height: `${region.shape.aabb.height * 100}%`,
          }}
        />
      ))}
    </div>
  );
}
