import { Dispatch, useState } from "react";
import { ListItemFormField } from "../../attributes/list-item/ListItemFormField";
import { TextFormField } from "../../attributes/text/TextFormField";
import { EditorAction } from "../../editor/reducers";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { assertTypeName } from "../../client/guards";
import {
  useListItemRegionEditorSuspenseQuery,
  useUpdateListItemRegionMutation,
} from "../../client/generated";

type Props = {
  listItemAttributeId: string;
  textAttributeId: string;
  dispatch: Dispatch<EditorAction>;
};

export function ListItemRegionEditor({
  listItemAttributeId,
  textAttributeId,
  dispatch,
}: Props): JSX.Element {
  const { data } = useListItemRegionEditorSuspenseQuery({
    variables: {
      listItemAttributeId,
      textAttributeId,
    },
  });

  const { listItemAttr, textAttr } = data;
  assertTypeName(listItemAttr?.__typename === "ListItemAttribute");
  assertTypeName(textAttr?.__typename === "TextAttribute");

  const [updateRegion] = useUpdateListItemRegionMutation();
  const [ordinal, setOrdinal] = useState(listItemAttr.ordinal);
  const [text, setText] = useState(textAttr.text);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateRegion({
      variables: {
        listItemAttributeId,
        textAttributeId,
        ordinal,
        text,
      },
    });
  };

  return (
    <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch}>
      <ListItemFormField
        value={ordinal}
        onChange={(event) => setOrdinal(parseInt(event.target.value))}
      />
      <TextFormField
        fragment={textAttr.region}
        value={text}
        onChange={(event) => setText(event.target.value)}
        onPrediction={setText}
      />
    </RegionUpdateForm>
  );
}
