import { Button } from "@acdc2/ui/components/button";
import { cn } from "@acdc2/ui/lib/utils";
import { mdiContentSave, mdiLoading, mdiTrashCan } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@acdc2/ui/components/tooltip";
import { Badge } from "@acdc2/ui/components/badge";

type Props = {
  title: string;
  unsaved?: boolean;
  onSave: () => Promise<void>;
  onDelete: () => Promise<void>;
  children?: React.ReactNode;
};

export function AttributePanel({
  title,
  unsaved,
  onSave,
  onDelete,
  children,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleSave = async () => {
    setSaving(true);
    try {
      await onSave();
    } finally {
      setSaving(false);
    }
  };

  const handleDelete = async () => {
    setDeleting(true);
    try {
      await onDelete();
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div className="flex flex-col border-b">
      <div className="flex flex-row justify-between items-center p-4 border-b border-dashed">
        <div className="flex flex-row">
          <span>{title}</span>
          <div className="px-2">
            {unsaved && (
              <Badge variant="destructive">
                {t("AttributePanel.badges.unsaved")}
              </Badge>
            )}
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={handleSave}
                  disabled={saving}
                >
                  <Icon
                    className={cn({ "animate-spin": saving })}
                    path={saving ? mdiLoading : mdiContentSave}
                    size="1.25rem"
                  />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                {t("AttributePanel.tooltips.save")}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={handleDelete}
                  disabled={deleting}
                >
                  <Icon
                    className={cn({ "animate-spin": deleting })}
                    path={deleting ? mdiLoading : mdiTrashCan}
                    size="1.25rem"
                  />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                {t("AttributePanel.tooltips.delete")}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      <div className="p-4">{children}</div>
    </div>
  );
}
