import { DocumentUploadedByTableCellFragment } from "../client/generated";
import { UserAvatar } from "../users/UserAvatar";
import { DocumentTableCell } from "./DocumentTableCell";

type Props = {
  fragment: DocumentUploadedByTableCellFragment;
};

export function DocumentUploadedByTableCell({ fragment }: Props): JSX.Element {
  return (
    <DocumentTableCell className="flex items-center gap-2">
      <UserAvatar className="h-6 w-6" fragment={fragment.uploadedBy} />
      <span>{fragment.uploadedBy.username}</span>
    </DocumentTableCell>
  );
}
