import { Dispatch, useState } from "react";
import { EditorAction } from "../../editor/reducers";
import { assertTypeName } from "../../client/guards";
import { AlternativeTextFormField } from "../../attributes/alternative-text/AlternativeTextFormField";
import { RegionUpdateForm } from "../RegionUpdateForm";
import {
  useImageRegionEditorSuspenseQuery,
  useUpdateImageRegionMutation,
} from "../../client/generated";

type Props = {
  alternativeTextAttributeId: string;
  imageAttributeId: string;
  dispatch: Dispatch<EditorAction>;
};

export function ImageRegionEditor({
  alternativeTextAttributeId,
  imageAttributeId,
  dispatch,
}: Props): JSX.Element {
  const { data } = useImageRegionEditorSuspenseQuery({
    variables: {
      alternativeTextAttributeId,
      imageAttributeId,
    },
  });

  const { altTextAttr } = data;
  assertTypeName(altTextAttr?.__typename === "AlternativeTextAttribute");

  const [updateAttribute] = useUpdateImageRegionMutation();
  const [alternativeText, setAlternativeText] = useState(
    altTextAttr.alternativeText,
  );

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateAttribute({
      variables: {
        alternativeTextAttributeId,
        alternativeText,
      },
    });
  };

  return (
    <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch}>
      <AlternativeTextFormField
        fragment={altTextAttr.region}
        value={alternativeText}
        onChange={(event) => setAlternativeText(event.target.value)}
        onPrediction={setAlternativeText}
      />
    </RegionUpdateForm>
  );
}
