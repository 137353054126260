import { useTranslation } from "react-i18next";
import { assertTypeName } from "../../client/guards";
import { SublistRegionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: SublistRegionPreviewFragment;
  listItemAttributeId: string;
  listAttributeId: string;
};

export function SublistRegionPreview({
  fragment,
  listItemAttributeId,
  listAttributeId,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const listItemAttribute = fragment.attributes.find(
    (a) => a.id === listItemAttributeId,
  );
  assertTypeName(listItemAttribute?.__typename === "ListItemAttribute");

  const listAttribute = fragment.attributes.find(
    (a) => a.id === listAttributeId,
  );
  assertTypeName(listAttribute?.__typename === "ListAttribute");

  return (
    <>
      {t("SublistRegionPreview.preview", {
        style: t(`ListRegionPreview.preview.${listAttribute.listType}`),
        position: listItemAttribute.ordinal,
      })}
    </>
  );
}
