import { useTranslation } from "react-i18next";
import { useState } from "react";
import { AttributePanel } from "../AttributePanel";
import { AlternativeTextFormField } from "./AlternativeTextFormField";
import {
  AlternativeTextCreationPanelFragment,
  useCreateAlternativeTextFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: AlternativeTextCreationPanelFragment;
  onDelete: () => Promise<void>;
};

export function AlternativeTextCreationPanel({
  fragment,
  onDelete,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createAttribute] = useCreateAlternativeTextFromPanelMutation();
  const [alternativeText, setAlternativeText] = useState<string>();

  const onSave = async () => {
    if (!alternativeText) return;
    await createAttribute({
      variables: {
        regionId: fragment.id,
        alternativeText,
      },
    });
    await onDelete();
  };

  return (
    <AttributePanel
      title={t("AlternativeTextCreationPanel.title")}
      unsaved
      onSave={onSave}
      onDelete={onDelete}
    >
      <AlternativeTextFormField
        fragment={fragment}
        value={alternativeText}
        onChange={(event) => setAlternativeText(event.target.value)}
        onPrediction={setAlternativeText}
      />
    </AttributePanel>
  );
}
