import { useTranslation } from "react-i18next";
import { KaTeXPreview } from "./KaTeXPreview";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@acdc2/ui/components/form";

type Props = {
  latex: string;
};

export function ExpressionPreviewFormField({ latex }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <FormField>
      <FormItem>
        <FormLabel>{t("ExpressionPreviewFormField.label")}</FormLabel>
        <FormControl>
          <KaTeXPreview latexExpression={latex} />
        </FormControl>
      </FormItem>
    </FormField>
  );
}
