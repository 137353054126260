import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import { ListItemFormField } from "./ListItemFormField";
import {
  ListItemUpdatePanelFragment,
  useDeleteListItemFromPanelMutation,
  useUpdateListItemFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: ListItemUpdatePanelFragment;
};

export function ListItemUpdatePanel({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const [updateAttribute] = useUpdateListItemFromPanelMutation();
  const [deleteAttribute] = useDeleteListItemFromPanelMutation();
  const [ordinal, setOrdinal] = useState(fragment.ordinal);

  const onSave = async () => {
    await updateAttribute({
      variables: {
        attributeId: fragment.id,
        ordinal,
      },
    });
  };

  const onDelete = async () => {
    await deleteAttribute({
      variables: { attributeId: fragment.id },
      update(cache) {
        const id = cache.identify(fragment);
        cache.evict({ id });
        cache.gc();
      },
    });
  };

  return (
    <AttributePanel
      title={t("ListItemUpdatePanel.title")}
      unsaved={ordinal !== fragment.ordinal}
      onSave={onSave}
      onDelete={onDelete}
    >
      <ListItemFormField
        value={ordinal}
        onChange={(event) => setOrdinal(parseInt(event.target.value))}
      />
    </AttributePanel>
  );
}
