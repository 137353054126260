import { Dispatch } from "react";
import { EditorAction } from "../../editor/reducers";
import { ArtifactRegionTypeCard } from "../artifact/ArtifactRegionTypeCard";
import { CaptionRegionTypeCard } from "../caption/CaptionRegionTypeCard";
import { FigureRegionTypeCard } from "../figure/FigureRegionTypeCard";
import { FormulaRegionTypeCard } from "../formula/FormulaRegionTypeCard";
import { HeadingRegionTypeCard } from "../heading/HeadingRegionTypeCard";
import { ImageRegionTypeCard } from "../image/ImageRegionTypeCard";
import { ListRegionTypeCard } from "../list/ListRegionTypeCard";
import { ListItemRegionTypeCard } from "../list-item/ListItemRegionTypeCard";
import { SublistRegionTypeCard } from "../sublist/SublistRegionTypeCard";
import { TableRegionTypeCard } from "../table/TableRegionTypeCard";
import { TextRegionTypeCard } from "../text/TextRegionTypeCard";
import { TOCRegionTypeCard } from "../toc/TOCRegionTypeCard";

type Props = {
  regionId: string;
  dispatch: Dispatch<EditorAction>;
};

export function UntypedRegionEditor({
  regionId,
  dispatch,
}: Props): JSX.Element {
  return (
    <div className="flex flex-col p-4 gap-4">
      <HeadingRegionTypeCard regionId={regionId} dispatch={dispatch} />
      <TextRegionTypeCard regionId={regionId} dispatch={dispatch} />
      <FormulaRegionTypeCard regionId={regionId} dispatch={dispatch} />

      <TableRegionTypeCard regionId={regionId} dispatch={dispatch} />
      <ImageRegionTypeCard regionId={regionId} dispatch={dispatch} />
      <FigureRegionTypeCard regionId={regionId} dispatch={dispatch} />
      <CaptionRegionTypeCard regionId={regionId} dispatch={dispatch} />

      <ListRegionTypeCard regionId={regionId} dispatch={dispatch} />
      <ListItemRegionTypeCard regionId={regionId} dispatch={dispatch} />
      <SublistRegionTypeCard regionId={regionId} dispatch={dispatch} />

      <ArtifactRegionTypeCard regionId={regionId} dispatch={dispatch} />
      <TOCRegionTypeCard regionId={regionId} dispatch={dispatch} />
    </div>
  );
}
