import { cn } from "@acdc2/ui/lib/utils";
import { RegionThumbnailFragment } from "../client/generated";
import { RegionImage } from "./RegionImage";
import { getRegionType } from "../types/utils";

type Props = {
  fragment: RegionThumbnailFragment;
};

export function RegionThumbnail({ fragment }: Props): JSX.Element {
  const regionKind = getRegionType(fragment);
  const viewBoxWidth = fragment.shape.aabb.width * fragment.page.width;
  const viewBoxHeight = fragment.shape.aabb.height * fragment.page.height;

  return (
    <div
      className={cn(
        "min-h-16 flex flex-col justify-center bg-gradient-to-r p-2",
        {
          "from-attr-untyped-max to-attr-untyped-min": regionKind === "Untyped",
          "from-attr-hybrid-max to-attr-hybrid-min": regionKind === "Hybrid",
          "from-attr-heading-max to-attr-heading-min": regionKind === "Heading",
          "from-attr-caption-max to-attr-caption-min": regionKind === "Caption",
          "from-attr-formula-max to-attr-formula-min": regionKind === "Formula",
          "from-attr-figure-max to-attr-figure-min": regionKind === "Figure",
          "from-attr-image-max to-attr-image-min": regionKind === "Image",
          "from-attr-list-max to-attr-list-min":
            regionKind === "List" ||
            regionKind === "ListItem" ||
            regionKind === "Sublist",
          "from-attr-artifact-max to-attr-artifact-min":
            regionKind === "Artifact",
          "from-attr-table-max to-attr-table-min": regionKind === "Table",
          "from-attr-toc-max to-attr-toc-min": regionKind === "TOC",
          "from-attr-text-max to-attr-text-min": regionKind === "Text",
        },
      )}
    >
      <div
        className="mx-auto drop-shadow-lg"
        style={{ width: `${viewBoxWidth}in`, height: `${viewBoxHeight}in` }}
      >
        <RegionImage fragment={fragment} />
      </div>
    </div>
  );
}
