import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import { TableCellRowSpanFormField } from "./TableCellRowSpanFormField";
import { useState } from "react";
import { TableCellColumnSpanFormField } from "./TableCellColumnSpanFormField";
import {
  TableCellCreationPanelFragment,
  useCreateTableCellFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: TableCellCreationPanelFragment;
  onDelete: () => Promise<void>;
};

export function TableCellCreationPanel({
  fragment,
  onDelete,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createAttribute] = useCreateTableCellFromPanelMutation();
  const [rowSpan, setRowSpan] = useState<number>();
  const [columnSpan, setColumnSpan] = useState<number>();

  const onSave = async () => {
    if (!rowSpan || !columnSpan) return;
    await createAttribute({
      variables: {
        regionId: fragment.id,
        rowSpan,
        columnSpan,
      },
    });
    await onDelete();
  };

  return (
    <AttributePanel
      title={t("TableCellCreationPanel.title")}
      unsaved
      onSave={onSave}
      onDelete={onDelete}
    >
      <div className="flex flex-col gap-4">
        <TableCellRowSpanFormField
          value={rowSpan ?? ""}
          onChange={(event) => setRowSpan(parseInt(event.target.value))}
        />
        <TableCellColumnSpanFormField
          value={columnSpan ?? ""}
          onChange={(event) => setColumnSpan(parseInt(event.target.value))}
        />
      </div>
    </AttributePanel>
  );
}
