import { Dispatch, useState } from "react";
import { EditorAction } from "../../editor/reducers";
import { assertTypeName } from "../../client/guards";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { TableRowOffsetFormField } from "../../attributes/table/TableRowOffsetFormField";
import { TableColumnOffsetFormField } from "../../attributes/table/TableColumnOffsetFormField";
import { TableRowListFormField } from "../../attributes/table/TableRowListFormField";
import { TableColumnListFormField } from "../../attributes/table/TableColumnListFormField";
import { v4 as uuid } from "uuid";
import {
  useTableRegionEditorSuspenseQuery,
  useUpdateTableRegionMutation,
} from "../../client/generated";

type Props = {
  tableAttributeId: string;
  dispatch: Dispatch<EditorAction>;
};

export function TableRegionEditor({
  tableAttributeId,
  dispatch,
}: Props): JSX.Element {
  const { data } = useTableRegionEditorSuspenseQuery({
    variables: {
      tableAttributeId,
    },
  });

  const { tableAttr } = data;
  assertTypeName(tableAttr?.__typename === "TableAttribute");

  const [updateRegion] = useUpdateTableRegionMutation();
  const [parentRowOffset, setParentRowOffset] = useState(
    tableAttr.parentRowOffset,
  );
  const [parentColumnOffset, setParentColumnOffset] = useState(
    tableAttr.parentColumnOffset,
  );
  const [rows, setRows] = useState(
    tableAttr.rows.map((height) => ({ uuid: uuid(), height })),
  );
  const [columns, setColumns] = useState(
    tableAttr.columns.map((width) => ({ uuid: uuid(), width })),
  );

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    await updateRegion({
      variables: {
        tableAttributeId,
        parentRowOffset,
        parentColumnOffset,
        rows: rows.map((row) => row.height),
        columns: columns.map((column) => column.width),
      },
    });
  };

  return (
    <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch}>
      <TableRowOffsetFormField
        value={parentRowOffset}
        onChange={(event) => setParentRowOffset(parseInt(event.target.value))}
      />
      <TableColumnOffsetFormField
        value={parentColumnOffset}
        onChange={(event) =>
          setParentColumnOffset(parseInt(event.target.value))
        }
      />
      <TableRowListFormField rows={rows} setRows={setRows} />
      <TableColumnListFormField columns={columns} setColumns={setColumns} />
    </RegionUpdateForm>
  );
}
