import { makeRegionPreviewID } from "../regions/utils";

export enum TabIdentifier {
  Metadata = "metadata-editor-tab",
  Pages = "pages-editor-tab",
  Regions = "regions-editor-tab",
  Attributes = "attributes-editor-tab",
}

export enum TabPrefix {
  ArtifactRegionCreation = "artifact-region-creation-tab",
  CaptionRegionCreation = "caption-region-creation-tab",
  FigureRegionCreation = "figure-region-creation-tab",
  FormulaRegionCreation = "formula-region-creation-tab",
  HeadingRegionCreation = "heading-region-creation-tab",
  HybridRegionCreation = "hybrid-region-creation-tab",
  ImageRegionCreation = "image-region-creation-tab",
  ListRegionCreation = "list-region-creation-tab",
  ListItemRegionCreation = "list-item-region-creation-tab",
  SublistRegionCreation = "sublist-region-creation-tab",
  TableRegionCreation = "table-region-creation-tab",
  TextRegionCreation = "text-region-creation-tab",
  TOCRegionCreation = "toc-region-creation-tab",
  RegionUpdate = "region-update-tab",
}

export enum RegionMode {
  Rectangle = "rectangle",
  Polygon = "polygon",
}

export type SwitchRegionModeEditorAction = {
  type: "switchMode";
  mode: RegionMode;
};

export type SelectRegionEditorAction = {
  type: "selectRegion";
  regionId: string;
};

export type DeselectRegionEditorAction = {
  type: "deselectRegion";
};

export type SwitchTabEditorAction = {
  type: "switchTab";
  tabIdentifier: string;
};

export type EditorAction =
  | SwitchRegionModeEditorAction
  | SelectRegionEditorAction
  | DeselectRegionEditorAction
  | SwitchTabEditorAction;

export type EditorState = {
  mode: RegionMode;
  tabIdentifier: string;
  selectedRegionId?: string;
};

export function editorReducer(
  state: EditorState,
  action: EditorAction,
): EditorState {
  if (action.type === "switchMode") {
    return {
      ...state,
      mode: action.mode,
    };
  }

  if (action.type === "selectRegion") {
    scrolToRegion(action.regionId);

    return {
      ...state,
      tabIdentifier: TabIdentifier.Regions,
      selectedRegionId: action.regionId,
    };
  }

  if (action.type === "deselectRegion") {
    return {
      ...state,
      selectedRegionId: undefined,
    };
  }

  if (action.type === "switchTab") {
    if (state.selectedRegionId !== undefined) {
      scrolToRegion(state.selectedRegionId, true);
    }

    return {
      ...state,
      tabIdentifier: action.tabIdentifier,
    };
  }

  return state;
}

export function makeIdentifier(tabPrefix: TabPrefix, nodeId: string): string {
  return `${tabPrefix}-${nodeId}`;
}

function scrolToRegion(regionId: string, instant = false) {
  setTimeout(() => {
    document.getElementById(makeRegionPreviewID(regionId))?.scrollIntoView({
      behavior: instant ? "instant" : "smooth",
      block: "center",
    });
  });
}
