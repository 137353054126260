import { assertTypeName } from "../../client/guards";
import { CaptionRegionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: CaptionRegionPreviewFragment;
  textAttributeId: string;
};

export function CaptionRegionPreview({
  fragment,
  textAttributeId,
}: Props): JSX.Element {
  const textAttribute = fragment.attributes.find(
    (a) => a.id === textAttributeId,
  );
  assertTypeName(textAttribute?.__typename === "TextAttribute");

  return <>{textAttribute.text}</>;
}
