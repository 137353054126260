import { TableOfContentsItemFormFieldFragment } from "../../client/generated";
import { ComponentProps } from "react";
import { RegionSelect } from "../../regions/RegionSelect";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@acdc2/ui/components/form";

type Props = Omit<
  ComponentProps<typeof RegionSelect>,
  "qualifyingAttributes"
> & {
  fragment: TableOfContentsItemFormFieldFragment;
};

export function TableOfContentsItemFormField({
  fragment,
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <FormField>
      <FormItem>
        <FormLabel>{t("TableOfContentsItemFormField.label")}</FormLabel>
        <FormControl>
          <RegionSelect
            {...props}
            fragment={fragment}
            qualifyingAttributes={["HeaderLevelAttribute", "CaptionAttribute"]}
          />
        </FormControl>
      </FormItem>
    </FormField>
  );
}
