import { Dispatch, useState } from "react";
import { EditorAction } from "../../editor/reducers";
import { HeadingLevelFormField } from "../../attributes/heading-level/HeadingLevelFormField";
import { TextFormField } from "../../attributes/text/TextFormField";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { assertTypeName } from "../../client/guards";
import {
  useHeadingRegionEditorSuspenseQuery,
  useUpdateHeadingRegionMutation,
} from "../../client/generated";

type Props = {
  headerLevelAttributeId: string;
  textAttributeId: string;
  dispatch: Dispatch<EditorAction>;
};

export function HeadingRegionEditor({
  headerLevelAttributeId,
  textAttributeId,
  dispatch,
}: Props): JSX.Element {
  const { data } = useHeadingRegionEditorSuspenseQuery({
    variables: {
      headerLevelAttributeId,
      textAttributeId,
    },
  });

  const { headerLevelAttr, textAttr } = data;
  assertTypeName(headerLevelAttr?.__typename === "HeaderLevelAttribute");
  assertTypeName(textAttr?.__typename === "TextAttribute");

  const [updateRegion] = useUpdateHeadingRegionMutation();
  const [level, setLevel] = useState(headerLevelAttr.level);
  const [text, setText] = useState(textAttr.text);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateRegion({
      variables: {
        headerLevelAttributeId,
        textAttributeId,
        level,
        text,
      },
    });
  };

  return (
    <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch}>
      <HeadingLevelFormField
        value={level.toString()}
        onValueChange={(newLevel) => setLevel(parseInt(newLevel))}
        required
      />
      <TextFormField
        fragment={textAttr.region}
        value={text}
        onChange={(event) => setText(event.target.value)}
        onPrediction={setText}
      />
    </RegionUpdateForm>
  );
}
