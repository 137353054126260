import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@acdc2/ui/components/accordion";
import { useTranslation } from "react-i18next";
import {
  MetadataKeywordAccordionItemFragment,
  useAddSubjectMutation,
  useRemoveSubjectMutation,
} from "../client/generated";
import { AccessTagInput } from "@acdc2/ui/components/access-tag-input";

type Props = {
  fragment: MetadataKeywordAccordionItemFragment;
};

export function MetadataKeywordAccordionItem({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();

  const subjects = fragment.metadata.subject.map((tag) => tag.subject);

  const [addSubject] = useAddSubjectMutation({ refetchQueries: "active" });
  const [removeSubject] = useRemoveSubjectMutation({
    update(cache) {
      const id = cache.identify(fragment);
      cache.evict({ id });
      cache.gc();
    },
  });

  const onTagAdd = async (subject: string) => {
    await addSubject({
      variables: {
        documentId: fragment.id,
        subject,
      },
    });
  };

  const onTagRemove = async (subject: string) => {
    const subjectId = fragment.metadata.subject.find(
      (tag) => tag.subject === subject,
    )?.id;
    if (!subjectId) {
      return;
    }

    await removeSubject({
      variables: {
        subjectId,
      },
    });
  };

  return (
    <AccordionItem value="keywords">
      <AccordionTrigger className="px-4">
        {t("MetadataKeywordAccordionItem.label")}
      </AccordionTrigger>
      <AccordionContent className="px-4 pt-1">
        <AccessTagInput
          tags={subjects}
          onTagAdd={onTagAdd}
          onTagRemove={onTagRemove}
          placeholder={t("MetadataKeywordAccordionItem.placeholder")}
        />
      </AccordionContent>
    </AccordionItem>
  );
}
