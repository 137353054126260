import { useTranslation } from "react-i18next";
import { matchRegionType } from "../types/utils";
import { RegionTileFragment } from "../client/generated";
import { Dispatch } from "react";
import { EditorAction, makeIdentifier, TabPrefix } from "../editor/reducers";
import { ListRegionPreview } from "../types/list/ListRegionPreview";
import { TextRegionPreview } from "../types/text/TextRegionPreview";
import { ImageRegionPreview } from "../types/image/ImageRegionPreview";
import { HybridRegionPreview } from "../types/hybrid/HybridRegionPreview";
import { HeadingRegionPreview } from "../types/heading/HeadingRegionPreview";
import { FormulaRegionPreview } from "../types/formula/FormulaRegionPreview";
import { CaptionRegionPreview } from "../types/caption/CaptionRegionPreview";
import { TableRegionPreview } from "../types/table/TableRegionPreview";
import { ArtifactRegionPreview } from "../types/artifact/ArtifactRegionPreview";
import { TOCRegionPreview } from "../types/toc/TOCRegionPreview";
import { FigureRegionPreview } from "../types/figure/FigureRegionPreview";
import { ListItemRegionPreview } from "../types/list-item/ListItemRegionPreview";
import { SublistRegionPreview } from "../types/sublist/SublistRegionPreview";

type Props = {
  fragment: RegionTileFragment;
  dispatch: Dispatch<EditorAction>;
  children?: React.ReactNode;
};

export function RegionTile({
  fragment,
  dispatch,
  children,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [regionType, params] = matchRegionType(fragment);

  const onClick = () => {
    dispatch({
      type: "selectRegion",
      regionId: fragment.id,
    });
    dispatch({
      type: "switchTab",
      tabIdentifier: makeIdentifier(TabPrefix.RegionUpdate, fragment.id),
    });
  };

  return (
    <div className="flex flex-row items-center p-2">
      <div className="flex-1 overflow-hidden">
        <button
          className="flex flex-row gap-1 items-center"
          type="button"
          onClick={onClick}
        >
          <div className="font-bold flex flex-row gap-1 hover:text-brand">
            <span>{t(`RegionTile.title.${regionType}`)}</span>
            <span>[{fragment.readingOrderIndex + 1}]</span>
          </div>
        </button>
        <div className="truncate text-xs">
          {regionType === "Artifact" ? (
            <ArtifactRegionPreview
              fragment={fragment}
              paginationArtifactAttributeId={params.paginationId}
            />
          ) : regionType === "Caption" ? (
            <CaptionRegionPreview
              fragment={fragment}
              textAttributeId={params.textId}
            />
          ) : regionType === "Figure" ? (
            <FigureRegionPreview
              fragment={fragment}
              figureAttributeId={params.figureId}
            />
          ) : regionType === "Formula" ? (
            <FormulaRegionPreview
              fragment={fragment}
              expressionAttributeId={params.expressionId}
            />
          ) : regionType === "Heading" ? (
            <HeadingRegionPreview
              fragment={fragment}
              headerLevelAttributeId={params.headerLevelId}
              textAttributeId={params.textId}
            />
          ) : regionType === "Hybrid" ? (
            <HybridRegionPreview fragment={fragment} />
          ) : regionType === "Image" ? (
            <ImageRegionPreview
              fragment={fragment}
              alternativeTextAttributeId={params.alternativeTextId}
            />
          ) : regionType === "List" ? (
            <ListRegionPreview
              fragment={fragment}
              listAttributeId={params.listId}
            />
          ) : regionType === "ListItem" ? (
            <ListItemRegionPreview
              fragment={fragment}
              listItemAttributeId={params.listItemId}
              textAttributeId={params.textId}
            />
          ) : regionType === "Sublist" ? (
            <SublistRegionPreview
              fragment={fragment}
              listItemAttributeId={params.listItemId}
              listAttributeId={params.listId}
            />
          ) : regionType === "Table" ? (
            <TableRegionPreview
              fragment={fragment}
              tableAttributeId={params.tableId}
            />
          ) : regionType === "Text" ? (
            <TextRegionPreview
              fragment={fragment}
              textAttributeId={params.textId}
            />
          ) : regionType === "TOC" ? (
            <TOCRegionPreview
              fragment={fragment}
              tocAttributeId={params.tocId}
            />
          ) : null}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
}
