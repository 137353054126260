import { v4 as uuid } from "uuid";
import { ScrollArea } from "@acdc2/ui/components/scroll-area";
import { NavbarTabCloseButton } from "../../shell/NavbarTabCloseButton";
import { NavbarTabContent } from "../../shell/NavbarTabContent";
import { useTranslation } from "react-i18next";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { Dispatch, useState } from "react";
import { assertTypeName } from "../../client/guards";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { TableRowOffsetFormField } from "../../attributes/table/TableRowOffsetFormField";
import { TableColumnOffsetFormField } from "../../attributes/table/TableColumnOffsetFormField";
import { TableRowListFormField } from "../../attributes/table/TableRowListFormField";
import { TableColumnListFormField } from "../../attributes/table/TableColumnListFormField";
import {
  TableRegionCreationTabFragment,
  useCreateTableRegionMutation,
  useTableRegionCreationTabSuspenseQuery,
} from "../../client/generated";

type Props = {
  fragment: TableRegionCreationTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export function TableRegionCreationTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.TableRegionCreation, fragment.id)}
      title={t("TableRegionCreationTab.title")}
      right={<NavbarTabCloseButton dispatch={dispatch} />}
    >
      <ScrollArea className="flex-1">
        <Body fragment={fragment} dispatch={dispatch} />
      </ScrollArea>
    </NavbarTabContent>
  );
}

function Body({ fragment, dispatch }: Props) {
  const tableAttributeIds = fragment.attributes
    .filter((attr) => attr.__typename === "TableAttribute")
    .map((attr) => attr.id);

  const { data } = useTableRegionCreationTabSuspenseQuery({
    variables: {
      regionId: fragment.id,
      tableAttributeIds,
    },
  });

  assertTypeName(data.region?.__typename === "Region");

  const [createRegion] = useCreateTableRegionMutation();

  const [parentRowOffset, setParentRowOffset] = useState(() => {
    const attr = data.tableAttributes[0];
    if (!attr) return undefined;
    assertTypeName(attr.__typename === "TableAttribute");
    return attr.parentRowOffset;
  });

  const [parentColumnOffset, setParentColumnOffset] = useState(() => {
    const attr = data.tableAttributes[0];
    if (!attr) return undefined;
    assertTypeName(attr.__typename === "TableAttribute");
    return attr.parentColumnOffset;
  });

  const [rows, setRows] = useState(() => {
    const attr = data.tableAttributes[0];
    if (!attr) return [];
    assertTypeName(attr.__typename === "TableAttribute");
    return attr.rows.map((height) => ({ uuid: uuid(), height }));
  });

  const [columns, setColumns] = useState(() => {
    const attr = data.tableAttributes[0];
    if (!attr) return [];
    assertTypeName(attr.__typename === "TableAttribute");
    return attr.columns.map((width) => ({ uuid: uuid(), width }));
  });

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (parentRowOffset === undefined || parentColumnOffset === undefined)
      return;

    await createRegion({
      variables: {
        regionId: fragment.id,
        parentRowOffset,
        parentColumnOffset,
        rows: rows.map((row) => row.height),
        columns: columns.map((column) => column.width),
        excessAttributeIds: fragment.attributes.map((attr) => attr.id),
      },
      update: (cache) => {
        for (const attr of fragment.attributes) {
          const id = cache.identify(attr);
          cache.evict({ id });
          cache.gc();
        }
      },
    });
  };

  return (
    <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch}>
      <TableRowOffsetFormField
        value={parentRowOffset}
        onChange={(event) => setParentRowOffset(parseInt(event.target.value))}
      />
      <TableColumnOffsetFormField
        value={parentColumnOffset}
        onChange={(event) =>
          setParentColumnOffset(parseInt(event.target.value))
        }
      />
      <TableRowListFormField rows={rows} setRows={setRows} />
      <TableColumnListFormField columns={columns} setColumns={setColumns} />
    </RegionUpdateForm>
  );
}
