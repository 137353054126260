import { useAuth } from "react-oidc-context";
import { ExpressionPredictionButtonFragment } from "../../client/generated";
import { predictLatex } from "../../client/predictions";
import { useTranslation } from "react-i18next";
import { PredictionButton } from "../../predictions/PredictionButton";

type Props = {
  fragment: ExpressionPredictionButtonFragment;
  onAccept: (prediction: string) => void;
};

export function ExpressionPredictionButton({
  fragment,
  onAccept,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const auth = useAuth();

  const predict = async () => {
    if (!fragment.page.pngUrl || !auth.user) return;

    return await predictLatex(
      fragment.page.pngUrl,
      auth.user.access_token,
      fragment.shape.aabb,
    );
  };

  return (
    <PredictionButton
      label={t("ExpressionPredictionButton.label")}
      onPredict={predict}
      onAccept={onAccept}
    />
  );
}
