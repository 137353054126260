import { assertTypeName } from "../../client/guards";
import { ImageRegionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: ImageRegionPreviewFragment;
  alternativeTextAttributeId: string;
};

export function ImageRegionPreview({
  fragment,
  alternativeTextAttributeId,
}: Props): JSX.Element {
  const alternativeTextAttribute = fragment.attributes.find(
    (a) => a.id === alternativeTextAttributeId,
  );
  assertTypeName(
    alternativeTextAttribute?.__typename === "AlternativeTextAttribute",
  );

  return <>{alternativeTextAttribute.alternativeText}</>;
}
