import { useTranslation } from "react-i18next";
import {
  ListItemLabelCreationPanelFragment,
  useCreateListItemLabelFromPanelMutation,
} from "../../client/generated";
import { AttributePanel } from "../AttributePanel";

type Props = {
  fragment: ListItemLabelCreationPanelFragment;
  onDelete: () => Promise<void>;
};

export function ListItemLabelCreationPanel({
  fragment,
  onDelete,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createAttribute] = useCreateListItemLabelFromPanelMutation();

  const onSave = async () => {
    await createAttribute({
      variables: {
        regionId: fragment.id,
      },
    });
    await onDelete();
  };

  return (
    <AttributePanel
      title={t("ListItemLabelCreationPanel.title")}
      unsaved
      onSave={onSave}
      onDelete={onDelete}
    />
  );
}
