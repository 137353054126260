import { mdiAccountCircle } from "@mdi/js";
import { useAuth } from "react-oidc-context";
import { useTranslation } from "react-i18next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@acdc2/ui/components/dropdown-menu";
import { ViewerHelpDropdownMenuItem } from "./ViewerHelpDropdownMenuItem";
import { ViewerLoginDropdownMenuItem } from "./ViewerLoginDropdownMenuItem";
import { ViewerLogoutDropdownMenuItem } from "./ViewerLogoutDropdownMenuItem";
import { ViewerSettingsDropdownMenuItem } from "./ViewerSettingsDropdownMenuItem";
import { NavbarButtonIcon } from "./NavbarButtonIcon";
import { NavbarButton } from "./NavbarButton";
import { ViewerImprintDropdownMenuItem } from "./ViewerImprintDropdownMenuItem";
import { ViewerPrivacyDropdownMenuItem } from "./ViewerPrivacyDropdownMenuItem";
import { ViewerVersionDropdownItem } from "./ViewerVersionDropdownItem";

export function ViewerAccountNavbarButton(): JSX.Element {
  const { t } = useTranslation();
  const auth = useAuth();
  const username = auth.user?.profile?.preferred_username;

  return (
    <DropdownMenu>
      <NavbarButton tooltip={t("ViewerAccountNavbarButton.tooltip")}>
        <DropdownMenuTrigger asChild>
          <NavbarButtonIcon path={mdiAccountCircle} />
        </DropdownMenuTrigger>
      </NavbarButton>
      <DropdownMenuContent className="my-2" side="right">
        {username ? (
          <>
            <DropdownMenuLabel>
              {t("ViewerAccountNavbarButton.label", { name: username })}
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <ViewerHelpDropdownMenuItem />
            <ViewerImprintDropdownMenuItem />
            <ViewerPrivacyDropdownMenuItem />
            <ViewerVersionDropdownItem />
            <DropdownMenuSeparator />
            <ViewerSettingsDropdownMenuItem />
            <ViewerLogoutDropdownMenuItem />
          </>
        ) : (
          <>
            <ViewerHelpDropdownMenuItem />
            <ViewerImprintDropdownMenuItem />
            <ViewerPrivacyDropdownMenuItem />
            <ViewerVersionDropdownItem />
            <ViewerLoginDropdownMenuItem />
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
