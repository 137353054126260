import { Column } from "@tanstack/react-table";
import { DocumentRowFragment } from "../client/generated";
import { Button } from "@acdc2/ui/components/button";
import { Icon } from "@mdi/react";
import {
  mdiArrowDownThin,
  mdiArrowUpThin,
  mdiEyeOff,
  mdiSwapVertical,
} from "@mdi/js";
import { useTranslation } from "react-i18next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@acdc2/ui/components/dropdown-menu";

type Props = {
  column: Column<DocumentRowFragment, string>;
  label: string;
};

export function DocumentTableHeader({ column, label }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="sm" variant="ghost" className="-ml-1 gap-1">
          <span>{label}</span>
          <Icon
            path={
              column.getIsSorted() === "asc"
                ? mdiArrowUpThin
                : column.getIsSorted() === "desc"
                  ? mdiArrowDownThin
                  : mdiSwapVertical
            }
            size="1rem"
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuItem
          onClick={() => column.toggleSorting(false)}
          className="gap-2"
        >
          <Icon path={mdiArrowUpThin} size="1rem" />
          {t("DocumentTableHeader.ascending")}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => column.toggleSorting(true)}
          className="gap-2"
        >
          <Icon path={mdiArrowDownThin} size="1rem" />
          {t("DocumentTableHeader.descending")}
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => column.toggleVisibility(false)}
          className="gap-2"
        >
          <Icon path={mdiEyeOff} size="1rem" />
          {t("DocumentTableHeader.hide")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
