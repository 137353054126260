import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";

export function useProtectedImageSource(imagePath?: string | null) {
  const [src, setSrc] = useState<string>();
  const auth = useAuth();

  useEffect(() => {
    if (!imagePath || !auth.user?.access_token) return;

    fetchAndCacheProtectedImage(imagePath, auth.user.access_token).then(
      (blob) => setSrc(URL.createObjectURL(blob)),
    );
  }, [imagePath, auth.user?.access_token]);

  return src;
}

export async function fetchAndCacheProtectedImage(path: string, token: string) {
  const url = `${import.meta.env.VITE_ACDC2_BASE_API_URL}/${path}`;
  const headers = { Authorization: `Bearer ${token}` };

  const cache = await caches.open("protected-images");
  const cachedResponse = await cache.match(url);

  if (cachedResponse) {
    return await cachedResponse.blob();
  } else {
    const response = await fetch(url, { headers });
    const blob = await response.blob();
    cache.put(url, new Response(blob));
    return blob;
  }
}

export function blobToBase64(blob: Blob): Promise<string> {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject("Failed to read blob as base64");
      }
    };
  });
}

export function cropBlobImage(
  blob: Blob,
  xPercent: number,
  yPercent: number,
  wPercent: number,
  hPercent: number,
) {
  const image = new Image();
  image.src = URL.createObjectURL(blob);

  return new Promise<Blob>((resolve, reject) => {
    image.onload = () => {
      const x = xPercent * image.width;
      const y = yPercent * image.height;
      const w = wPercent * image.width;
      const h = hPercent * image.height;

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        reject("Failed to get 2d context from canvas");
        return;
      }

      canvas.width = w;
      canvas.height = h;
      ctx.drawImage(image, x, y, w, h, 0, 0, w, h);

      canvas.toBlob((croppedBlob) => {
        if (croppedBlob) {
          resolve(croppedBlob);
        } else {
          reject("Failed to turn canvas into blob");
        }
      });
    };
  });
}

export function padBlobImage(blob: Blob, padding: number) {
  const image = new Image();
  image.src = URL.createObjectURL(blob);

  return new Promise<Blob>((resolve, reject) => {
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        reject("Failed to get 2d context from canvas");
        return;
      }

      canvas.width = image.width + 2 * padding;
      canvas.height = image.height + 2 * padding;
      ctx.drawImage(image, padding, padding);

      canvas.toBlob((paddedBlob) => {
        if (paddedBlob) {
          resolve(paddedBlob);
        } else {
          reject("Failed to turn canvas into blob");
        }
      });
    };
  });
}

export function fillBlobImage(blob: Blob, color: string) {
  const image = new Image();
  image.src = URL.createObjectURL(blob);

  return new Promise<Blob>((resolve, reject) => {
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        reject("Failed to get 2d context from canvas");
        return;
      }

      canvas.width = image.width;
      canvas.height = image.height;

      ctx.fillStyle = color;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0);

      canvas.toBlob((filledBlob) => {
        if (filledBlob) {
          resolve(filledBlob);
        } else {
          reject("Failed to turn canvas into blob");
        }
      });
    };
  });
}
