import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AttributePanel } from "../AttributePanel";
import { TableCellRowSpanFormField } from "./TableCellRowSpanFormField";
import { TableCellColumnSpanFormField } from "./TableCellColumnSpanFormField";
import {
  TableCellUpdatePanelFragment,
  useDeleteTableCellFromPanelMutation,
  useUpdateTableCellFromPanelMutation,
} from "../../client/generated";

type Props = {
  fragment: TableCellUpdatePanelFragment;
};

export function TableCellUpdatePanel({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const [updateAttribute] = useUpdateTableCellFromPanelMutation();
  const [deleteAttribute] = useDeleteTableCellFromPanelMutation();
  const [rowSpan, setRowSpan] = useState(fragment.rowSpan);
  const [columnSpan, setColumnSpan] = useState(fragment.columnSpan);

  const onSave = async () => {
    await updateAttribute({
      variables: {
        attributeId: fragment.id,
        rowSpan,
        columnSpan,
      },
    });
  };

  const onDelete = async () => {
    await deleteAttribute({
      variables: { attributeId: fragment.id },
      update(cache) {
        const id = cache.identify(fragment);
        cache.evict({ id });
        cache.gc();
      },
    });
  };

  return (
    <AttributePanel
      title={t("TableCellUpdatePanel.title")}
      unsaved={
        rowSpan !== fragment.rowSpan || columnSpan !== fragment.columnSpan
      }
      onSave={onSave}
      onDelete={onDelete}
    >
      <div className="flex flex-col gap-4">
        <TableCellRowSpanFormField
          value={rowSpan}
          onChange={(event) => setRowSpan(parseInt(event.target.value))}
        />
        <TableCellColumnSpanFormField
          value={columnSpan}
          onChange={(event) => setColumnSpan(parseInt(event.target.value))}
        />
      </div>
    </AttributePanel>
  );
}
