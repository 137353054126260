import { AccessButton } from "@acdc2/ui/components/access-button";
import { Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditorAction, TabIdentifier } from "../editor/reducers";

type Props = {
  onSubmit: (event: React.FormEvent) => Promise<void>;
  dispatch: Dispatch<EditorAction>;
  children?: React.ReactNode;
};

export function RegionUpdateForm({
  onSubmit,
  dispatch,
  children,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const submit = async (event: React.FormEvent) => {
    setLoading(true);
    try {
      await onSubmit(event);
      dispatch({
        type: "switchTab",
        tabIdentifier: TabIdentifier.Regions,
      });
    } catch (error) {
      console.error("Failed to submit region update form:", error);
    }
    setLoading(false);
  };

  return (
    <form className="p-4 flex flex-col gap-4" onSubmit={submit}>
      {children}
      <AccessButton type="submit" loading={loading} disabled={loading}>
        {t("RegionUpdateForm.labels.save")}
      </AccessButton>
    </form>
  );
}
