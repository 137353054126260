import { useAuth } from "react-oidc-context";
import { Outlet } from "react-router";
import { useEffect } from "react";
import { routingPaths } from "../navigation/constants";
import { useNavigateTransition } from "../navigation/useNavigateTransition";
import { LoadingScreen } from "./LoadingScreen";

export const ProtectedRoute = () => {
  const auth = useAuth();
  const [isPending, navigate] = useNavigateTransition();

  useEffect(() => {
    if (auth.isLoading) return;
    if (auth.error) return;
    if (auth.isAuthenticated) return;
    navigate(routingPaths.index, { replace: true });
  }, [auth.isLoading, auth.isAuthenticated, auth.error, navigate]);

  // react-oidc-context is not compatible with react suspense yet
  if (auth.isLoading || !auth.isAuthenticated || isPending) {
    return <LoadingScreen />;
  }

  return <Outlet />;
};
