import { Dispatch, useState } from "react";
import { EditorAction } from "../../editor/reducers";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { assertTypeName } from "../../client/guards";
import { ListFormField } from "../../attributes/list/ListFormField";
import { ListItemFormField } from "../../attributes/list-item/ListItemFormField";
import {
  ListType,
  useSublistRegionEditorSuspenseQuery,
  useUpdateSublistRegionMutation,
} from "../../client/generated";

type Props = {
  listAttributeId: string;
  listItemAttributeId: string;
  dispatch: Dispatch<EditorAction>;
};

export function SublistRegionEditor({
  listAttributeId,
  listItemAttributeId,
  dispatch,
}: Props): JSX.Element {
  const { data } = useSublistRegionEditorSuspenseQuery({
    variables: {
      listAttributeId,
      listItemAttributeId,
    },
  });

  const { listAttr, listItemAttr } = data;
  assertTypeName(listAttr?.__typename === "ListAttribute");
  assertTypeName(listItemAttr?.__typename === "ListItemAttribute");

  const [updateRegion] = useUpdateSublistRegionMutation();
  const [listType, setListType] = useState(listAttr.listType);
  const [ordinal, setOrdinal] = useState(listItemAttr.ordinal);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateRegion({
      variables: {
        listAttributeId,
        listType,
        listItemAttributeId,
        ordinal,
      },
    });
  };

  return (
    <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch}>
      <ListFormField
        value={listType}
        onValueChange={(newListType: ListType) => setListType(newListType)}
      />
      <ListItemFormField
        value={ordinal}
        onChange={(event) => setOrdinal(parseInt(event.target.value))}
      />
    </RegionUpdateForm>
  );
}
