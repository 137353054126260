import { UserAvatar } from "../users/UserAvatar";
import { DocumentExportArtifactFragment } from "../client/generated";
import { useTranslation } from "react-i18next";
import { useHumanizedDiff } from "../i18n/useHumanizedDiff";
import { DocumentExportArtifactOptions } from "./DocumentExportArtifactOptions";

type Props = {
  fragment: DocumentExportArtifactFragment;
};

export function DocumentExportArtifact({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const humanizedCreatedAt = useHumanizedDiff(fragment.createdAt);

  return (
    <div className="flex gap-4">
      <UserAvatar fragment={fragment.createdBy} />
      <div className="flex-1 flex flex-col">
        <span>
          {t("DocumentExportArtifact.title", { exporter: fragment.exporter })}
        </span>
        <span className="text-sm text-muted-foreground">
          {t("DocumentExportArtifact.description", {
            createdAt: humanizedCreatedAt,
            createdBy: fragment.createdBy.username,
          })}
        </span>
      </div>
      <DocumentExportArtifactOptions fragment={fragment} />
    </div>
  );
}
