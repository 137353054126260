import { useTranslation } from "react-i18next";
import { ExpressionReferenceFormFieldFragment } from "../../client/generated";
import { RegionImage } from "../../regions/RegionImage";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@acdc2/ui/components/form";

type Props = {
  fragment: ExpressionReferenceFormFieldFragment;
};

export function ExpressionReferenceFormField({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <FormField>
      <FormItem>
        <FormLabel>{t("ExpressionReferenceFormField.label")}</FormLabel>
        <FormControl>
          <div className="p-4 rounded-md border bg-white">
            <RegionImage fragment={fragment} />
          </div>
        </FormControl>
      </FormItem>
    </FormField>
  );
}
