import { Dispatch } from "react";
import { EditorAction } from "../../editor/reducers";
import { assertTypeName } from "../../client/guards";
import { RegionUpdateForm } from "../RegionUpdateForm";
import { useTocRegionEditorSuspenseQuery } from "../../client/generated";

type Props = {
  tocAttributeId: string;
  dispatch: Dispatch<EditorAction>;
};

export function TOCRegionEditor({
  tocAttributeId,
  dispatch,
}: Props): JSX.Element {
  const { data } = useTocRegionEditorSuspenseQuery({
    variables: {
      tocAttributeId,
    },
  });

  const { tocAttr } = data;
  assertTypeName(tocAttr?.__typename === "TableOfContentsAttribute");

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
  };

  return <RegionUpdateForm onSubmit={onSubmit} dispatch={dispatch} />;
}
